import React, {Fragment, useEffect, useState} from "react"
//** Import Image */
import AccountTabs from "./AccountTabs"
import profile from "../../../images/default.svg"
import Alert from "./../Common/Alert"
import PageTitle from "../../layouts/PageTitle"
import {getUserProfile, getProfileMessage, isAuthenticated} from "../../../store/selectors/AuthSelectors"
import {Link, withRouter} from "react-router-dom"
import {connect, useDispatch} from "react-redux"
import {registerCompanyAction} from "../../../store/actions/CompanyActions"
import {getUserProfileAction} from "../../../store/actions/AuthActions"
import DeletePrompt from "../Common/DeletePrompt"
import {Modal, Row, Col, Spinner, Button} from "react-bootstrap"

const Account = (props) => {
    const {getUserProfile, responseMessage, getUserProfileAction} = props
    const [alert, showAlert] = useState(false)

    const [deactivateModal, setDeactivateModal] = useState({show: false, func: undefined})
    console.log(deactivateModal)
    useEffect(() => {
        getUserProfileAction()
    }, [])

    return (
        <>
            <Fragment>
                {/*<PageTitle activeMenu="Profile" motherMenu="Setting" />*/}

                <div className="row d-none">
                    <div className="col-lg-12">
                        <div className="profile card card-body px-3 pt-3 pb-0">
                            <div className="profile-head">
                                <div className="photo-content">
                                    <div className="cover-photo"></div>
                                </div>
                                <div className="profile-info">
                                    <div className="profile-photo">
                                        <img
                                            src={getUserProfile?.image || profile}
                                            className="img-fluid rounded-circle"
                                            alt="profile"
                                        />
                                    </div>
                                    <div className="profile-details">
                                        <div className="profile-name px-3 pt-2">
                                            <h4 className="text-primary mb-0">{getUserProfile?.first_name} {getUserProfile?.last_name}</h4>
                                            <p>{getUserProfile?.phone}</p>
                                        </div>
                                        <div className="profile-email px-2 pt-2">
                                            <h4 className="text-muted mb-0">{getUserProfile?.email}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {(responseMessage?.success || responseMessage?.error) &&
                    <Alert alert={alert} type={responseMessage?.success ? "success" : responseMessage?.error && "error"}
                           variant={responseMessage?.success ? "success" : responseMessage?.error && "danger"}
                           msg={responseMessage?.success || responseMessage?.error}
                           dismissAlert={() => showAlert(false)}/>}
                <div className="row">
                    <AccountTabs profile={getUserProfile} showAlert={() => showAlert(true)} setDeactivateModal={setDeactivateModal}/>
                    {/* <div className="col-xl-4 col-lg-4 col-md-8 col-sm-12">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="profile-statistics">
                                            <div className="row"> */}
                                                {/*<div className="col-12">*/}
                                                {/*    <h5 className="text-subtitle">Status</h5>*/}
                                                {/*</div>*/}
                                                {/* <div className="col-12 mb-1">
                                                    <div className="text-muted">
                                                        <span>Last Updated</span>
                                                        <span
                                                            className="float-right">{getUserProfile?.updatedAt ? new Date(getUserProfile?.updatedAt).toLocaleDateString() : "N/A"}</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 mb-1">
                                                    <div className="text-muted">
                                                        <span>Account Created</span>
                                                        <span
                                                            className="float-right">{getUserProfile?.createdAt ? new Date(getUserProfile?.createdAt).toLocaleDateString() : "N/A"}</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 mb-1">
                                                    <div className="text-muted">
                                                        <span>Active Plan</span>
                                                        <span
                                                            className="float-right">{getUserProfile?.active_plan ? getUserProfile?.active_plan : "N/A"}</span>
                                                    </div>
                                                </div>
                                                {getUserProfile?.subscription_deactivated_at ?
                                                    <div className="col-12 mb-1">
                                                        <div className="text-muted">
                                                            <span>Deactivated on</span>
                                                            <span
                                                                className="float-right">{getUserProfile?.subscription_deactivated_at ? new Date(getUserProfile?.subscription_deactivated_at).toLocaleDateString() : "N/A"}</span>
                                                        </div>
                                                    </div> : ""}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
                <Modal centered={true} show={deactivateModal?.show} onHide={() => {
                    setDeactivateModal(undefined)
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Account Deactivate</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>We’re sorry to see you go! If this was a mistake, please contact us at
                            <span style={{color: "green"}}> support@digitalhire.com</span>.
                            <br></br>
                            <br></br>
                            Please leave us your feedback so that we can keep improving. From everyone at
                            DigitalHire, we thank you for your support and partnership, and look forward to serving
                            you again!
                            <br></br>
                        </p> <a rel="noopener noreferrer" className="btn btn-primary" target={"_blank"}
                                href={"https://share.hsforms.com/1CYY418MATC2vCT_LsUtvEwc9og8"}>Leave Feedback</a>


                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setDeactivateModal({show: false})}>Close</Button>
                        <Button variant="danger" onClick={() => deactivateModal?.func()}>Deactivate</Button>
                    </Modal.Footer>
                </Modal>
            </Fragment>

        </>
    )
}
const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
        getUserProfile: getUserProfile(state),
        responseMessage: getProfileMessage(state)
    }
}


const mapDispatchToProps = dispatch => {
    return {
        getUserProfileAction: () => dispatch(getUserProfileAction())
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Account))
