import React, { useEffect, useState } from "react"
import { Card, Col, Media, Row, Spinner } from "reactstrap"
import { Modal } from "react-bootstrap"
import { withRouter, Link } from "react-router-dom"
import { connect } from "react-redux"
import UploadBox from "./UploadBox"
import VideoThumbnail from "react-video-thumbnail"
import { useDispatch } from "react-redux"
import Alert from "./../../Common/Alert"

import { getCompanyMediaAction, addCompanyMediaAction } from "../../../../store/actions/CompanyActions"
import { uploadMediaToAWSAction } from "../../../../store/actions/MediaActions"
import { getCompanyMedia, getMeiaAddedMessage } from "../../../../store/selectors/CompanySelector"
import { callCancel } from "../../../../services/CompanyService"
import { callCancelMedia } from "../../../../services/MediaService"

const MediaLibrary = (props) => {
  const { toggle, media, setFile, listmedia, loader, setLoader, responseMessage } = props
  const [selectedFiles, setSelectedFiles] = useState([])
  const [selectedFile, setSelectedFile] = useState(undefined)
  const [mediaFiles, setMediaFiles] = useState([])
  const [video, setVideo] = useState("")
  const [alert, showAlert] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    setSelectedFile(undefined)
  }, [])

  useEffect(() => {
    setMediaFiles(media?.rows)
    if (media.id !== "question") handleChange("video")
  }, [listmedia?.rows])

  const handleChange = (type) => {
    if (type) {
      let medias = listmedia?.rows
      medias = medias?.filter((media) => {
        return media?.type === type
      })
      setMediaFiles(medias)
    } else {
      setMediaFiles(listmedia?.rows)
    }
  }

  function handleAcceptedFiles(files) {
    files?.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    files?.map((file) => {
      const media_key = "company/" + file.lastModified + "." + file.type.split("/")[1]

      const data = {
        media_data: {
          url: media_key,
          thumbnail: "Media thumbnail",
          name: file.name.split(".")[0],
          type: file.type.includes("video") ? "video" : "image",
        },
      }

      setLoader(true)
      props.uploadMediaToAWS(
        {
          media_key,
          file,
        },
        props,
        () => {
          props.addCompanyMedia(data, props, () => {
            setLoader(false);
            showAlert(true);
          })
        }
      )
    })
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const renderMediaRows = (files) => {
    let rows = []
    let rowFiles = []
    files = [...listmedia?.rows]
    while (files.length > 0) {
      rowFiles.push(files.splice(0, 3))
    }
    rowFiles?.map((row, i) => {
      rows.push(
        <Row className="mb-2" key={i}>
          {renderMediaFiles(row)}
        </Row>
      )
    })
    return rows
  }

  const submitFile = () => {
    setFile(selectedFile)
    toggle()
  }

  const onFileSelect = (file) => {
    setSelectedFile(selectedFile?.id === file.id ? undefined : file)
  }

  const renderMediaFiles = (files) => {
    let renderFiles = []
    if (files && files.length > 0) {
      files?.map((f, i) => {
        if (f && f.name) {
          const active = selectedFile?.id === f?.id
          renderFiles.push(
            <Col lg={4} key={i} onClick={() => onFileSelect(f)}>
              <div className={`${active ? "active" : ""} p-1 rounded position-media-box`}>
                <div className="position-media-img customImg">
                  {f.type === "video" ? (
                    <Link to="#" onClick={() => setVideo(f)}>
                      { f?.thumbnail ?
                          <img
                          height="166px"
                          width="100%"
                          src={f.thumbnail}
                          alt=""
                          className="d-block"
                          />
                          :
                        <VideoThumbnail
                          videoUrl={f.url}
                          // thumbnailHandler={(thumbnail) => console.log(thumbnail)}
                          // width={203}
                          // height={140}
                          snapshotAtTime={1}
                        />
                      }
                      {/* <img
                     height="150px"
                     className="rounded bg-light d-block"
                     alt={f.name}
                     src={f.thumbnail}
                     width="100%"
                     />  */}
                    </Link>
                  ) : (
                    <img height="150px" className="rounded bg-light d-block" alt={f.name} src={f.url} width="100%" />
                  )}
                </div>
                <div className="position-media-box-body" style={{overflow:'hidden', textOverflow:'ellipsis'}}>{f.name}</div>
              </div>
            </Col>
          )
        }
      })
    }
    return renderFiles
  }

  return (
    <div className="modal-body pl-5 pr-5 pt-4">
      <Row className="align-items-center justify-content-center">
        <Col lg={9}>
          <div className="text-subtitle">
            <h6>Add from Media Library</h6>
            <span>
              <small>Click on any Media Item to select</small>
            </span>
            <Alert alert={alert} type={(responseMessage?.success) ? "success" : responseMessage?.error && "error"}
              variant={(responseMessage?.success) ? "success" : responseMessage?.error && "danger"}
              msg={"Media successfully added." || responseMessage?.error}
              dismissAlert={() => showAlert(false)} 
            />
          </div>
        </Col>
        <Col>
          {media === "question" ? (
            <>
              <button onClick={() => handleChange("video")} className="btn p-0 btn-link float-right">
                Videos
              </button>
              <button onClick={() => handleChange("image")} className="btn p-0 btn-link float-right mr-2">
                Images
              </button>
              <button onClick={() => handleChange()} className="btn p-0 btn-link float-right mr-2">
                All
              </button>
            </>
          ) : (
            ""
          )}
        </Col>
      </Row>
      <hr />
      <Row className="align-items-center">
        {listmedia && listmedia?.count > 0 ? (
          <Col lg={12}>
            <div
              className="media-list custom-scroll"
              style={{ height: "300px", overflowY: "scroll", overflowX: "hidden" }}
            >
              {renderMediaRows(listmedia)}
            </div>
          </Col>
        ) : (
          <Col lg={12}>
            <div className="text-subtitle text-center p-5 media-library-content">
              <h5>
                <i className="bx bx-images d-block bx-lg mb-2"></i>You have no Item in the Media Library
              </h5>
            </div>
          </Col>
        )}
      </Row>
      <Row className="align-items-center justify-content-center mb-3">
        <Col lg={12}>
          <div className="text-subtitle">
            <h6>Upload New Media</h6>
            <span>
              <small>Maximum Video Size Limit: 34MB; Max Image Size Limit: 2MB</small>
            </span>
          </div>
        </Col>
      </Row>
      <Row className="align-items-center justify-content-center">
        {loader ? (
          <Col>
            <div className="text-subtitle text-center p-5">
              <Spinner size="sm" className="m-auto text-center" color="secondary" />
            </div>
          </Col>
        ) : (
          <Col>
            <UploadBox handleAcceptedFiles={handleAcceptedFiles} />
          </Col>
        )}
      </Row>
      <Row className="justify-content-center mb-3">
        <Col lg={3}>
          <button
            className="btn btn-block btn-outline-secondary float-right"
            onClick={() => {
              dispatch(callCancel())
              dispatch(callCancelMedia())
              setLoader(false)
              toggle()
            }}
          >
            Cancel
          </button>
        </Col>
        <Col lg={3}>
          <button
            onClick={() => submitFile()}
            disabled={!selectedFile ? true : false}
            className="btn btn-block btn-primary"
          >
            Select
          </button>
        </Col>
      </Row>

      <Modal
        centered={true}
        show={video ? true : false}
        onHide={() => {
          setVideo(undefined)
        }}
      >
        <div style={{padding:'10px'}}>
          <div className="text-subtitle">
            <h6>{video?.name}</h6>
          </div>
          <video src={video?.url} controls width="100%" />
        </div>
      </Modal>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    listmedia: getCompanyMedia(state),
    responseMessage: getMeiaAddedMessage(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addCompanyMedia: (data, props, cb) => dispatch(addCompanyMediaAction(data, props.history, cb)),
    loadCompanyMedia: (data, props, cb) => dispatch(getCompanyMediaAction(data, props.history, cb)),
    uploadMediaToAWS: (data, props, cb) => dispatch(uploadMediaToAWSAction(data, props.history, cb)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MediaLibrary))
