import React from "react"

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"


import tabNames from './types'

import './Stepper.css'

const Stepper = props => {
    const { tab } = props

    const renderTabs = (tabObject) => {
        const tabs = Object.values(tabNames)
        return tabs.map((tab, index) => {
            const activeTab = tabObject.id >= tab.id ? 'stepper-active' : ""
            const activeLineRight = tabObject.id > index + 1 ? "stepper-active" : ""
            const activeLineLeft = tabObject.id >= index + 1 ? "stepper-active" : ""
            return (<Col className="col-3" key={index}>
                <div className="text-center">
                    <div className={`d-block stepper-number ${activeTab}`}>{tab.id}</div>
                    <div className="d-block">{tab.name}</div>
                    {tab !== tabNames.ACCOUNT_SETUP ? <div className={`stepper-left ${activeLineLeft}`}></div> : ""}
                    {tab !== tabNames.COMPLETE ? <div className={`stepper-right ${activeLineRight}`}></div> : ""}
                </div>
            </Col>)
        })
    }

    return (
        <div className="stepper pl-4 mb-5">
            {/* {renderTabs2(tab)} */}
            <Row>
                {renderTabs(tab)}
            </Row>
        </div>
    )
}


export default Stepper;