import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { connect, useDispatch } from "react-redux";
import { signupAction } from "../../../store/actions/AuthActions";
import { isAuthenticated, getErrorMessage } from "../../../store/selectors/AuthSelectors";
import { withRouter } from "react-router-dom";
import Alert from "./../Common/Alert";
import { Spinner } from "reactstrap";

const StepOne = (props) => {
  let errorsObj = { fname: "", lname: "", email: "", phone: "", password: "" }
  const [errors, setErrors] = useState(errorsObj)
  const [fname, setFname] = useState("")
  const [lname, setLname] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState()
  const [password, setPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const dispatch = useDispatch()
  const [alert, setAlert] = useState(false)
  const [loader, setLoader] = useState(false)


  function onSubmit(e) {
    e.preventDefault()
    let error = false
    const errorObj = { ...errorsObj }
    if (fname === "") {
      errorObj.fname = "First name is Required"
      error = true
    }
    if (lname === "") {
      errorObj.lname = "Last name is Required"
      error = true
    }
    if (email === "") {
      errorObj.email = "Email is Required"
      error = true
    }
    if (phone === "" || phone === undefined) {
      errorObj.phone = "Phone number is Required"
      error = true
    }
    if (password === "") {
      errorObj.password = "Password is Required"
      error = true
    }
    setErrors(errorObj)
    if (error) {
      return
    }
    const data = {
      "first_name": fname,
      "last_name": lname,
      "email": email,
      "phone": phone,
      "password": password
    }
    // dispatch(signupAction(data, props.history))
    setLoader(true);
    props.signup(data, props, (res) => {
      if (res){
        props.onSubmit()
      }else {
        setAlert(true)
      }
      setLoader(false);
    })
  }
  console.log(alert);

  return (
    <section>
      <form onSubmit={onSubmit} style={{ marginTop: 20 }}>
        {alert &&
          <Alert alert={alert} type={"error"} variant={"danger"} msg={props.errorMessage}
               dismissAlert={() => setAlert(false)} />
        }
        <div className="row">
          <div className="col-lg-6 mb-2">
            <div className={"form-group"}>
              <label className="text-label">
                First Name{" "}
                <span className="text-muted">
                  <small>Required</small>
                </span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter a first name.."
                onChange={(e) => setFname(e.target.value)}
                value={fname}
              />
              {errors.fname && <div className="animated fadeInUp text-danger fs-12">{errors.fname}</div>}
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className={"form-group"}>
              <label className="text-label">
                Last Name{" "}
                <span className="text-muted">
                  <small>Required</small>
                </span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter a last name.."
                onChange={(e) => setLname(e.target.value)}
                value={lname}
              />
              {errors.lname && <div className="animated fadeInUp text-danger fs-12">{errors.lname}</div>}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 mb-2">
            <div className={"form-group"}>
              <label className="text-label">
                Email{" "}
                <span className="text-muted">
                  <small>Required</small>
                </span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter a Email Address"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
              {errors.email && <div className="animated fadeInUp text-danger fs-12">{errors.email}</div>}
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className={"form-group"}>
              <label className="text-label">
                Password{" "}
                <span className="text-muted">
                  <small>Required</small>
                </span>
              </label>
              <div className="input-group transparent-appen">
                <input
                  type={`${showPassword ? "text" : "password"}`}
                  className="form-control"
                  minLength={8}
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  placeholder="Choose a safe one.."
                />

                <div
                  className="input-group-append show-pass"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <span className="input-group-text">
                    {" "}
                    <i className="fa fa-eye-slash" />
                  </span>
                </div>
              </div>
              {errors.password && <div className="animated fadeInUp text-danger fs-12">{errors.password}</div>}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 mb-2">
            <div className={"form-group"}>
              <label className="text-label">
                Phone Number{" "}
                <span className="text-muted">
                  <small>Required</small>
                </span>
              </label>
              <PhoneInput
                country={"us"}
                value={phone}
                id="val-phone1"
                onChange={phone => setPhone(phone)}
                placeholder="541-754-3010"
                inputStyle={{ width: "99%", height: "-webkit-fill-available", border: "hidden" }}
                containerClass={"form-control"}
                containerStyle={{ padding: 0 }}
                buttonStyle={{ borderRadius: " 10px 0 0 10px", border: "0" }}
              />
              {errors.phone && <div className="animated fadeInUp text-danger fs-12">{errors.phone}</div>}
            </div>
          </div>
        </div>
        <button
          type="submit"
          className="btn btn-block mr-2 btn-primary col-2"
          disabled={loader}
        >
          {loader ? (
            <Spinner
              size="sm"
              className="m-auto text-center"
              color="light"
            />
            ) : (
            "Submit"
            )
          }
        </button>
        {/* <button type="button" hr className="btn btn-light">
        cencel
      </button>  */}
      </form>
    </section>
  )
}


const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
    errorMessage: getErrorMessage(state),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    signup: (data, props, cb) => dispatch(signupAction(data, props.history, cb))
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StepOne))
