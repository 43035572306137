import React, { Fragment, useEffect, useState } from "react";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import { Spinner } from "reactstrap";
import PaymentList from "../Payment/PaymentList";
import {
  getCompanyCandidateFilterDataSelector,
  getCompanyCandidates,
  getCompanyPaymentsSelector,
  getCompanySubscription,
  getCompanySubscriptionPlansSelector,
  getMeiaAddedMessage,
} from "../../../store/selectors/CompanySelector";
import {
  getCompanyCandidateFilterDataAction,
  getCompanyCandidatesAction,
  getCompanyPaymentsAction,
  getCompanySubscriptionAction,
  getCompanySubscriptionPlansAction,
  getCompanyTagsAction,
} from "../../../store/actions/CompanyActions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Switch from "../Common/Switch";
import "./style.css";

const Subscription = (props) => {
  const {
    loadCompanySubscriptionPlans,
    getSubscriptionPlans,
    getCompanySubscription,
  } = props;
  const [yearlyPlan, setYearlyPlan] = useState(false);
  const [loader, setloader] = useState(false);
  const [colSize, setColSize] = useState(4);
  useEffect(() => {
    setloader(true);
    loadCompanySubscriptionPlans(() => {
      setloader(false);
      setColSize(12 / getSubscriptionPlans.length);
    });
  }, []);

  return (
    <div className="subscription-page">
      <div className="mb-4 d-md-flex justify-content-center">
        <h1>Subscription Plans</h1>
      </div>
      <h3 className="d-flex justify-content-center mb-3 text-dark">
        Monthly &nbsp;&nbsp;{" "}
        <Switch
          isOn={yearlyPlan}
          handleToggle={() => {
            setYearlyPlan(!yearlyPlan);
          }}
        />
        &nbsp;&nbsp; Yearly
      </h3>
      {/* <hr /> */}
      <Row>
        {loader ? (
          <Spinner size="lg" className="m-auto text-center" color="success" />
        ) : getSubscriptionPlans && getSubscriptionPlans.length > 0 ? (
          getSubscriptionPlans.map((plan, index) => {
            const { interviews, positions, users } = JSON.parse(plan?.limit);
            return (
              <Col key={index} md={3}>
                <div
                  className={`card border-${
                    getCompanySubscription?.name === plan?.name
                      ? "primary active-plan"
                      : "warning"
                  }`}
                >
                  <div className="card-body">
                    <h4>{plan?.name}</h4>
                    <div className="d-flex align-items-center">
                      <h3>
                        {plan?.base_price === -1
                          ? "Custom Pricing"
                          : plan?.base_price
                          ? yearlyPlan
                            ? `$${plan?.yearly_price}/Month`
                            : `$${plan?.base_price}/Month`
                          : "Free"}{" "}
                      </h3>
                    </div>
                    <p>Fit for small companies to grow their teams.</p>
                    <hr />
                    <div className="">
                      <ul className="features-list mb-4">
                        <li>
                          <i class="bx bx-check"></i> {positions} POSITIONS
                        </li>
                        <li>
                          <i class="bx bx-check"></i> {interviews} INTERVIEWS
                        </li>
                        <li>
                          <i class="bx bx-check"></i> {users} TEAM MEMBERS
                        </li>
                        <li>
                          <i class="bx bx-check"></i> 24/7 HUMAN CUSTOMER
                          SUPPORT
                        </li>
                        <li>
                          <i class="bx bx-check"></i> CUSTOM TAGS
                        </li>
                        <li>
                          <i class="bx bx-check"></i> ASSIGN ROLES
                        </li>
                        <li className="text-gray">
                          <i class="bx bx-check"></i> INSTANT EMAIL
                          NOTIFICATIONS
                        </li>
                        <li className="text-gray">
                          <i class="bx bx-minus"></i> SHARE INTERVIEWS WITH
                          ANYONE
                        </li>
                        <li className="text-gray">
                          <i class="bx bx-minus"></i> DEDICATED RECRUITING
                          CONSULTANT
                        </li>
                        <li className="text-gray">
                          <i class="bx bx-minus"></i> DEDICATED SERVERS
                        </li>
                        <li className="text-gray">
                          <i class="bx bx-minus"></i> MONTHLY RECRUITING
                          CONSULTATION
                        </li>
                        <li className="text-gray">
                          <i class="bx bx-minus"></i> CUSTOM WEEKLY REPORTING
                        </li>
                      </ul>
                      <button
                        className="btn text-primary btn-block plan-btn"
                        onClick={() => {
                          if (getCompanySubscription?.name === plan?.name) {
                            return;
                          }
                          props.history.push(
                            `/checkout/${plan?.id}?type=${yearlyPlan}`
                          );
                        }}
                      >
                        {getCompanySubscription?.name === plan?.name
                          ? "Current Plan"
                          : "Upgrade"}
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            );
          })
        ) : (
          <Fragment>
            <h3>No Subscription Plans</h3>
          </Fragment>
        )}
      </Row>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    getSubscriptionPlans: getCompanySubscriptionPlansSelector(state),
    getCompanySubscription: getCompanySubscription(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadCompanySubscriptionPlans: (cb) =>
      dispatch(getCompanySubscriptionPlansAction(cb)),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Subscription)
);
