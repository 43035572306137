import React, {Fragment, useState, useEffect} from "react"
import SideBar from "./SideBar"
import NavHader from "./NavHader"
import Header from "./Header"
import {withRouter} from "react-router-dom"
import {connect} from "react-redux"
import {getUserPermissions, getUserProfile} from "../../../store/selectors/AuthSelectors"
import {getUserProfileAction} from "../../../store/actions/AuthActions"
import {getCompany} from "../../../store/selectors/CompanySelector";

const Dashboard = ({title, onClick: ClickToAddEvent, onClick2, onClick3, getUserProfile, getUserPermissions, getUserProfileAction, getCompany}) => {
    const [toggle, setToggle] = useState("")

    // useEffect(() => {
    //   const bootUp = () => getUserProfileAction()

    //   bootUp()
    // }, [])

    const onClick = (name) => setToggle(toggle === name ? "" : name)
    return (
        <Fragment>
            <NavHader/>
            <Header
                profile={getUserProfile}
                onNote={() => onClick("chatbox")}
                onNotification={() => onClick("notification")}
                onProfile={() => onClick("profile")}
                toggle={toggle}
                title={title}
                onBox={() => onClick("box")}
                onClick={() => ClickToAddEvent()}
            />
            <SideBar profile={getUserProfile} company={getCompany} permissions={getUserPermissions}/>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        getUserProfile: getUserProfile(state),
        getCompany: getCompany(state),
        getUserPermissions: getUserPermissions(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getUserProfileAction: () => dispatch(getUserProfileAction())
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard))
