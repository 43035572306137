import {
    formatError,
    login,
    runLogoutTimer,
    saveTokenInLocalStorage,
    signUp,
    verify,
    getUserProfile,
    updateUserProfile,
    changePasswordService,
    userDeactivateAccountRequestService,
    getUserPermissionsService,
    userForgotPasswordService,
    userResendPinService,
    userAutoResendPinService
} from "../../services/AuthService"

import {
    LOADING_TOGGLE_ACTION,
    LOGIN_CONFIRMED_ACTION,
    LOGIN_MISSING_DATA_ACTION,
    LOGIN_FAILED_ACTION,
    LOGOUT_ACTION,
    SIGNUP_CONFIRMED_ACTION,
    SIGNUP_FAILED_ACTION,
    VERIFY_CONFIRMED_ACTION,
    VERIFY_FAILED_ACTION,
    COMPANY_PROFILE_COMPLETED,
    USER_PROFILE_COMPLETED,
    USER_PROFILE_FAILED,
    USER_PROFILE_UPDATE_COMPLETED,
    USER_PROFILE_UPDATE_FAILED,
    USER_CHANGE_PASSWORD_COMPLETED,
    USER_CHANGE_PASSWORD_FAILED,
    USER_DEACTIVATE_ACCOUNT_REQUEST_COMPLETED,
    USER_DEACTIVATE_ACCOUNT_REQUEST_FAILED,
    LOGIN_REQUEST_ACTION,
    USER_PROFILE_UPDATE_REQUEST,
    USER_PERMISSION_COMPLETED,
    USER_PERMISSION_FAILED,
    USER_FORGOT_PASSWORD_REQUEST,
    USER_FORGOT_PASSWORD_COMPLETED,
    USER_FORGOT_PASSWORD_FAILED,
    USER_RESEND_PIN_REQUEST,
    USER_RESEND_PIN_COMPLETED,
    USER_RESEND_PIN_FAILED, USER_AUTO_RESEND_PIN_REQUEST, USER_AUTO_RESEND_PIN_COMPLETED, USER_AUTO_RESEND_PIN_FAILED
} from "./constants/Auth"
import {getCompanySubscriptionAction} from "./CompanyActions"

export function signupAction(data, history, cb) {
    return (dispatch) => {
        signUp(data)
            .then((response) => {
                console.log("response", response)
                saveTokenInLocalStorage(response.data)
                runLogoutTimer(dispatch, response.data.expiresIn * 1000, history)
                const email = data.email
                data = response.data
                data.email = email
                dispatch(confirmedSignupAction(data))
                cb(true)
            })
            .catch((error) => {

                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                console.log("error", errorMessage)
                dispatch(signupFailedAction(errorMessage))
                cb(false)
            })
    }
}

export function verifyAction(data, history, cb) {
    return (dispatch) => {
        verify(data)
            .then((response) => {
                dispatch(verifyConfirmedAction())
                const d = JSON.parse(localStorage.getItem("userDetails"))
                d.verified = true
                saveTokenInLocalStorage(d)
                cb(true)
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(verifyFailedAction(errorMessage))
                cb(false)
            })
    }
}

export function getUserProfileAction() {
    return (dispatch) => {
        getUserProfile()
            .then((response) => {
                dispatch(getUserProfileConfirmedAction(response.data.data))
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(getUserProfileFailedAction(errorMessage))
            })
    }
}

export function updateUserProfileAction(data, history, cb = null) {
    return (dispatch) => {
        dispatch(updateUserProfileActionRequest())
        updateUserProfile(data)
            .then((response) => {
                dispatch(updateUserProfileConfirmedAction(response.data.data.Profile))
                if (cb) cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(updateUserProfileFailedAction(errorMessage))
                if (cb) cb()
            })
    }
}

export function getUserPermissionsAction(data, history, cb = null) {
    return (dispatch) => {
        getUserPermissionsService()
            .then((response) => {
                dispatch(userPermissionCompleted(response.data.data.permissions))
                if (cb) cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(userPermissionFailed(errorMessage))
                if (cb) cb()
            })
    }
}

export function userChangePasswordAction(data, history, cb) {
    return (dispatch) => {
        changePasswordService(data)
            .then((response) => {
                dispatch(userChangePasswordCompletedAction())
                if (cb) cb()
                // history.push("/dashboard")
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(userChangePasswordFailedAction(errorMessage))
                if (cb) cb()
            })
    }
}

export function userDeactivateAccountRequestAction(cb) {
    return (dispatch) => {
        userDeactivateAccountRequestService()
            .then((response) => {
                dispatch(userDeactivateAccountRequestCompletedAction())
                if (cb) cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(userDeactivateAccountRequestFailedAction(errorMessage))
                if (cb) cb()
            })
    }
}

export function logout(history) {
    ;(() => {
        var cookies = document.cookie.split("; ")
        for (var c = 0; c < cookies.length; c++) {
            var d = window.location.hostname.split(".")
            while (d.length > 0) {
                var cookieBase =
                    encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
                    "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
                    d.join(".") +
                    " ;path="
                var p = window.location.pathname.split("/")
                document.cookie = cookieBase + "/"
                while (p.length > 0) {
                    document.cookie = cookieBase + p.join("/")
                    p.pop()
                }
                d.shift()
            }
        }
    })()
    localStorage.removeItem("userDetails")
    let path = window.location.pathname
    console.log("P: ", path)
    path = path.split("/")
    path = path[path.length - 1]
    if (path === "forgot-password") {
        console.log("In forgot password")
        history.push("/forgot-password")
    } else if (path === "register") {
        console.log("P: ", path)
        history.push("/register")
    } else {
        console.log("P: ", path)
        history.push("/login")
    }
    return {
        type: LOGOUT_ACTION
    }
}

export function loginAction(email, password, history, cb) {
    return (dispatch) => {
        dispatch(loginRequest())
        login(email, password)
            .then((response) => {
                runLogoutTimer(dispatch, response.data.expiresIn * 1000, history)
                const data = response.data
                saveTokenInLocalStorage(response.data)

                data.email = email
                if (data.company_profile === true && data.verified === true) {
                    dispatch(loginConfirmedAction(data))
                    history.push("/home")
                    //window.href.location = "/home"
                } else {
                    dispatch(loginInCompleteData(data))
                    setTimeout(() => history.push("/register"), 900)
                }
                if (cb) cb(false)
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(loginFailedAction(errorMessage))
                if (cb) cb(true)
            })
    }
}

export function userForgotPasswordAction(data, history, cb) {
    return (dispatch) => {
        dispatch(userForgotPasswordRequestAction())
        userForgotPasswordService(data)
            .then((response) => {
                dispatch(userForgotPasswordCompletedAction())

                if (cb) cb(true)
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(userForgotPasswordFailedAction())
                if (cb) cb(false)
            })
    }
}

export function userResendPinAction(cb) {
    return (dispatch) => {
        dispatch(userResendPinRequestAction())
        userResendPinService()
            .then((response) => {
                dispatch(userResendPinCompletedAction())

                if (cb) cb(true)
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(userResendPinFailedAction(errorMessage))
                if (cb) cb(false)
            })
    }
}

export function userAutoResendPinAction(cb) {
    return (dispatch) => {
        dispatch(userAutoResendPinRequestAction())
        userAutoResendPinService()
            .then((response) => {
                dispatch(userAutoResendPinCompletedAction())

                if (cb) cb(true)
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(userAutoResendPinFailedAction(errorMessage))
                if (cb) cb(false)
            })
    }
}

export function userForgotPasswordRequestAction() {
    return {
        type: USER_FORGOT_PASSWORD_REQUEST
    }
}

export function userForgotPasswordCompletedAction() {
    return {
        type: USER_FORGOT_PASSWORD_COMPLETED
    }
}

export function userForgotPasswordFailedAction(data) {
    return {
        type: USER_FORGOT_PASSWORD_FAILED,
        payload: data
    }
}

export function userResendPinRequestAction() {
    return {
        type: USER_RESEND_PIN_REQUEST
    }
}

export function userResendPinCompletedAction() {
    return {
        type: USER_RESEND_PIN_COMPLETED
    }
}

export function userResendPinFailedAction(data) {
    return {
        type: USER_RESEND_PIN_FAILED,
        payload: data
    }
}

export function userAutoResendPinRequestAction() {
    return {
        type: USER_AUTO_RESEND_PIN_REQUEST
    }
}

export function userAutoResendPinCompletedAction() {
    return {
        type: USER_AUTO_RESEND_PIN_COMPLETED
    }
}

export function userAutoResendPinFailedAction(data) {
    return {
        type: USER_AUTO_RESEND_PIN_FAILED,
        payload: data
    }
}

export function userPermissionCompleted(data) {
    return {
        type: USER_PERMISSION_COMPLETED,
        payload: data
    }
}

export function userPermissionFailed(data) {
    return {
        type: USER_PERMISSION_FAILED,
        payload: data
    }
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data
    }
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data
    }
}

export function loginInCompleteData(data) {
    return {
        type: LOGIN_MISSING_DATA_ACTION,
        payload: data
    }
}

export function getUserProfileConfirmedAction(data) {
    return {
        type: USER_PROFILE_COMPLETED,
        payload: data
    }
}

export function updateUserProfileConfirmedAction(data) {
    return {
        type: USER_PROFILE_UPDATE_COMPLETED,
        payload: data
    }
}

export function updateUserProfileFailedAction(data) {
    return {
        type: USER_PROFILE_UPDATE_FAILED,
        payload: data
    }
}

export function updateUserProfileActionRequest() {
    return {
        type: USER_PROFILE_UPDATE_REQUEST
    }
}

export function getUserProfileFailedAction(data) {
    return {
        type: USER_PROFILE_FAILED,
        payload: data
    }
}

export function verifyConfirmedAction() {
    return {
        type: VERIFY_CONFIRMED_ACTION
    }
}

export function verifyFailedAction(data) {
    return {
        type: VERIFY_FAILED_ACTION,
        payload: data
    }
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload
    }
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message
    }
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status
    }
}

export function companyProfileConfirmationAction() {
    return {
        type: COMPANY_PROFILE_COMPLETED
    }
}

export function userChangePasswordCompletedAction() {
    return {
        type: USER_CHANGE_PASSWORD_COMPLETED
    }
}

export function userChangePasswordFailedAction(payload) {
    return {
        type: USER_CHANGE_PASSWORD_FAILED,
        payload
    }
}

export function userDeactivateAccountRequestCompletedAction(payload) {
    return {
        type: USER_DEACTIVATE_ACCOUNT_REQUEST_COMPLETED,
        payload
    }
}

export function userDeactivateAccountRequestFailedAction(payload) {
    return {
        type: USER_DEACTIVATE_ACCOUNT_REQUEST_FAILED,
        payload
    }
}

export function loginRequest() {
    return {
        type: LOGIN_REQUEST_ACTION
    }
}
