import axios from "axios"
import axiosInstance from "../services/AxiosInstance"
import swal from "sweetalert"
import {loginConfirmedAction, loginInCompleteData, logout} from "../store/actions/AuthActions"

export function signUp(data) {
    //axios call
    const postData = {
        ...data,
        "type": "employer"
    }
    return axiosInstance.post(
        `/accounts/sign-up`,
        postData
    )
}

export function login(email, password) {
    const postData = {
        email,
        password,
        // type: "employer"
    }
    return axiosInstance.post(
        `/accounts/login`,
        postData
    )
}

export function verify(data) {
    const {pin} = data
    const postData = {
        pin
    }
    return axiosInstance.post(
        `/accounts/verify`,
        postData
    )
}

export function getUserProfile() {
    return axiosInstance.get(
        `/accounts/`
    )
}

export function getUserPermissionsService() {
    return axiosInstance.get(
        `/accounts/permissions`
    )
}

export function updateUserProfile(data) {
    /*
    *data {
      "user_data":{
          "first_name": "",
          "last_name":"",
          "email":"",
          "image": "",
          "phone": ""
      }
  }
    * */

    const postData = {
        ...data
    }
    return axiosInstance.put(
        `/accounts/`,
        postData
    )
}

export function changePasswordService(data) {
    /*
    *data {
      "old_password": "",
      "new_password": ""
      }
  }
    * */

    const postData = {
        ...data
    }
    return axiosInstance.post(
        `/accounts/password/change`,
        postData
    )
}

export function userDeactivateAccountRequestService() {
    return axiosInstance.post(
        `/accounts/deactivate/request`
    )
}

export function userForgotPasswordService(data) {
    const postData = {
        ...data
    }
    return axiosInstance.post(
        `/accounts/forget/password/`, postData
    )
}

export function userResendPinService() {

    return axiosInstance.post(
        `/accounts/resend/verify/code`
    )
}

export function userAutoResendPinService() {

    return axiosInstance.post(
        `/accounts/auto/resend/verify/code`
    )
}

export function formatError(errorResponse) {
    console.log(errorResponse)
    const error = errorResponse?.error ? errorResponse.error : errorResponse?.errors ? errorResponse.errors : errorResponse
    if (Array.isArray(error)) {
        for (let i = 0; error.length; i++) {
            return error[i]
            // swal("Oops", error[i], "error", { button: "Try Again!" })

        }
    } else {
        // swal("Oops", error, "error", { button: "Try Again!" })
        return error
    }
    // switch (errorResponse.error.message) {
    //   case "EMAIL_EXISTS":
    //     //return 'Email already exists';
    //     swal("Oops", "Email already exists", "error")
    //     break
    //   case "EMAIL_NOT_FOUND":
    //     //return 'Email not found';
    //     swal("Oops", "Email not found", "error", { button: "Try Again!" })
    //     break
    //   case "INVALID_PASSWORD":
    //     //return 'Invalid Password';
    //     swal("Oops", "Invalid Password", "error", { button: "Try Again!" })
    //     break
    //   case "USER_DISABLED":
    //     return "User Disabled"
    //
    //   default:
    //     return ""
    // }
}

export function saveTokenInLocalStorage(tokenDetails) {
    tokenDetails.expireDate = new Date(
        new Date().getTime() + tokenDetails.expiresIn * 1000
    )
    localStorage.setItem("userDetails", JSON.stringify(tokenDetails))
}

export function runLogoutTimer(dispatch, timer, history) {
    setTimeout(() => {
        dispatch(logout(history))
    }, timer)
}

export function checkAutoLogin(dispatch, history) {
    console.log("In CheckAutoLogin 1 ")
    const tokenDetailsString = localStorage.getItem("userDetails")
    let tokenDetails = ""
    if (!tokenDetailsString) {
        dispatch(logout(history))
        return
    }

    tokenDetails = JSON.parse(tokenDetailsString)
    let expireDate = new Date(tokenDetails.expireDate)
    let todaysDate = new Date()

    if (todaysDate > expireDate) {
        dispatch(logout(history))
        return
    }

    if (tokenDetails.company_profile === true && tokenDetails.verified === true) {
        dispatch(loginConfirmedAction(tokenDetails))
    } else {
        dispatch(loginInCompleteData(tokenDetails))
        setTimeout(() => history.push("/register"), 900)
    }

    const timer = expireDate.getTime() - todaysDate.getTime()
    runLogoutTimer(dispatch, timer, history)
}
