import React, {useState, useEffect, useRef} from "react";
import {
    signupAction,
    userAutoResendPinAction,
    userResendPinAction,
    verifyAction
} from "../../../store/actions/AuthActions";
import {withRouter} from "react-router-dom";
import {connect, useSelector} from "react-redux";
import {Spinner} from "reactstrap";

const StepTwo = (props) => {


    let errorsObj = {pin: ""}
    const [errors, setErrors] = useState(errorsObj)
    const [pin, setPin] = useState('');
    const [alart, setAlart] = useState(false);
    const [loader, setLoader] = useState(false);
    const state = useSelector((state) => state.auth);

    const showAlart = (bol) => {
        if (!bol) {
            setTimeout(() => {
                setAlart(false)
            }, 3000);
        }
    }

    function onSubmit(e) {
        e.preventDefault()
        let error = false
        const errorObj = {...errorsObj}
        if (!pin) {
            errorObj.pin = "Verification code is Required"
            error = true
        }
        setErrors(errorObj)
        if (error) {
            return
        }
        const data = {
            pin
        }
        setLoader(true)
        props.verifyAction(data, props, (bol) => {
            setAlart(true);
            showAlart(bol);
            setLoader(false)
            if (bol) props.onSubmit();
        })
    }

    function onSubmitResendPin(e) {
        e.preventDefault()


        setLoader(true)
        props.userResendPin((bol) => {
            setAlart(true);
            showAlart(bol);
            setLoader(false)
        })
    }

    useEffect(() => {
        props.userAutoResendPin()
    }, [])


    return (
        <section>
            <form onSubmit={onSubmit}>
                <div className="row justify-content-center">
                    <div className="col-lg-6 mb-2 justify-content-center">
                        <div className="text-center">
                            <h4>Enter Verification Pin</h4>
                            <span className="text-subtitle">Please check your email for Verification Pin.
                        If you don’t receive an email within 15 minutes, please check your
                        spam folder or click the resend pin button. </span>
                        </div>
                        <hr/>
                    </div>
                </div>
                {alart && state.errorVerifyMessage && (
                    <div className="animated fadeInUp text-danger fs-15 text-center">{state.errorVerifyMessage}</div>
                )}
                {alart && state.successMessage && (
                    <div className="animated fadeInUp text-primary fs-15 text-center">{state.successMessage}</div>
                )}
                <div className="row justify-content-center mb-5">
                    <div className={"row form-group col-lg-4 mb-2 justify-content-center "}>
                        <label className="text-label"> Verification Pin </label>
                        <input
                            type="text"
                            className="form-control text-center"
                            id="val-pin1"
                            placeholder="6 Digit Pin"
                            name="pin"
                            onChange={(e) => setPin(e.target.value)}
                            value={pin}
                        />
                        {errors.pin &&
                            <div className="animated fadeInUp text-danger fs-12"
                                 style={{marginTop: 5}}>{errors.pin}</div>}
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-4 mb-2 justify-content-center">
                        <button className="btn btn-secondary btn-block waves-effect waves-light"
                                onClick={onSubmitResendPin} type="button"> Resend
                            Pin
                        </button>
                    </div>
                    <div className="col-lg-4 mb-2 justify-content-center">
                        <button
                            disabled={loader}
                            className="btn btn-primary btn-block waves-effect waves-light"
                            type="submit"
                        >
                            {loader ? (
                                <Spinner
                                    size="sm"
                                    className="m-auto text-center"
                                    color="light"
                                />
                            ) : ("Verify")
                            }
                        </button>
                    </div>
                </div>
                <p className="text-center mt-4">Verification pin sent successfully, please check your email!</p>
            </form>
        </section>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        verifyAction: (data, props, cb) => dispatch(verifyAction(data, props.history, cb)),
        userResendPin: (cb) => dispatch(userResendPinAction(cb)),
        userAutoResendPin: (cb) => dispatch(userAutoResendPinAction(cb))
    }
}


export default withRouter(connect(null, mapDispatchToProps)(StepTwo))
