import React, {useEffect, useState} from "react";
import {Card, CardBody, Col, Media, ModalBody, Row} from "reactstrap";
import logoDark from "./../../../images/logo-dark.svg";
import {Link, withRouter} from "react-router-dom";
import {connect, useDispatch, useSelector} from "react-redux";
import {shareInterviewAction} from "../../../store/actions/CompanyActions";
// import AlertCommon from 'components/Common/commonAlert'

const EmailInterviewModal = ({evaluation, toggle, shareInterview, history}) => {
    const dispatch = useDispatch();

    // useEffect(() => {
    //   if(success){
    //     setTimeout(() => { toggle() }, 2000)
    //   }
    // }, [success])

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");

    const onSubmit = (e) => {
        console.log("Share Review submitted")
        e.preventDefault()
        const d = {
            name,
            email,
            interview_id: evaluation.id
        }
        shareInterview(d, history, (res) => {
            toggle()
        });
    }

    return (
        <ModalBody className="p-4 text-dark">
            <Row className="justify-content-end">
                <Col lg={3}>
                    <button
                        className="float-right btn btn-link-secondary"
                        onClick={() => toggle()}
                    >
                        close
                    </button>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col lg={7}>
                    <img src={logoDark} className="img-fluid"/>
                </Col>
                <Col lg={12}>
                    <h4 className="text-subtitle mt-3 mb-2">Email this Interview</h4>
                    <p>
                        Create and share a secure link with the recipient. They will only be
                        able access the interview you are about to share.
                    </p>
                    <hr/>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    {/* {success ? ( */}
                    {/* <Col lg={12}><AlertCommon body={success} color="success"/></Col> */}
                    {/* ) : null} */}
                    <form>
                        <div className="form-group">
                            <label>
                                Name{" "}
                                <span className="text-muted">
                  <small>Required</small>
                </span>
                            </label>
                            <input
                                name="name"
                                className="form-control"
                                placeholder="Name"
                                type="text"
                                value={name}
                                onChange={(e) => setName(e?.target?.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label>
                                Email{" "}
                                <span className="text-muted">
                  <small>Required</small>
                </span>
                            </label>
                            <input
                                name="email"
                                className="form-control"
                                placeholder="user@example.com"
                                type="text"
                                value={email}
                                onChange={(e) => setEmail(e?.target?.value)}
                            />
                        </div>
                        <button
                            type="button"
                            className="btn btn-primary btn-block d-inline-block"
                            onClick={onSubmit}
                        >
                            Share
                        </button>
                    </form>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col lg={12}>
                    By 'Sharing this Interview', you agree to our{" "}
                    <Link to="#" className="text-primary">
                        Terms and Services{" "}
                    </Link>
                    and that you have read our{" "}
                    <Link to={"#"} className="text-primary">
                        Privacy Policy
                    </Link>
                    .
                </Col>
            </Row>
        </ModalBody>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        shareInterview: (data, history, cb) => dispatch(shareInterviewAction(data, history, cb))
    }
}

export default withRouter(connect(null, mapDispatchToProps)(EmailInterviewModal));
