import React from "react"
import { Link } from "react-router-dom"
import { Row, Col, Card, Tab, Nav } from "react-bootstrap"
// import './evaluations.scss'

const EvaluationTabs = ({ loading, tab, setTab }) => {
  return (
    <Tab.Container defaultActiveKey={tab.toLowerCase()} onSelect={(e) => setTab(e)}>
      <Nav as="ul" className="nav-pills mb-4 ">
        <Nav.Item as="li">
          <Nav.Link eventKey={""}>All</Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Nav.Link eventKey={"video"}>Videos</Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Nav.Link eventKey={"image"}>Images</Nav.Link>
        </Nav.Item>
      </Nav>
    </Tab.Container>
  )
}

export default EvaluationTabs
