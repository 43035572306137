import React from 'react'

import './common.css'

const ProfileImage = ({ classes, image }) => {
  return (
    <div className={`circle ${classes}`}>
      <img
        src={image}
        alt=""
        className="img-thumbnail rounded-circle"
      />
    </div>
  )
}

export default ProfileImage