import PropTypes from 'prop-types'
import React from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Badge, Row, Col } from "reactstrap"

const QuestionBox = ({ data, editMode }) => {
  const question = data

  const renderTypeColor = (type) => {
    if (type === 'video') return { backgroundColor: '#00BA5220', color: '#00BA52' }
    if (type === 'essay') return { backgroundColor: '#237BFF20', color: '#237BFF' }
    if (type === 'shortText') return { backgroundColor: '#5743D920', color: '#5743D9' }
  }

  const renderQuestionType = (type) => {
    if (type === 'video') return 'Video'
    if (type === 'essay') return 'Essay'
    if (type === 'shortText') return 'Short Text'
  }

  return (
    <Card style={{ width: "80%",border: "dotted" }} className={`d-inline-block  mr-2 question-box`} >
      <CardBody>
        <Row>
          <Col lg="9">
            <div>
              <h6 className="d-inline-block"><b>Question {question?.seqIndex}: </b></h6>
              <span> {question?.title}</span>
            </div>
            <div>
              <span className="mr-4">Time: {question?.time_allowed} Min</span>
              <span>Attempts: {question?.attempts_allowed}</span>
            </div>
          </Col>
          <Col lg="3">
            <Row className="align-items-center justify-content-end">
              <Col lg="6">
                <Badge className="float-right" style={renderTypeColor(question?.type)}>{renderQuestionType(question?.type)}</Badge>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

QuestionBox.propTypes = {
  data: PropTypes.object
}

export default QuestionBox