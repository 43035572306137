import swal from "sweetalert"

export function formatError(errorResponse) {
  // console.log(errorResponse)
  const error = errorResponse?.error ? errorResponse.error : errorResponse?.errors || errorResponse ;
  if (Array.isArray(error)) {
    for (let i = 0; error.length; i++) {
      return error[i]
      // swal("Oops", error[i], "error", { button: "Try Again!" })

    }
  } else {
    // swal("Oops", error, "error", { button: "Try Again!" })
    return error
  }
  // switch (errorResponse.error.message) {
  //   case "EMAIL_EXISTS":
  //     //return 'Email already exists';
  //     swal("Oops", "Email already exists", "error")
  //     break
  //   case "EMAIL_NOT_FOUND":
  //     //return 'Email not found';
  //     swal("Oops", "Email not found", "error", { button: "Try Again!" })
  //     break
  //   case "INVALID_PASSWORD":
  //     //return 'Invalid Password';
  //     swal("Oops", "Invalid Password", "error", { button: "Try Again!" })
  //     break
  //   case "USER_DISABLED":
  //     return "User Disabled"
  //
  //   default:
  //     return ""
  // }
}
