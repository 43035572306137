import { uploadMediaToAWS, generatePutSigningUrlService } from "../../services/MediaService"
import { formatError } from "../../services/FormatError"
import { UPLOAD_MEDIA_TO_AWS_COMPLETED, UPLOAD_MEDIA_TO_AWS_FAILED } from "./constants/Media"


export function uploadMediaToAWSAction(data, history, cb = null) {
  /**
   * data = {
      "media_key": key,
      file: blob[file],

    }
   */
  return (dispatch) => {
    generatePutSigningUrlService(data).then((response) => {

        // console.log(response.data.data)
        data.url = response.data.data
        console.log("DATA: ", data)
        dispatch(uploadMediaToAWSAction2(data, history, cb))

        // return (dispatch) => {
        //   console.log("MEDIA RESPONSE: 1", response)
        //   uploadMediaToAWS(data)
        //     .then((response) => {
        //       console.log("MEDIA RESPONSE: ", response)
        //       dispatch(uploadMediaToAWSCompletedAction(data))
        //
        //       // history.push("/dashboard")
        //     })
        //     .catch((error) => {
        //
        //       const errorMessage = formatError(error.response.data)
        //       dispatch(uploadMediaToAWSFailedAction(errorMessage))
        //     })
        // }

      }
    ).catch((error) => {
        const errorMessage = formatError(error.response.data)
        dispatch(uploadMediaToAWSFailedAction(errorMessage))
      })

  }
}

export function uploadMediaToAWSAction2(data, history, cb = null) {
  /**
   * data = {
      "media_key": key,
      file: blob[file],

    }
   */
  return (dispatch) => {
          uploadMediaToAWS(data)
            .then((response) => {
              console.log("MEDIA RESPONSE: ", response)
              dispatch(uploadMediaToAWSCompletedAction(data))
              if (cb){
                cb()
              }
              // history.push("/dashboard")
            })
            .catch((error) => {
              console.log("MEDIA RESPONSE ERROIR: ", error)
              // const errorMessage = formatError(error.response.data)
              // dispatch(uploadMediaToAWSFailedAction(errorMessage))
            })
        }

      }



export function uploadMediaToAWSCompletedAction(payload) {
  return {
    type: UPLOAD_MEDIA_TO_AWS_COMPLETED,
    payload
  }
}

export function uploadMediaToAWSFailedAction(payload) {
  return {
    type: UPLOAD_MEDIA_TO_AWS_FAILED,
    payload
  }
}
