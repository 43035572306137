import React, { useEffect, useState } from "react"
import { Alert, Card, CardBody, Col, Media, Row, Spinner } from "reactstrap"
import PhoneInput from "react-phone-input-2"
import avatar from "../../../images/default.svg"
import ProfileImage from "../Common/ProfileImage"
import { addCompanyMemberAction, 
  getCompanySubscriptionAction 
} from "../../../store/actions/CompanyActions"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"

const SendInviteModal = (props) => {

  const { toggle, addCompanyMember, showAlert, company, getCompanySubscription } = props
  let errorsObj = { email: "", fName: "", lName: "", phone: "", role: "" }
  const [errors, setErrors] = useState(errorsObj)
  const [email, setEmail] = useState('');
  const [fName, setFName] = useState('');
  const [lName, setLName] = useState('');
  const [phone, setPhone] = useState('');
  const [role, setRole] = useState('');
  

  const onsubmit = (e) => {
    e.preventDefault()
    let error = false
    const errorObj = { ...errorsObj }
    if (email==='') {
      errorObj.email = "Email Address is Required"
      error = true
    }
    if (fName==='') {
      errorObj.fName = "First Name is Required"
      error = true
    }
    if (lName==='') {
      errorObj.lName = "Last Name is Required"
      error = true
    }
    if (phone==='') {
      errorObj.phone = "Phone Number is Required"
      error = true
    }
    if (role==='') {
      errorObj.role = "Role is Required"
      error = true
    }
    setErrors(errorObj)
    if (error) {
      return
    }
    const data = {
      user_data: {
        first_name: fName,
        last_name: lName,
        phone: phone,
        email: email,
        role: role
      }
    }
    console.log(data);
    addCompanyMember(data, props,() =>{toggle();showAlert()});
    getCompanySubscription();

  }

  return (
    <Card className="overflow-hidden">
      <CardBody>
        <Row><Col>
          <button className="float-right btn btn-link-secondary" onClick={() => toggle()}>close</button>
        </Col></Row>
        <Row className="align-items-center ">
          <Col sm="4">
            <ProfileImage classes={"m-auto"} image={company.logo || avatar} />
          </Col>
          <Col sm="8">
            <div className="">
              <h5>{company.name}</h5>
              <span className="text-subtitle mb-2">Invite a new Team Member</span>
              <span className="d-block mb-1">An email invitation will be sent.</span>
            </div>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col lg={12}>
            <div className="form-group">
              <label className="text-label">Email <span className="text-muted"><small>Required</small></span></label>
              <input type="email" className="form-control" value={email} placeholder="Enter Email Address" onChange={(e) => setEmail(e.target.value)} />
              {errors.email && <div className="animated fadeInUp text-danger fs-12" style={{ marginTop: 5 }}>{errors.email}</div>}
            </div>
            <div className="form-group">
              <label className="text-label">First Name <span className="text-muted"><small>Required</small></span></label>
              <input type="text" className="form-control" value={fName} placeholder="Enter First Name" onChange={(e) => setFName(e.target.value)} />
              {errors.fName && <div className="animated fadeInUp text-danger fs-12" style={{ marginTop: 5 }}>{errors.fName}</div>}
            </div>
            <div className="form-group">
              <label className="text-label">Last Name <span className="text-muted"><small>Required</small></span></label>
              <input type="text" className="form-control" value={lName} placeholder="Enter Last Name" onChange={(e) => setLName(e.target.value)} />
              {errors.lName && <div className="animated fadeInUp text-danger fs-12" style={{ marginTop: 5 }}>{errors.lName}</div>}
            </div>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col lg={6}>
            <div className="form-group">
              <label>Phone <span className="text-muted"><small>Required</small></span></label>
              <PhoneInput
                country={"us"}
                value={phone} 
                onChange={(phone) => setPhone(phone)}
                placeholder="541-754-3010"
                inputStyle={{ width: "99%", height: "-webkit-fill-available", border: "hidden" }}
                containerClass={"form-control"}
                containerStyle={{ padding: 0 }}
                buttonStyle={{ borderRadius: " 10px 0 0 10px", border: "0" }}
              />
              {errors.phone && <div className="animated fadeInUp text-danger fs-12" style={{ marginTop: 5 }}>{errors.phone}</div>}
            </div>
          </Col>
          <Col lg={6}>
            <div className="form-group">
              <label className="text-label">Select Role <span
                className="text-muted"><small>Required</small></span></label>
              <select
                name="role"
                label="Select Role"
                className="form-control"
                onChange={(e) => setRole(e.target?.value)}
                required
              >
                <option value="">Please select role</option>
                <option value="manager">Manager</option>
                <option value="recruiter">Recruiter</option>
              </select>
              {errors.role && <div className="animated fadeInUp text-danger fs-12" style={{ marginTop: 5 }}>{errors.role}</div>}
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg={5}>
            <button type="button" onClick={() => toggle()}
                    className="btn btn-block btn-outline-secondary float-right">Cancel
            </button>
          </Col>
          <Col lg={5}>
            <button className="btn btn-block btn-primary" type="submit" onClick={onsubmit} onSubmit={onsubmit}> Send Invite</button>
          </Col>
        </Row>

      </CardBody>
    </Card>
  )
}
const mapDispatchToProps = dispatch => {
  return {
    addCompanyMember: (data, props, cb) => dispatch(addCompanyMemberAction(data, props.history, cb)),
    getCompanySubscription: () => dispatch(getCompanySubscriptionAction())
  }
}

export default withRouter(connect(null, mapDispatchToProps)(SendInviteModal))