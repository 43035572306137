import React, { useState } from "react"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import { Formik } from "formik"
import * as Yup from "yup"
import profile from "../../../images/profile/profile.png"
import { getCompanyAction, updateCompanyAction } from "../../../store/actions/CompanyActions"
import { userChangePasswordAction } from "../../../store/actions/AuthActions"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { prop } from "dom7"

const PasswordSection = (props) => {
  let errorsObj = { curPassword: "", newPassword: "", conPassword: "" }
  const [errors, setErrors] = useState(errorsObj)
  const [curPassword, setCurPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [conPassword, setConPassword] = useState("")
  const [showPassword, setShowPassword] = useState({ cur: false, new: false, con: false })

  function onSubmit(e) {
    e.preventDefault()

    let error = false
    const errorObj = { ...errorsObj }
    if (curPassword === "") {
      errorObj.curPassword = "Current Passowrd is Required"
      error = true
    } else errorObj.curPassword = ""
    if (newPassword === "") {
      errorObj.newPassword = "New Passowrd is Required"
      error = true
    } else if (newPassword.length < 6) {
      errorObj.newPassword = "Minimum 6 Characters Required"
      error = true
    } else errorObj.newPassword = ""
    if (conPassword === "") {
      errorObj.conPassword = "Confirm New Passowrd is Required"
      error = true
    } else errorObj.conPassword = ""
    if (conPassword !== newPassword && conPassword !== "") {
      errorObj.conPassword = "Passowrd Not Match"
      error = true
    }
    setErrors(errorObj)
    if (error) {
      return
    }

    const data = {
      old_password: curPassword,
      new_password: newPassword,
    }
    props.changePassword(data, prop, props.showAlert)
  }
  return (
    <form onSubmit={onSubmit}>
      <div className="col-lg-8 mb-2">
        <div className={`form-group `}>
          <label className="text-label">
            Current Password{" "}
            <span className="text-muted">
              <small>Required</small>
            </span>
          </label>
          <div className="input-group transparent-appen">
            <input
              type={`${showPassword.cur ? "text" : "password"}`}
              className="form-control"
              onChange={(e) => setCurPassword(e.target.value)}
              value={curPassword}
              placeholder="Enter Current Password"
            />

            <div
              className="input-group-append show-pass"
              onClick={() => setShowPassword({ ...showPassword, cur: !showPassword.cur })}
            >
              <span className="input-group-text">
                {" "}
                {showPassword.cur ? <i class="bx bx-show-alt"></i> : <i className="fa fa-eye-slash" />}
              </span>
            </div>
          </div>
          {errors.curPassword && <div className="animated fadeInUp text-danger fs-12">{errors.curPassword}</div>}
        </div>
      </div>
      <div className="col-lg-8 mb-2">
        <div className={`form-group`}>
          <label className="text-label">
            New Password{" "}
            <span className="text-muted">
              <small>Required</small>
            </span>
          </label>
          <div className="input-group transparent-appen">
            <input
              type={`${showPassword.new ? "text" : "password"}`}
              className="form-control"
              id="val-password1"
              onChange={(e) => setNewPassword(e.target.value)}
              value={newPassword}
              placeholder="Choose a safe one.."
            />

            <div
              className="input-group-append show-pass"
              onClick={() => setShowPassword({ ...showPassword, new: !showPassword.new })}
            >
              <span className="input-group-text">
                {" "}
                {showPassword.new ? <i class="bx bx-show-alt"></i> : <i className="fa fa-eye-slash" />}
              </span>
            </div>
          </div>
          {errors.newPassword && <div className="animated fadeInUp text-danger fs-12">{errors.newPassword}</div>}
        </div>
      </div>
      <div className="col-lg-8 mb-2">
        <div className={`form-group `}>
          <label className="text-label">
            Confirm Password{" "}
            <span className="text-muted">
              <small>Required</small>
            </span>
          </label>
          <div className="input-group transparent-appen">
            <input
              type={`${showPassword.con ? "text" : "password"}`}
              className="form-control"
              onChange={(e) => setConPassword(e.target.value)}
              value={conPassword}
              placeholder="Enter Password Again"
            />

            <div
              className="input-group-append show-pass"
              onClick={() => setShowPassword({ ...showPassword, con: !showPassword.con })}
            >
              <span className="input-group-text">
                {" "}
                {showPassword.con ? <i class="bx bx-show-alt"></i> : <i className="fa fa-eye-slash" />}
              </span>
            </div>
          </div>
          {errors.conPassword && <div className="animated fadeInUp text-danger fs-12">{errors.conPassword}</div>}
        </div>
      </div>
      <div className="col-lg-8 mb-2">
        <button
          type="submit"
          className="btn mr-2 btn-primary"
          // disabled={isSubmitting}
        >
          Update Password
        </button>
      </div>
    </form>
  )
}
const mapDispatchToProps = (dispatch) => {
  return {
    changePassword: (data, props, cb) => dispatch(userChangePasswordAction(data, props.history, cb)),
  }
}

export default withRouter(connect(null, mapDispatchToProps)(PasswordSection))
