import React from "react"
import ReactApexChart from "react-apexcharts"

const ActiveBarGraph = (props) => {
    console.log("Traffic Data:", props?.traffic_data)
    const state = {
        series: props?.traffic_data
            ? [props?.traffic_data?.web, props?.traffic_data?.android, props?.traffic_data?.apple, props?.traffic_data?.web === 0 && props?.traffic_data?.android === 0 && props?.traffic_data?.apple === 0 ? 1 : 0]
            : [0, 0, 0, 1],

        options: {
            chart: {
                // width:400,
                type: "donut",
            },
            labels: ["Web", "Android", "Apple", "No data"],
            fill: {
                opacity: 1,
                // colors: ["#001C35", "#00BA52", "#CE1036"],
            },
            stroke: {
                width: 0,
                colors: undefined,
            },
            yaxis: {
                show: false,
            },
            dataLabels: {
                enabled: false,
            },
            legend: {
                position: "bottom",
                markers: {
                    // fillColors: ["#001C35", "#00BA52", "#CE1036"],
                },
            },
            plotOptions: {
                polarArea: {
                    rings: {
                        strokeWidth: 0,
                    },
                },
            },
            // theme: {
            //   monochrome: {
            //     enabled: true,
            //     shadeTo: "light",
            //     shadeIntensity: 0.6,
            //   },
            // },
            colors: [
                "#00ba52",
                "#009477",
                "#006b7e",
                "#ECECEC",
                "#004164",
                "#001c35"
            ],
        },
    }

    return (
        <div id="chart" className="apex-chart">
            <ReactApexChart
                options={state.options}
                series={state.series}
                type="donut"
                // height={251}
                // width={300}
            />
        </div>
    )
}

export default ActiveBarGraph
