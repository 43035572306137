import React from 'react';
import './Switch.css';

const Switch = ({ isOn, handleToggle, forId }) => {
  return (
    <>
      <input
        checked={isOn}
        onChange={() => handleToggle()}
        className="react-switch-checkbox"
        id={`react-switch-new-${forId?forId:1}`}
        type="checkbox"
      />
      <label
        className={`react-switch-label ${isOn && ' bg-primary ' }`}
        htmlFor={`react-switch-new-${forId?forId:1}`}
      >
        <span className={`react-switch-button`} />
      </label>
    </>
  );
};

export default Switch;