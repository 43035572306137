import {
    LOADING_TOGGLE_ACTION,
    LOGIN_CONFIRMED_ACTION,
    LOGIN_MISSING_DATA_ACTION,
    LOGIN_FAILED_ACTION,
    LOGOUT_ACTION,
    SIGNUP_CONFIRMED_ACTION,
    SIGNUP_FAILED_ACTION,
    VERIFY_CONFIRMED_ACTION,
    VERIFY_FAILED_ACTION,
    COMPANY_PROFILE_COMPLETED,
    USER_PROFILE_COMPLETED,
    USER_PROFILE_FAILED,
    USER_PROFILE_UPDATE_COMPLETED,
    USER_PROFILE_UPDATE_FAILED,
    USER_CHANGE_PASSWORD_FAILED,
    USER_CHANGE_PASSWORD_COMPLETED,
    USER_DEACTIVATE_ACCOUNT_REQUEST_COMPLETED,
    USER_DEACTIVATE_ACCOUNT_REQUEST_FAILED,
    LOGIN_REQUEST_ACTION,
    USER_PROFILE_UPDATE_REQUEST,
    USER_PERMISSION_COMPLETED,
    USER_PERMISSION_FAILED,
    USER_FORGOT_PASSWORD_FAILED,
    USER_FORGOT_PASSWORD_COMPLETED, USER_RESEND_PIN_FAILED, USER_RESEND_PIN_COMPLETED,
} from "../actions/constants/Auth"

const initialState = {
    auth: {
        email: "",
        token: "",
        localId: "",
        expiresIn: "",
        refreshToken: "",
        verified: undefined,
        company_profile: undefined,
    },
    permissions: undefined,
    profile: undefined,
    errorMessage: "",
    successMessage: "",
    showLoading: false,
    AuthDataLoading: false,
    userProfileLoading: false,
}

export function AuthReducer(state = initialState, action) {
    const {type, payload} = action
    let auth
    switch (type) {
        case SIGNUP_CONFIRMED_ACTION:
            return {
                ...state,
                auth: payload,
                errorMessage: "",
                successMessage: "Signup Successfully Completed",
                showLoading: false,
                AuthDataLoading: false,
            }
        case LOGIN_REQUEST_ACTION:
            return {
                ...state,
                AuthDataLoading: true,
            }
        case LOGIN_CONFIRMED_ACTION:
            return {
                ...state,
                auth: payload,
                errorMessage: "",
                successMessage: "Login Successfully Completed",
                showLoading: false,
                AuthDataLoading: false,
            }
        case USER_FORGOT_PASSWORD_COMPLETED:
            return {
                ...state,
                errorMessage: "",
                successMessage: "Your password reset request has been received. " +
                    "You will receive new password email shortly",
                showLoading: false,
                AuthDataLoading: false,
            }
        case USER_PERMISSION_COMPLETED:
            return {
                ...state,
                permissions: payload,
                errorMessage: "",
                successMessage: "User Permission Successfully Completed",
                showLoading: false,
                AuthDataLoading: false,
            }
        case USER_PERMISSION_FAILED:
            return {
                ...state,
                errorMessage: payload,
                successMessage: "",
                showLoading: false,
                AuthDataLoading: false,
            }
        case LOGIN_MISSING_DATA_ACTION:
            return {
                ...state,
                auth: payload,
                errorMessage: "Complete SignUp Setps",
                successMessage: "",
                showLoading: false,
                AuthDataLoading: false,
            }
        case USER_PROFILE_COMPLETED:
            return {
                ...state,
                profile: payload,
                errorMessage: "",
                successMessage: "Get User Profile Successful",
                showLoading: false,
            }
        case USER_PROFILE_UPDATE_REQUEST:
            return {
                ...state,
                userProfileLoading: true,
            }
        case USER_PROFILE_UPDATE_COMPLETED:
            const p = Object.assign({}, state.profile, payload)
            return {
                ...state,
                profile: p,
                errorMessage: "",
                updateMessage: "User Profile updated Successfully",
                showLoading: false,
                userProfileLoading: false,
            }
        case USER_DEACTIVATE_ACCOUNT_REQUEST_COMPLETED:
            return {
                ...state,
                errorMessage: "",
                updateMessage: "User Deactivate Account Request Sent",
                showLoading: false,
            }
        case USER_DEACTIVATE_ACCOUNT_REQUEST_FAILED:
            return {
                ...state,
                errorMessage: payload,
                updateMessage: "",
                showLoading: false,
            }
        case USER_PROFILE_UPDATE_FAILED:
            return {
                ...state,
                errorMessage: payload,
                updateMessage: "",
                showLoading: false,
                userProfileLoading: false,
            }
        case USER_CHANGE_PASSWORD_COMPLETED:
            return {
                ...state,
                errorMessage: "",
                updateMessage: "Password Changed Successfully",
                showLoading: false,
            }
        case USER_CHANGE_PASSWORD_FAILED:
            return {
                ...state,
                errorMessage: payload,
                updateMessage: "",
                showLoading: false,
            }
        case USER_PROFILE_FAILED:
            return {
                ...state,
                errorMessage: payload,
                successMessage: "",
                showLoading: false,
            }
        case VERIFY_CONFIRMED_ACTION:
            auth = state.auth
            auth.verified = true
            return {
                ...state,
                auth: auth,
                errorMessage: "",
                errorVerifyMessage: "",
                successMessage: "Verification Completed",
                showLoading: false,
            }
        case USER_RESEND_PIN_COMPLETED:

            return {
                ...state,
                errorMessage: "",
                errorVerifyMessage: "",
                successMessage: "Verification pin resent to your registered account",
                showLoading: false,
            }

        case COMPANY_PROFILE_COMPLETED:
            auth = state.auth
            auth.company_profile = true
            return {
                ...state,
                auth: auth,
                errorMessage: "",
                successMessage: "Verification Completed",
                showLoading: false,
            }

        case VERIFY_FAILED_ACTION:
            state.auth.verified = false
            return {
                ...state,
                auth: state.auth,
                errorVerifyMessage: payload,
                successMessage: "",
                showLoading: false,
            }

        case LOGOUT_ACTION:
            return {
                ...state,
                errorMessage: "",
                successMessage: "",
                auth: {
                    email: "",
                    token: "",
                    localId: "",
                    expiresIn: "",
                    refreshToken: "",
                },
            }

        case USER_FORGOT_PASSWORD_FAILED:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: "",

            }

        case SIGNUP_FAILED_ACTION:
        case LOGIN_FAILED_ACTION:
        case USER_RESEND_PIN_FAILED:
            return {
                ...state,
                errorMessage: payload,
                successMessage: "",
                showLoading: false,
                AuthDataLoading: false,
            }

        case LOADING_TOGGLE_ACTION:
            return {
                ...state,
                showLoading: payload,
            }
        default:
            return state
    }
}
