import React, { useEffect, useState } from "react"
import { Link, withRouter } from "react-router-dom"
import { Col, ModalBody, Row, Spinner, input } from "reactstrap"

import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import Alert from "./../Common/Alert"

import companyProfile from "../../../images/svg/default.svg"
import ProfileImage from "../Common/ProfileImage"
import {
  companyInviteCandidateAction
} from "../../../store/actions/CompanyActions"
import { getMeiaAddedMessage, getCompany } from "../../../store/selectors/CompanySelector"
import { connect } from "react-redux"

const InviteCandidate = (props) => {
  const { position, getCompany, toggle, getMessage } =props;
  let errorsObj = { email: "", fName: "", lName: "", phone: "", }
  const [errors, setErrors] = useState(errorsObj)
  const [email, setEmail] = useState("")
  const [fName, setFName] = useState("")
  const [lName, setLName] = useState("")
  const [alert, setAlert] = useState(false)
  const [loader, setLoader] = useState(false)
  const [phone, setPhone] = useState(undefined)
  console.log("Invite Position: ", position)
  const onsubmit = (e) => {

    e.preventDefault()
    let error = false
    const errorObj = { ...errorsObj }
    if (email === "") {
      errorObj.email = "Email Address is Required"
      error = true
    }
    if (fName === "") {
      errorObj.fName = "First Name is Required"
      error = true
    }
    if (lName === "") {
      errorObj.lName = "Last Name is Required"
      error = true
    }
    if (!phone) {
      errorObj.phone = "Phone is Required"
      error = true
    }
    setErrors(errorObj)
    if (error) {
      return
    }

    const data = {
      user_data: {
        first_name: fName,
        last_name: lName,
        phone: phone,
        email: email
      },
      position_url: position?.id
    }
    setLoader(true)
    props.companyInviteCandidate(data, props, (bol) =>{
        setAlert(true);
        setLoader(false)
    })
  }

  return (
    <ModalBody className="">
      {/* <form className="form-horizontal" > */}
      <Alert alert={alert} type={(getMessage?.success || getMessage?.delete) ? "success" : getMessage?.error && "error"}
             variant={(getMessage?.success || getMessage?.delete) ? "success" : getMessage?.error && "danger"}
             msg={getMessage?.success || getMessage?.delete || getMessage?.error}
             dismissAlert={() => setAlert(false)} />
      <Row className="align-items-center justify-content-center">
        <Col lg={3}>
          <ProfileImage image={getCompany?.logo || companyProfile} />
        </Col>
        <Col lg={9}>
          <div className="text-subtitle">
            <h5>{getCompany?.name}</h5>
            <div>Invite a new candidate</div>
            <span className="d-block text-muted mt-1">
                An email will be sent to them with the link for the interview
              </span>
          </div>
        </Col>
      </Row>
      <hr />
      {/* <div> */}
        <div>
          <div className="form-group">
            <label className="text-label">Email <span className="text-muted"><small>Required</small></span></label>
            <input type="email" className="form-control" value={email} placeholder="user@example.com"
                   onChange={(e) => setEmail(e.target.value)} />
            {errors.email &&
              <div className="animated fadeInUp text-danger fs-12" style={{ marginTop: 5 }}>{errors.email}</div>}
          </div>
          <div className="form-group">
            <label className="text-label">First Name <span className="text-muted"><small>Required</small></span></label>
            <input type="text" className="form-control" value={fName} placeholder="Enter First Name"
                   onChange={(e) => setFName(e.target.value)} />
            {errors.fName &&
              <div className="animated fadeInUp text-danger fs-12" style={{ marginTop: 5 }}>{errors.fName}</div>}
          </div>
          <div className="form-group">
            <label className="text-label">Last Name <span className="text-muted"><small>Required</small></span></label>
            <input type="text" className="form-control" value={lName} placeholder="Enter Last Name"
                   onChange={(e) => setLName(e.target.value)} />
            {errors.lName &&
              <div className="animated fadeInUp text-danger fs-12" style={{ marginTop: 5 }}>{errors.lName}</div>}
          </div>
          <div className="form-group">
            <label> Phone{" "} <span className="text-muted"><small>Required</small></span></label>
            <PhoneInput
              country={"us"}
              value={phone}
              onChange={phone => setPhone(phone)}
              placeholder="541-754-3010"
              inputStyle={{ width: "99%", height: "-webkit-fill-available", border: "hidden" }}
              containerClass={"form-control"}
              containerStyle={{ padding: 0 }}
              buttonStyle={{ borderRadius: " 10px 0 0 10px", border: "0" }}
            />
            {errors.phone &&
              <div className="animated fadeInUp text-danger fs-12" style={{ marginTop: 5 }}>{errors.phone}</div>}
          </div>
        </div>
        <Row className="justify-content-center">
          <Col lg={6}>
            <button
              type="button"
              className="btn btn-block btn-outline-secondary"
              onClick={() => toggle()}
            >
              Cancel
            </button>
          </Col>
          <Col lg={6}>
            <button
              type="button"
              onClick={onsubmit}
              disabled={loader}
              className="btn btn-block btn-primary"
            >
              {loader ? (
                <Spinner
                  size="sm"
                  className="m-auto text-center"
                  color="light"
                />
              ) : (
                "Send Invite"
              )}
            </button>
          </Col>
        </Row>
      {/* </form> */}
    </ModalBody>
  )
}

const mapStateToProps = (state) => {
  return {
    getMessage: getMeiaAddedMessage(state),
    getCompany: getCompany(state)
  }
}
const mapDispatchToProps = dispatch => {
  return {
    companyInviteCandidate: (data, props, cb) => dispatch(companyInviteCandidateAction(data, props.history, cb))

  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InviteCandidate))
