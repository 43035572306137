export const VERIFY_CONFIRMED_ACTION = "[verify action] confirmed verify"
export const VERIFY_FAILED_ACTION = "[verify action] failed verify"
export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup"
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup"
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login"
export const LOGIN_MISSING_DATA_ACTION = "[login action] login missing data"
export const LOGIN_FAILED_ACTION = "[login action] failed login"
export const LOGIN_REQUEST_ACTION = "[login action] request login"
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading"
export const LOGOUT_ACTION = "[Logout action] logout action"
export const COMPANY_PROFILE_COMPLETED = "[company profile action] company profile action"
export const USER_PROFILE_COMPLETED = "[user profile action] user profile action completed"
export const USER_PROFILE_FAILED = "[user profile action] user profile action failed"

export const USER_PROFILE_UPDATE_COMPLETED = "[user profile update action] user profile action completed"
export const USER_PROFILE_UPDATE_FAILED = "[user profile update action] user profile action failed"
export const USER_PROFILE_UPDATE_REQUEST = "[user profile update request] user profile action request"

export const USER_CHANGE_PASSWORD_COMPLETED = "[user change password action] user change password completed"
export const USER_CHANGE_PASSWORD_FAILED = "[user change password action] user change password failed"

export const USER_PERMISSION_COMPLETED = "[user permission action] user permission completed"
export const USER_PERMISSION_FAILED = "[user permission action] user permission failed"

export const USER_FORGOT_PASSWORD_COMPLETED = "[user forgot password action] user forgot password completed"
export const USER_FORGOT_PASSWORD_FAILED = "[user forgot password action] user forgot password failed"
export const USER_FORGOT_PASSWORD_REQUEST = "[user forgot password request action] user forgot password requested"

export const USER_RESEND_PIN_COMPLETED = "[user resent pin action] user resent pin completed"
export const USER_RESEND_PIN_FAILED = "[user resent pin action] user resent pin failed"
export const USER_RESEND_PIN_REQUEST = "[user resent pin request action] user resent pin requested"

export const USER_AUTO_RESEND_PIN_COMPLETED = "[user resent pin action] user auto resent pin completed"
export const USER_AUTO_RESEND_PIN_FAILED = "[user resent pin action] user auto resent pin failed"
export const USER_AUTO_RESEND_PIN_REQUEST = "[user resent pin request action] user auto resent pin requested"

export const USER_DEACTIVATE_ACCOUNT_REQUEST_COMPLETED =
    "[user deactivate account request action] user deactivate account request completed"
export const USER_DEACTIVATE_ACCOUNT_REQUEST_FAILED =
    "[user deactivate account request action] user deactivate account request failed"
