import React, {useState, useCallback} from 'react';
import {connect} from "react-redux"
import {withRouter} from "react-router-dom"
import {Col, Row, Card, Tab, Nav} from "react-bootstrap"
import PageTitle from "../../layouts/PageTitle";
import Alert from "./../Common/Alert"
import PositionDetail from "./common/PositionDetail";
import AddPositionQuestions from "./common/AddPositionQuestions";
import AddPositionReview from "./common/AddPositionReview";
import "./positions.css"
import {getMeiaAddedMessage} from "../../../store/selectors/CompanySelector"


const PositionEdit = (props) => {
    const {getMessage} = props;
    const [active, setActive] = useState('Position');
    const [alert, showAlert] = useState(false)
    const changetab = useCallback((tabName) => {
            setActive(tabName);
        }, [],
    );
    const tabData = [
        {
            name: 'Position',
            icon: <i className="bx bx-file bx-md mt-2 "/>,
            content: <PositionDetail activeTab={active === 'Position'} nextTab={(tabName) => changetab(tabName)}
                                     modeOn={'edit'} showAlert={() => showAlert(true)}/>,
        },
        {
            name: 'Questions',
            icon: <i className="bx bx-chat bx-md mt-2 "/>,
            content: <AddPositionQuestions activeTab={active === 'Questions'} nextTab={(tabName) => changetab(tabName)}
                                           modeOn={'edit'} showAlert={() => showAlert(true)}/>,
        },
        {
            name: 'Review',
            icon: <i className="bx bx-badge-check bx-md mt-2 "/>,
            content: <AddPositionReview activeTab={active === 'Review'} nextTab={(tabName) => changetab(tabName)}
                                        modeOn={'edit'} showAlert={() => showAlert(true)}/>,
        },
    ];

    return (
        <>
            {/*<PageTitle activeMenu={"Edit"} motherMenu="Dashboard / Positions" />*/}
            <Alert alert={alert}
                   type={(getMessage?.success || getMessage?.delete) ? "success" : getMessage?.error && "error"}
                   variant={(getMessage?.success || getMessage?.delete || getMessage?.updated) ? "success" : getMessage?.error && "danger"}
                   msg={getMessage?.success || getMessage?.delete || getMessage?.error || getMessage?.updated}
                   dismissAlert={() => showAlert(false)}/>
            <Col xl={12} lg={12} md={12} sm={12}>
                <Row>
                    <Tab.Container defaultActiveKey={active} onSelect={(e) => changetab(e)}>
                        <Col xl={2}>
                            <Nav as="ul" className="flex-column nav-pills mb-1">
                                {tabData.map((data, i) => (
                                    <Nav.Item as="li" key={i}>
                                        <Card>
                                            <Nav.Link eventKey={data.name} key={i} active={data.name === active}
                                                      disabled={data.disable}>
                                                <Col className='text-center'>
                                                    {data.icon}
                                                    <p>{data.name}</p>
                                                </Col>
                                            </Nav.Link>
                                        </Card>
                                    </Nav.Item>
                                ))}
                            </Nav>
                        </Col>
                        <Col xl={10}>
                            <Tab.Content className="ml-2">
                                {tabData.map((data, i) => (
                                    <Tab.Pane eventKey={data.name} key={i} active={data.name === active}
                                              disabled={data.disable}>
                                        {data.content}
                                    </Tab.Pane>
                                ))}
                            </Tab.Content>{" "}
                        </Col>
                    </Tab.Container>
                </Row>
            </Col>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        getMessage: getMeiaAddedMessage(state)
    }
}

export default withRouter(connect(mapStateToProps, null)(PositionEdit))