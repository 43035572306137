import React, {useState} from "react";
import {Link, withRouter} from "react-router-dom";
// image
// import logo from "../../images/logo-full.png";
import logo from "../../images/logo-dark.svg"
import {userForgotPasswordAction} from "../../store/actions/AuthActions";
import {connect} from "react-redux";
import Loader from "../components/Loader"
import Alert from "../components/Common/Alert";
import {
    getCompany,
    getCompanyCountries, getCompanyIndustries, getCompanyLoading,
    getCompanyMembers,
    getCompanyMessage
} from "../../store/selectors/CompanySelector";
import {getUserMessage} from "../../store/selectors/AuthSelectors";

const ForgotPassword = (props) => {
    const {userForgotPassword, getUserMessage} = props

    const [alert, showAlert] = useState(false);
    const [email, setEmail] = useState()
    const [loader, setLoader] = useState()
    const onSubmit = (e) => {
        e.preventDefault();
        setLoader(true)
        const d = {
            email
        }
        userForgotPassword(d, props, (res) => {
            if (res) {
                setLoader(false)
                showAlert(true)

                // props.history.push("/login")
            }
        })
        // history.push("/dashboard");
    };
    return (
        <div className="authincation h-100 p-meddle">
            <div className="container h-100">

                {" "}
                <div className="row justify-content-center h-100 align-items-center">

                    <div className="col-md-6">
                        <div className="authincation-content">
                            <div className="row no-gutters">
                                <div className="col-xl-12">
                                    <div className="auth-form">
                                        <Alert
                                            alert={alert}
                                            type={
                                                "success"
                                            }
                                            variant={
                                                "success"
                                            }
                                            msg={getUserMessage}
                                            dismissAlert={() => showAlert(false)}
                                        />
                                        <div className="text-center mb-3">
                                            <Link to="/dashboard">
                                                <img src={logo} style={{maxWidth: "300px"}} alt=""/>
                                            </Link>
                                        </div>
                                        <h4 className="text-center mb-4 ">Forgot Password</h4>
                                        <form onSubmit={(e) => {
                                        }}>
                                            <div className="form-group">
                                                <label className="">
                                                    <strong>Email</strong>
                                                </label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    placeholder="hello@example.com"
                                                    value={email}
                                                    onChange={(e) => {
                                                        setEmail(e?.target?.value)
                                                    }}
                                                />
                                            </div>
                                            <p style={{
                                                fontSize: "12px",
                                                lineHeight: "1.5",
                                                marginTop: "-7px"
                                            }}>Temporary password email will be dispatched. you can use that password to
                                                login</p>
                                            <div className="text-center">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary btn-block"
                                                    onClick={onSubmit}
                                                >
                                                    {
                                                        loader ? <Loader spinnerClass="text-white"/> : "Submit"
                                                    }
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProp = (dispatch) => {
    return {
        userForgotPassword: (data, prop, cb) => dispatch(userForgotPasswordAction(data, prop.history, cb))
    }
}


const mapStateToProps = (state) => {
    return {
        getUserMessage: getUserMessage(state)
    };
};


export default withRouter(connect(mapStateToProps, mapDispatchToProp)(ForgotPassword));
