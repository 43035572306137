import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Modal, Row, Spinner } from "reactstrap"
import DeletePrompt from "../../Common/DeletePrompt"
import Switch from "../../Common/Switch"
import {
  updateCompanyPositionAction,
  addCompanyPositionReminderAction,
  removeCompanyPositionReminderAction,
} from "../../../../store/actions/CompanyActions"
import { connect, useDispatch } from "react-redux"
import { withRouter } from "react-router-dom"
import moment from "moment"
import "./PositionActions.css"

const PositionActions = ({
  position,
  props,
  updateCompanyPosition,
  showAlert,
  addCompanyPositionReminder,
  removeCompanyPositionReminder,
}) => {
  // console.log(position?.position_reminder);
  const [loader, setLoader] = useState(false)
  const [reminder, setReminder] = useState(false)
  const [deactivateModal, setDeactivateModal] = useState({
    show: false,
    func: undefined,
  })

  useEffect(() => {
    setReminder(position?.position_reminder)
  }, [position?.position_reminder])

  let editPath = window.location.pathname.includes("edit")
  return (
    <Card style={{ height: "auto" }} className="position-actions">
      <CardBody>
        <Row>
          <Col md={6}>
            <h5>Position Actions</h5>
          </Col>
        </Row>
        {position?.status !== "draft" && (
          <>
            <hr />
            <Row className=" align-items-center">
              <Col md={6}>
                <h6>Position Status</h6>
              </Col>
              <Col md={6}>
                <div className="custom-control float-right" dir="ltr">
                  <Switch
                    isOn={position?.status === "open" ? true : false}
                    forId={`position-detail-${props?.modeOn}-status-item-${position.createdAt}`}
                    handleToggle={() => {
                      position?.status === "open"
                        ? setDeactivateModal({
                            show: true,
                            func: () => {
                              updateCompanyPosition(
                                {
                                  position_id: position?.id,
                                  position_data: {
                                    status: "close",
                                  },
                                },
                                props,
                                () => {
                                  showAlert()
                                  setLoader(false)
                                }
                              )
                              setDeactivateModal(undefined)
                            },
                          })
                        : updateCompanyPosition(
                          {
                            position_id: position?.id,
                            position_data: {
                              status: "open",
                            },
                          },
                          props,
                          () => {
                            showAlert()
                            setLoader(false)
                          }
                        )
                      }
                    }
                  />
                </div>
              </Col>
            </Row>
          </>
        )}
        <hr />
        <Row>
          <Col md={10}>
            <div className="text-secondary">
              <i className="fa fa-paper-plane"></i> Turn on Email Reminders for your account.
            </div>
            <span className="text-muted">Get Notified by email for every new completed interview</span>
          </Col>
          <Col md={2}>
            <div className="custom-control float-right" dir="ltr">
              <Switch
                isOn={reminder}
                forId={`position-detail-reminder-${props?.modeOn}-item-${position.createdAt}`}
                handleToggle={() => {
                  setReminder(!reminder)
                  position?.position_reminder
                    ? removeCompanyPositionReminder({ position_id: position.id }, props, (bol) => {
                        showAlert()
                        setLoader(false)
                        if(!bol) setReminder(position?.position_reminder)
                      })
                    : addCompanyPositionReminder({ position_id: position.id }, props, (bol) => {
                          showAlert()
                          setLoader(false)
                          if(!bol) setReminder(position?.position_reminder)
                        }
                      )
                }}
              />
            </div>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col lg={12}>
            <div className="">
              {position?.status !== "draft" &&
                (editPath ? (
                  <button
                    className="btn btn-outline-secondary btn-block"
                    onClick={() => {
                      setLoader(true)
                      updateCompanyPosition(
                        {
                          position_id: position?.id,
                          position_data: {
                            status: "draft",
                          },
                        },
                        props,
                        () => {
                          showAlert()
                          setLoader(false)
                        }
                      )
                    }}
                  >
                    Save as Draft
                  </button>
                ) : (
                  <button
                    className="btn btn-outline-secondary btn-block"
                    onClick={() => props.history.push(`/position/edit/${position?.id}`)}
                  >
                    Edit Position
                  </button>
                ))}
              {/* <div className="d-inline-block">
                <Link
                  className="btn btn-outline-primary"
                  style={{ minWidth: "150px" }}
                  to={"/position"}
                >
                  Return
                </Link>
              </div> */}
              {/* <button className="btn btn-primary btn-block">Save Changes</button> */}
              {
                <>
                  {position?.status === "draft" && (
                    <button
                      className="btn btn-primary btn-block"
                      onClick={() => {
                        setLoader(true)
                        updateCompanyPosition(
                          {
                            position_id: position?.id,
                            position_data: {
                              status: "open",
                            },
                          },
                          props,
                          () => {
                            showAlert()
                            setLoader(false)
                          }
                        )
                      }}
                      disabled={loader || position?.Questions?.length === 0}
                      // style={{ minWidth: "150px" }}
                    >
                      {loader ? <Spinner size="sm" className="m-auto text-center" color="light" /> : "Publish"}
                    </button>
                  )}
                </>
              }
              <Modal
                centered={true}
                isOpen={deactivateModal?.show}
                toggle={() => {
                  setDeactivateModal(undefined)
                }}
              >
                <DeletePrompt
                  title={"Are you sure you want to deactivate this Position ?"}
                  btnText="Deactivate"
                  func={() => deactivateModal?.func()}
                  toggle={() => {
                    setDeactivateModal(undefined)
                  }}
                />
              </Modal>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateCompanyPosition: (data, props, cb) => dispatch(updateCompanyPositionAction(data, props.history, cb)),
    addCompanyPositionReminder: (data, props, cb) =>
      dispatch(addCompanyPositionReminderAction(data, props.history, cb)),
    removeCompanyPositionReminder: (data, props, cb) =>
      dispatch(removeCompanyPositionReminderAction(data, props.history, cb)),
  }
}

export default withRouter(connect(null, mapDispatchToProps)(PositionActions))
