import axiosInstance from "../services/AxiosInstance"

export function getCompanyReportService() {
  return axiosInstance.get(`/company/report`)
}
export function getCompanyReportMembersService() {
  return axiosInstance.get(`/company/members`)
}
export function getCompanyReportPositionsService(data) {
  return axiosInstance.post(`/company/dashboard/positions`, data)
}
export function getCompanyReportPositionService(data) {
  return axiosInstance.post(`/company/dashboard/position`, data)
}
