import {
  GET_COMPANY_REPORT_FAILED,
  GET_COMPANY_REPORT_COMPLETED,
  GET_COMPANY_REPORT_REQUEST,
  GET_COMPANY_REPORT_MEMBERS_COMPLETED,
  GET_COMPANY_REPORT_MEMBERS_FAILED,
  GET_COMPANY_REPORT_MEMBERS_REQUEST,
  GET_COMPANY_REPORT_POSITIONS_COMPLETED,
  GET_COMPANY_REPORT_POSITIONS_FAILED,
  GET_COMPANY_REPORT_POSITIONS_REQUEST,
  GET_COMPANY_REPORT_POSITION_COMPLETED,
  GET_COMPANY_REPORT_POSITION_FAILED,
  GET_COMPANY_REPORT_POSITION_REQUEST,
} from "../actions/constants/Report"

const initialState = {
  reportLoading: false,
  reportData: undefined,
  errorMessage: undefined,
  reportMembersData: undefined,
  reportMembersLoading: false,
  reportPositionsData: undefined,
  reportPositionsLoading: false,
  reportPositionData: undefined,
  reportPositionLoading: false,
}

export function ReportReducer(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case GET_COMPANY_REPORT_REQUEST:
      return {
        ...state,
        reportLoading: true,
      }
    case GET_COMPANY_REPORT_COMPLETED:
      return {
        ...state,
        reportLoading: false,
        reportData: payload,
      }
    case GET_COMPANY_REPORT_FAILED:
      return {
        ...state,
        reportLoading: false,
        errorMessage: payload,
      }
    case GET_COMPANY_REPORT_MEMBERS_REQUEST:
      return {
        ...state,
        reportMembersLoading: true,
      }
    case GET_COMPANY_REPORT_MEMBERS_COMPLETED:
      return {
        ...state,
        reportMembersLoading: false,
        reportMembersData: payload,
      }
    case GET_COMPANY_REPORT_MEMBERS_FAILED:
      return {
        ...state,
        reportMembersLoading: false,
        errorMessage: payload,
      }
    case GET_COMPANY_REPORT_POSITIONS_REQUEST:
      return {
        ...state,
        reportPositionsLoading: true,
      }
    case GET_COMPANY_REPORT_POSITIONS_COMPLETED:
      return {
        ...state,
        reportPositionsLoading: false,
        reportPositionsData: payload,
      }
    case GET_COMPANY_REPORT_POSITIONS_FAILED:
      return {
        ...state,
        reportPositionsLoading: false,
        errorMessage: payload,
      }
    case GET_COMPANY_REPORT_POSITION_REQUEST:
      return {
        ...state,
        reportPositionLoading: true,
      }
    case GET_COMPANY_REPORT_POSITION_COMPLETED:
      return {
        ...state,
        reportPositionLoading: false,
        reportPositionData: payload,
      }
    case GET_COMPANY_REPORT_POSITION_FAILED:
      return {
        ...state,
        reportPositionLoading: false,
        errorMessage: payload,
      }
    default:
      return state
  }
}
