import React from "react"
import ReactApexChart from "react-apexcharts"

class ActiveUsersChart extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            series: [
                {
                    name: "Percentage",
                    data: [
                        {
                            x: "04:00-06:00",
                            y: 12,
                            // goals: [
                            //     {
                            //         name: "Expected",
                            //         value: 14,
                            //         strokeWidth: 2,
                            //         strokeDashArray: 2,
                            //         strokeColor: "#001c35",
                            //     },
                            // ],
                        },
                        {
                            x: "14:00-16:00",
                            y: 44,
                            // goals: [
                            //     {
                            //         name: "Expected",
                            //         value: 54,
                            //         strokeWidth: 5,
                            //         strokeHeight: 10,
                            //         strokeColor: "#001c35",
                            //     },
                            // ],
                        },
                        {
                            x: "16:00-18:00",
                            y: 54,
                            // goals: [
                            //     {
                            //         name: "Expected",
                            //         value: 52,
                            //         strokeWidth: 10,
                            //         strokeHeight: 0,
                            //         strokeLineCap: "round",
                            //         strokeColor: "#001c35",
                            //     },
                            // ],
                        },
                        {
                            x: "12:00-14:00",
                            y: 66,
                            // goals: [
                            //     {
                            //         name: "Expected",
                            //         value: 61,
                            //         strokeWidth: 10,
                            //         strokeHeight: 0,
                            //         strokeLineCap: "round",
                            //         strokeColor: "#001c35",
                            //     },
                            // ],
                        },
                        {
                            x: "02:00-04:00",
                            y: 81,
                            // goals: [
                            //     {
                            //         name: "Expected",
                            //         value: 66,
                            //         strokeWidth: 10,
                            //         strokeHeight: 0,
                            //         strokeLineCap: "round",
                            //         strokeColor: "#001c35",
                            //     },
                            // ],
                        },
                        // {
                        //   x: "10:00-12:00",
                        //   y: 67,
                        //   goals: [
                        //     {
                        //       name: "Expected",
                        //       value: 70,
                        //       strokeWidth: 5,
                        //       strokeHeight: 10,
                        //       strokeColor: "#775DD0",
                        //     },
                        //   ],
                        // },
                        // {
                        //   x: "20:00-22:00",
                        //   y: 67,
                        //   goals: [
                        //     {
                        //       name: "Expected",
                        //       value: 70,
                        //       strokeWidth: 5,
                        //       strokeHeight: 10,
                        //       strokeColor: "#775DD0",
                        //     },
                        //   ],
                        // },
                    ],
                },
            ],
            options: {
                chart: {
                    // height: 350,
                    type: "bar",
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                    },
                },
                colors: [
                    function ({value, seriesIndex, dataPointIndex, w}) {
                        if (dataPointIndex == 0) {
                            return "#001c35"
                        } else if (dataPointIndex == 1) {
                            return "#004164"
                        } else if (dataPointIndex == 2) {
                            return "#006b7e"
                        } else if (dataPointIndex == 3) {
                            return "#009477"
                        } else if (dataPointIndex == 4) {
                            return "#00ba52"
                        } else if (dataPointIndex == 5) {
                            return "#556ee6"
                        } else if (dataPointIndex == 6) {
                            return "#f46a6a"
                        }
                    },
                ],
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                        return val + "%"
                    },
                    offsetX: 35,
                    style: {
                        fontSize: "10px",
                        colors: ["#001c35"],
                    },
                },
                textAnchor: "middle",
                legend: {
                    show: false,
                    showForSingleSeries: true,
                    customLegendItems: ["04:00-06:00", "Expected"],
                    markers: {
                        fillColors: ["#FF4C41", "#001c35"],
                    },
                },
            },
        }
    }

    render() {
        return (
            <div id="chart" className="apex-chart">
                <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={350}/>
            </div>
        )
    }
}

export default ActiveUsersChart
