import PropTypes from "prop-types"
import React, {useState, useEffect} from "react"
import {Container, Row, Col, Card, CardBody, Progress, Spinner} from "reactstrap"
import {Modal} from "react-bootstrap"
import {Link, withRouter} from "react-router-dom"


import "../positions.css"
import ShareInterview from "./ShareInterview"
import QuestionsDetails from "./QuestionsDetails"
import UserPositionDetails from "./UserPositionDetails"
import AddTeamMember from "./AddTeamMember"
import PositionActions from "./PositionActions"
import InviteCandidate from "../InviteCandidate"
import {connect} from "react-redux"
import {
    getCompanyCurrentPosition,
    getMeiaAddedMessage,
    getCompany
} from "../../../../store/selectors/CompanySelector"
import {
    addCompanyPositionTeamMemberAction,
    removeCompanyPositionTeamMemberAction,
    getCompanyPositionAction, 
} from "../../../../store/actions/CompanyActions"

const AddPositionReview = (props) => {

    const {
        position,
        getCurrentPosition,
        getMessage,
        modeOn,
        getCompany,
        showAlert,
        activeTab
    } = props
    const [inviteModal, setInviteModal] = useState(false)
    const [pos, setpos] = useState({});

    useEffect(() => {
        setpos(getCurrentPosition)
    }, [getCurrentPosition]);
    useEffect(() => {
        if(modeOn ==='add' && activeTab) {
            const data = {
                position_id: getCurrentPosition?.id
            }
            props.getCompanyPosition(data, props,)
        }
    }, [activeTab]);

    useEffect(() => {
        if(getMessage?.showLoading){
            if(modeOn ==='add' && activeTab) {
                const data = {
                    position_id: getCurrentPosition?.id
                }
                props.getCompanyPosition(data, props,)
            }
            if (modeOn ==='edit') {
                const pos_id = String(props?.location?.pathname).split('/position/edit/')[1]
                const data = {
                    position_id: pos_id
                }
                props.getCompanyPosition(data, props)
            }
        }
    }, [getMessage?.showLoading])

    

    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <UserPositionDetails position={pos} company={getCompany}/>
                </Col>
                <Col lg={8}>
                    <ShareInterview setInviteModal={setInviteModal} position={pos}/>
                    <QuestionsDetails position={pos}/>
                </Col>
                <Col md={4}>
                    <AddTeamMember position={pos}  showAlert={()=>showAlert()}/>
                    <PositionActions position={pos} props={props}  showAlert={()=>showAlert()}/>
                </Col>
            </Row>
            {/* <Row className="my-4 align-items-center">
        <Col sm="6"> 
          <Link to="/company/positions" className="btn btn-link secondary" >
              <i className="mdi mdi-arrow-left mr-1"></i>Return to Positions
          </Link>
        </Col>
        <Col sm="6">

        </Col>
      </Row> */}
            <Modal centered={true} show={inviteModal} onHide={() => {
                setInviteModal(!inviteModal)
            }}>
                <InviteCandidate position={pos} showAlert={()=>showAlert()} toggle={() => {
                    setInviteModal(!inviteModal)
                }}/>
            </Modal>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        getCompany: getCompany(state),
        getCurrentPosition: getCompanyCurrentPosition(state),
        getMessage: getMeiaAddedMessage(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCompanyPosition: (data, props, cb) => dispatch(getCompanyPositionAction(data, props.history, cb)),
        addCompanyPositionTeamMember: (data, props, cb) => dispatch(addCompanyPositionTeamMemberAction(data, props.history, cb)),
        removeCompanyPositionTeamMember: (data, props, cb) => dispatch(removeCompanyPositionTeamMemberAction(data, props.history, cb)),
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddPositionReview))