import React from 'react'
import { Card, CardBody, Col, Media, Row } from 'reactstrap'
import { Link, withRouter } from "react-router-dom"

import avatar from "../../../images/default.svg"
import PhoneNumber from '../Common/phoneNumber'

import { editCompanyMemberRoleAction } from "../../../store/actions/CompanyActions"
import { connect } from "react-redux"
import { getEditRoleMessage } from "../../../store/selectors/CompanySelector"


const EditRole = (props) => {
  const { RenderRole, member, toggle, alert } =props;
  const roles = [{
    title: 'Manager',
    value: 'manager'
  }, {
    title: "Recruiter",
    value: "recruiter"
  }]

  function onClickCHangeRole (role, type){
    console.log({role,type});
    const data = {
      company_member_id: member.id,
      role_name: role,
      type: type
    }
    props.editMemberRole(data, props,  () =>{toggle();alert()});
  }

  return <Card className="overflow-hidden">
    <CardBody>
      <button className="float-right btn btn-link-secondary" onClick={() => toggle()}>close</button>
      <Row className="align-items-center mb-4">
        <Col sm="4">
          <div className="avatar-xxl mt-4">
            <img
              src={member?.AddedMemberUser?.image || avatar}
              alt=""
              className="img-thumbnail rounded-circle"
            />
          </div>
        </Col>
        <Col sm="8">
          <div className="mt-4">
            <h5>{member?.AddedMemberUser?.first_name} {member?.AddedMemberUser?.last_name} </h5>
            {RenderRole}
            <span className="d-block mb-1">member?.user?.email</span>
            <span className="d-block mb-1"><PhoneNumber number={'92 123 123312'} /></span>
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col lg={12}>
          <div className="text-primary text-center mb-3">Change User role</div>
        </Col>
        <Col>
          <div className="new-tag-members">
            <ul className="list-group">
            {
              roles.map((role, index) => (
                  <li style={{ cursor:  "pointer" }} onClick={() => {
                    member?.AddedMemberUser?.Roles.length > 0 ?  member?.AddedMemberUser?.Roles.map((e, i)=> ((role?.value === e?.name && e?.name !=='owner') ? onClickCHangeRole(role.value, 0) : onClickCHangeRole(role.value,  1))) : onClickCHangeRole(role.value,  1) ;
                  }} className="list-group-item" key={role.value}>
                    <Row>
                      <Col lg="8">
                        <div className="d-inline-block mr-2">{role?.title}</div>
                      </Col>
                      <Col lg="4">
                        <i className={`fa fa-check-circle float-right ${member?.AddedMemberUser?.Roles.map(e => role.value === e.name ? ' text-primary ' : "")}`}></i>
                      </Col>
                    </Row>
                  </li>
                ))
            }
            </ul>
          </div>
        </Col>
      </Row>
    </CardBody>
  </Card>
}

const mapStateToProps = (state) => {
  return {
    responseMessage: getEditRoleMessage(state),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    editMemberRole: (data, props, cb) => dispatch(editCompanyMemberRoleAction(data, props.history, cb)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditRole))