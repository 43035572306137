import React, { useState, Fragment } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Badge } from "reactstrap";
import moment from "moment";

const PaymentList = (props) => {
  const { payData } = props;

  return (
    <Fragment>
      <tbody>
        {payData.map((item) => (
          <tr key={item.id}>
            <td className="text-capitalize">{item?.name}</td>
            <td>${item?.amount_paid}.00</td>
            <td>
              {item?.date_paid
                ? moment(item?.date_paid).format("MMM DD, YYYY")
                : "N/A"}
            </td>
            <td>
                {item?.status}
              {/*{item?.status ? (*/}
              {/*  // <Badge color="success light"><i className="bx bx-check"></i> Paid</Badge>*/}
              {/*  <div class="d-flex align-items-center">*/}
              {/*    <i class="fa fa-circle text-success mr-1"></i> Successful*/}
              {/*  </div>*/}
              {/*) : (*/}
              {/*  <Badge color="danger light">Unpaid</Badge>*/}
              {/*)}*/}
            </td>
          </tr>
        ))}
      </tbody>
    </Fragment>
  );
};

// const mapStateToProps = state => {
//   return {
//     getCandidates: getCompanyCandidates(state),
//     getSubscription: getCompanySubscription(state)
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     deleteCandidates: (data, props, cb) => dispatch(deleteCompanyCandidateAction(data, props.history, cb)),
//     resetCandidates: (data, props, cb) => dispatch(resetCompanyCandidateAction(data, props.history, cb)),
//     sendReminder: (data, props, cb) => dispatch(sendCompanyCandidateReminderAction(data, props.history, cb)),
//     deleteCompanyTag: (data, props, cb) => dispatch(deleteCompanyTagAction(data, props.history, cb)),
//     removeCompanyCandidateTag: (data, props, cb) => dispatch(removeCompanyCandidateTagAction(data, props.history, cb))
//   }
// }
export default withRouter(connect(null, null)(PaymentList));
