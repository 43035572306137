import React, {useState} from "react";
import {Alert, Card, CardBody, Col, Row, Button, Modal} from "reactstrap"
import 'react-phone-input-2/lib/style.css'
import {userDeactivateAccountRequestAction} from "../../../store/actions/AuthActions"
import {withRouter} from "react-router-dom"
import {connect} from "react-redux"
import {getCompanyAction, updateCompanyAction} from "../../../store/actions/CompanyActions"
import DeletePrompt from "../Common/DeletePrompt";

const PasswordSection = (props) => {
    // const [reason, setReason] = useState('')

    // console.log(deactivateModal)
    const onSubmit = (e) => {
        e.preventDefault();

        props.setDeactivateModal({show: true, func: () => props.userDeactivateAccountRequest(props.showAlert)})
        // console.log(deactivateModal, "STATE")
    }
    return (

        <React.Fragment>
            <Row>
                <Col lg={2}>
                    <i className="fa fa-exclamation-triangle display-1 red" style={{"color": "red"}}></i>
                </Col>
                <Col lg={10}>
                    <p className="mb-2">
                        <b>Warning:</b> You will lose your data after 30 days and ALL team
                        member accounts associated with this company will be deactivated
                        at the end of this billing period.
                        <br/>
                        <br/>
                        If this is temporary, please contact <a href="mailto:support@digitalhire.com"
                                                                className="text-primary">support@digitalhire.com</a>.
                    </p>
                    <h6>
                        Reason for Deactivation <span className="text-danger">*</span>
                    </h6>
                    <textarea className="form-control"></textarea>
                    {/* <div className="border">
            <textarea
              style={{ width: "100%", border: "none" }}
              onChange={e => setReason(e.target.value)}
            ></textarea>
          </div> */}
                </Col>
            </Row>
            <Row className=" mt-3">
                <Col lg={2}> </Col>
                <Col lg={3}>
                    <button onClick={onSubmit} className="btn btn-primary bg-primary btn-block"> Deactivate</button>
                </Col>

            </Row>

        </React.Fragment>
    );
};
const mapDispatchToProps = dispatch => {
    return {
        userDeactivateAccountRequest: (cb) => dispatch(userDeactivateAccountRequestAction(cb))
    }
}

export default withRouter(connect(null, mapDispatchToProps)(PasswordSection));
