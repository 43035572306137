import React, {useCallback, useRef, useState, useEffect} from "react";
import {Col, InputGroup, Row, Spinner} from "reactstrap";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import createHistory from 'history/createBrowserHistory'
import {RangeDatePicker} from "@y0c/react-datepicker";

const EvaluationsFilters = ({setQuery, query, filterData, setFilterData}) => {
    const [dateCompleted, setDateCompleted] = useState(null);
    const [dateAdded, setDateAdded] = useState(null);
    const [filter, setFilter] = useState("")

    const formRef = useRef(null);
    const filterRef = useRef(null);

    useEffect(() => {
        setFilter(setFilterData?.state?.filter)
    }, [setFilterData])

    const dateCompletedPicker = useRef(null);

    const comRangeRef = useRef(null);
    const addRangeRef = useRef(null);

    const renderPositionsOptions = (positions) => {
        const result = [
            <option key={"empty"} value={""}>
                Select Position
            </option>,
        ];
        if (positions && positions.length > 0) {
            positions?.map((pos, key) => {
                result.push(
                    <option key={key} value={pos?.id}>
                        {pos?.title}
                    </option>
                );
            });
        }
        return result;
    };

    const renderStatusOptions = (statuses) => {
        const result = [
            <option key={"0000"} value={""}>
                Select Status Filter
            </option>,
        ];
        if (statuses && statuses.length > 0) {
            statuses.map((status) => {
                result.push(
                    <option key={status?.key} value={status?.key}>
                        {status?.value}
                    </option>
                );
            });
        }
        return result;
    };

    const renderMembersOptions = (members) => {
        const result = [
            <option key={"0000"} value={""}>
                Select Member
            </option>,
        ];
        if (members && members.length > 0) {
            members?.map((mem, key) => {
                result.push(
                    <option key={key} value={mem?.id}>
                        {mem?.AddedMemberUser?.first_name} {mem?.AddedMemberUser?.last_name}
                    </option>
                );
            });
        }
        return result;
    };

    const renderTagsOptions = (tags) => {
        const result = [
            <option key={"0000"} value={""}>
                Select Tag
            </option>,
        ];
        if (tags && tags.length > 0) {
            tags?.map((tag) => {
                result.push(
                    <option key={tag?.id} value={tag?.id}>
                        {tag?.name} ({tag?.tag_interView_count})
                    </option>
                );
            });
        }
        return result;
    };

    const renderResponseOptions = (response) => {
        const result = [
            <option key={"0000"} value={""}>
                Select Response
            </option>,
        ];
        if (response && response.length > 0) {
            response.map((res) => {
                result.push(
                    <option key={res} value={res}>
                        {res}
                    </option>
                );
            });
        }
        return result;
    };

    const remove = useCallback((key) => {
        setQuery(({[key]: value, ...query}) => query);
    }, []);


    const handleDateCompleted = (start, end) => {
        if (start && end) {
            setDateCompleted(start);
            setQuery({
                ...query,
                // complete_date: moment(date).format("MMM DD, YYYY"),
                complete_date_start: moment(start).format("MMM DD, YYYY"),
                complete_date_end: moment(end).format("MMM DD, YYYY"),
            });
        } else {
            remove('complete_date')
            setDateCompleted(start);
        }
    };

    const handleDateAdded = (start, end) => {
        if (start && end) {
            setDateAdded(start);
            setQuery({
                ...query,
                // added_date: moment(date).format("MMM DD, YYYY"),
                added_date_start: moment(start).format("MMM DD, YYYY"),
                added_date_end: moment(end).format("MMM DD, YYYY"),
            });
        } else {
            remove('added_date')
            setDateAdded(start);
        }
    };

    const nameChange = (value) => {
        // if (value.keyCode === 13)
        setQuery({
            ...query,
            name: value.target.value,
        });
    };

    const clearFilters = () => {
        setFilter('');
        setDateCompleted(null);
        setDateAdded(null);
        setQuery({sort_array: query.sort_array});

        comRangeRef.current.state.startValue = '';
        comRangeRef.current.state.endValue = '';
        addRangeRef.current.state.startValue = '';
        addRangeRef.current.state.endValue = '';
        formRef.current.reset();


        // hot fix for dashbord card navigations
        const history = createHistory();
        if (setFilterData?.state?.filter) {
            let state = {...setFilterData?.state?.filter};
            delete state?.filter;
            history.replace({...setFilterData, state});
        }
    };

    return (
        <div className="card evaluation-filters border border-primary">
            <div className="card-header">
                <h5 className="card-title">Filters</h5>
            </div>
            <form ref={formRef} className=" card-body pb-0 ">
                <Row>
                    <Col lg={10}>
                        <Row>
                            <Col lg={3}>
                                <div className="form-group">
                                    {/* <label className="text-secondary">Name</label> */}
                                    <input
                                        name="name"
                                        placeholder="Name"
                                        className="form-control"
                                        onKeyUp={(e) => nameChange(e)}
                                        type="text"
                                    />
                                </div>
                                <div className="docs-datepicker">
                                    {/* <label className="text-secondary">Date Completed</label> */}
                                    {/*<MuiPickersUtilsProvider utils={DateFnsUtils}>*/}
                                    {/*    <DatePicker*/}
                                    {/*        autoOk*/}
                                    {/*        clearable*/}
                                    {/*        format="yyyy-MM-dd"*/}
                                    {/*        className="form-control"*/}
                                    {/*        onChange={(e) => handleDateCompleted(e)}*/}
                                    {/*        placeholder="Date Completed"*/}
                                    {/*        value={dateCompleted}*/}
                                    {/*    />*/}
                                    {/*</MuiPickersUtilsProvider>*/}
                                    <RangeDatePicker
                                        ref={comRangeRef}
                                        onChange={handleDateCompleted}
                                        startText="Completed"
                                        // endText=""
                                        startPlaceholder="Completed"
                                        // endPlaceholder=""
                                        // value={dateCompleted}
                                    />
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="form-group">
                                    {/* <label className="text-secondary">Position</label> */}
                                    <select
                                        name="position"
                                        className="form-control"
                                        onChange={(e) =>
                                            setQuery({...query, position: e.target.value})
                                        }
                                        type="select"
                                    >
                                        {renderPositionsOptions(filterData?.positions)}
                                    </select>
                                </div>
                                <div className="docs-datepicker form-group">
                                    {/* <label className="text-label">Date Added</label> */}
                                    {/*<MuiPickersUtilsProvider utils={DateFnsUtils}>*/}
                                    {/*    <DatePicker*/}
                                    {/*        autoOk*/}
                                    {/*        clearable*/}
                                    {/*        format="yyyy-MM-dd"*/}
                                    {/*        className="form-control"*/}
                                    {/*        placeholder="Date Added"*/}
                                    {/*        value={dateAdded}*/}
                                    {/*        onChange={(e) => handleDateAdded(e)}*/}
                                    {/*    />*/}
                                    {/*</MuiPickersUtilsProvider>*/}
                                    <RangeDatePicker

                                        ref={addRangeRef}
                                        onChange={handleDateAdded}
                                        startText="Date Added"
                                        // endText=""
                                        startPlaceholder="Date Added"
                                        // endPlaceholder=""
                                        // value={dateAdded}
                                    />
                                    <div className="docs-datepicker-container"/>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="form-group">
                                    {/* <label className="text-label">Filter by Status</label> */}
                                    <select
                                        name="interviewStatus"
                                        className="form-control"
                                        type="select"
                                        value={filter}
                                        onChange={(e) => {
                                            setFilter(e.target.value)
                                            setQuery({...query, status: e.target.value})
                                        }
                                        }
                                    >
                                        {renderStatusOptions(filterData?.status)}
                                    </select>
                                </div>
                                <div className="form-group">
                                    {/* <label className="text-secondary">Tags</label> */}
                                    <select
                                        name="tags"
                                        className="form-control"
                                        onChange={(e) =>
                                            setQuery({...query, tag: e.target.value})
                                        }
                                        type="select"
                                    >
                                        {renderTagsOptions(filterData?.tags)}
                                    </select>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="form-group response-filter">
                                    {/* <label className="text-label">Response</label> */}
                                    <select
                                        name="result"
                                        className="form-control"
                                        onChange={(e) =>
                                            setQuery({...query, response: e.target.value})
                                        }
                                    >
                                        {renderResponseOptions(filterData?.response)}
                                    </select>
                                </div>
                                <div className="form-group">
                                    {/* <label className="text-secondary font-15px"><small>Filter by Team Member</small> {loading ? <Spinner size="sm" className="m-auto text-center" color="secondary" /> : ""}</label> */}
                                    {/* <label className="text-secondary">Team Member</label> */}
                                    <select
                                        name="teamMember"
                                        className="form-control"
                                        onChange={(e) =>
                                            setQuery({...query, team_member: e.target.value})
                                        }
                                        type="select"
                                    >
                                        {renderMembersOptions(filterData?.team_members)}
                                    </select>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={2}>
                        <Row className="mb-3">
                            <Col lg={12}>
                                <div className="form-group">
                                    {/* <label className="text-label">Ratings</label> */}
                                    <select
                                        name="ratings"
                                        className="form-control"
                                        onChange={(e) =>
                                            setQuery({...query, rating: e.target.value})
                                        }
                                    >
                                        <option value="">Rating</option>
                                        <option value={1}>1</option>
                                        <option value={2}>2</option>
                                        <option value={3}>3</option>
                                        <option value={4}>4</option>
                                        <option value={5}>5</option>
                                    </select>
                                </div>
                            </Col>
                            <Col lg={12}>
                                {/* <label className="text-secondary">Clear Filters</label> */}
                                <button
                                    onClick={() => clearFilters()}
                                    //  disabled={loading}
                                    type="button"
                                    className="btn btn-secondary btn-block"
                                >
                                    {/* {loading ? <Spinner size="sm" className="m-auto text-center" color="secondary" /> : "Clear"} */}
                                    Clear Filters
                                </button>
                            </Col>
                            {/* <Col>
              <label className="text-secondary">Filter</label>
              <button disabled={loading} type="submit" className="btn btn-success btn-block">
                {loading ? <Spinner size="sm" className="m-auto text-center" color="light" /> : "Filter"}
              </button>
            </Col> */}
                        </Row>
                    </Col>
                </Row>
            </form>
        </div>
    );
};

export default EvaluationsFilters;
