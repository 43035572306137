
const renderIndustries = industries => {
  let indOptions = []
  if (industries && industries.length > 0) {
    industries.map(ind => {
      indOptions.push(
        {value: ind?.name, label: ind?.name}
      )
    })
  }
  return indOptions
}
// const renderIndustries = industries => {
//   let indOptions = []
//   indOptions.push(<option key={'initial'} value={""} >Select industry</option>)
//   if (industries && industries.length > 0) {
//     industries.map(ind => {
//       indOptions.push(
//         <option key={ind?.name} value={ind?.name}>
//           {ind?.name}
//         </option>
//       )
//     })
//   }
//   return indOptions
// }

const renderCountries = countries => {
  let cntOptions = []
  cntOptions.push(<option key={'initial'} value={""} disabled>Select country</option>)
  if (countries && countries.length > 0) {
    countries.map(country => {
      cntOptions.push(
        <option key={country?.name} value={country?.code}>
          {country?.name}
        </option>
      )
    })
  }
  return cntOptions
}

export {renderIndustries, renderCountries}