import React, {useEffect} from "react";
import {Card, CardBody, Col, Media, Row, Spinner} from "reactstrap";
import {Link} from "react-router-dom";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import avatar1 from "./../../../images/default.svg";

const TodoList = ({evaluation, loading}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        // dispatch(listEvaluations("?status=todo"))
    }, []);

    const renderTodoList = (evaluations) => {
        if (evaluations && evaluations.length > 0) {
            return evaluations?.map((ev, key) => {
                return (
                    // <li key={`${ev.id}-${key}`} className="mt-1">
                    //   <Link to={`/evaluation/${ev.id}`}>
                    //     <Media >
                    //       <div className="align-self-center  mr-3">
                    //       <img src={ev?.Candidate?.image || avatar1} width={50} alt=""
                    //         className="img-thumbnail rounded-circle"/>
                    //       </div>

                    //       <Media className="overflow-hidden" body>
                    //         <h5 className="text-truncate font-size-14 mb-1">
                    //           {ev?.Candidate?.first_name+' '+ev?.Candidate?.last_name}
                    //         </h5>
                    //         <p className="text-truncate mb-0">{ev?.Position?.title}</p>
                    //       </Media>
                    //       <div className="font-size-11">
                    //         {moment().format("MMM DD, YYYY")}
                    //       </div>
                    //     </Media>
                    //   </Link>
                    // </li>
                    <li>
                        <div className="timeline-panel">
                            <div className="media mr-2">
                                <img alt="" width="50" src={ev?.Candidate?.image || avatar1}/>
                            </div>
                            <div className="media-body">
                                <Link to={`/evaluation/${ev?.id}`}>
                                    <h5 className="mb-1">
                                        {ev?.Candidate?.first_name + " " + ev?.Candidate?.last_name}
                                    </h5>
                                    <small className="d-block">
                                        {ev?.Position?.title} - {moment().format("MMM DD, YYYY")}
                                    </small>
                                </Link>
                            </div>
                        </div>
                    </li>
                );
            });
        } else {
            return <div className="text-center">No Evaluations Found</div>;
        }
    };

    return (
        <>
            {/* <Row>
        <Col md={6}>
          <h5>To Do</h5>
        </Col>
      </Row> */}
            <div className="widget-media">
                <div className="card">
                    <div className="card-header">
                        <h5 className="card-title">
                            Pending Evaluations
                        </h5>
                    </div>
                    <div className="card-body">
                        <ul className="timeline">
                            <div className="custom-scroll" style={{maxHeight: "200px"}}>
                                {loading ? (
                                    <div className="text-center">
                                        <Spinner
                                            size="sm"
                                            className="m-auto text-center"
                                            color="success"
                                        />
                                    </div>
                                ) : (
                                    renderTodoList(evaluation?.todo)
                                )}
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TodoList;
