import React from "react"
import { ReactComponent as GoogleIcon } from "../../../images/svg/google-icon.svg"
import { useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import {
  getUserProfileAction,
  loginConfirmedAction,
  getUserPermissionsAction,
} from "../../../store/actions/AuthActions"
import { getCompanyAction, getCompanySubscriptionAction } from "../../../store/actions/CompanyActions"

const GoogleButton = () => {
  let { token } = useParams()
  const dispatch = useDispatch()
  console.log("token", useParams())
  if (token) {
    localStorage.setItem(
      "userDetails",
      JSON.stringify({
        company_profile: true,
        verified: true,
        token: token,
        expireDate: new Date(Date.now() + 3600 * 1000 * 24).toISOString(),
      })
    )
    window.location.href = "/home"
    return null
  }
  const handleSubmit = () => {
    window.location.href = `https://digitalhire.iqsoft.io/employer/google`
  }
  return (
    <div className="d-flex align-items-center mb-2">
      <button className="btn btn-block btn-danger font-bold" onClick={handleSubmit}>
        <GoogleIcon width="20" height="20" />
        &nbsp; Continue with Google
      </button>
    </div>
  )
}
export default GoogleButton
