import React from 'react'
import { Card, CardBody, Col, Media, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import moment from "moment"
import avatar from "../../../images/default.svg"
import PhoneNumber from '../Common/phoneNumber'

const ProfileModal = ({ member, toggle }) => {
  return (
    <Card className="overflow-hidden">
      <CardBody>
        <button className="float-right btn btn-link-secondary" onClick={() => toggle()}>close</button>
        <Row className="align-items-center mb-4">
          <Col sm="4">
            <div className="avatar-xxl mt-4">
              <img
                src={member?.AddedMemberUser?.image || avatar}
                alt=""
                className="img-thumbnail rounded-circle"
              />
            </div>
          </Col>
          <Col sm="8">
            <div className="mt-4">
              <h5>{member?.AddedMemberUser?.first_name} {member?.AddedMemberUser?.last_name}</h5>
              <span className="d-block mb-1 text-truncate"> <i className="dripicons-mail d-inline-block mr-1"></i>{member?.AddedMemberUser?.email}</span>
              <span className="d-block mb-1"> <i className="dripicons-phone d-inline-block mr-1"></i> <PhoneNumber number={member?.AddedMemberUser?.phone} /></span>
              <span className="text-muted">Joined on {moment(member?.AddedMemberUser?.createdAt).format('MMM DD, YYYY')}</span>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default ProfileModal
