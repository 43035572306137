import React,{ useState, useEffect} from 'react';
import { Col, Row, Card,  Tab, Nav  } from "react-bootstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import PageTitle from "../../layouts/PageTitle";
import Alert from "./../Common/Alert"
import PositionDetail from "./common/PositionDetail";
import AddPositionQuestions from "./common/AddPositionQuestions";
import AddPositionReview from "./common/AddPositionReview";
import "./positions.css"
import {
  getCompanySubscriptionAction
} from "../../../store/actions/CompanyActions"
import { getMeiaAddedMessage } from "../../../store/selectors/CompanySelector"


const PositionCreate = (props) => {
  const { getMessage } = props;
  const [active, setActive] = useState('Position');
  const [alert, showAlert] = useState(false)
  const [disableTabs, setdisableTabs] = useState({'Questions': true, 'Review': true});

  useEffect(() => {
    setActive('Position');
    setdisableTabs({'Questions': true, 'Review': true})
    props.getCompanySubscription();
  }, []);
  
  const tabData = [
    {
      name: 'Position',
      icon: <i className="bx bx-file bx-md mt-2 " />,
      content:<PositionDetail  activeTab={active==='Position'} nextTab={() => { setActive('Questions'); setdisableTabs({Questions: false, Review: true}); }} modeOn={'add'} showAlert={()=> showAlert(true)}/>,
      disable:(!disableTabs?.Review || !disableTabs?.Questions)? true : false
    },
    {
      name: 'Questions',
      icon: <i className="bx bx-chat bx-md mt-2 " />,
      content: <AddPositionQuestions activeTab={active==='Questions'} nextTab={() => { setActive('Review'); setdisableTabs({Questions: false, Review: false}); }}  modeOn={'add'} showAlert={()=> showAlert(true)}/>,
      disable:disableTabs?.Questions
    },
    {
      name: 'Review',
      icon: <i className="bx bx-badge-check bx-md mt-2 " />,
      content:<AddPositionReview activeTab={active==='Review'} edit={false}  modeOn={'add'} showAlert={()=> showAlert(true)}/>,
      disable:disableTabs?.Review
    },
  ];
  console.log(alert);
	return(
		<>
			{/*<PageTitle activeMenu="Add Positions" motherMenu="Dashboard" />*/}
      
      <Alert alert={alert} type={(getMessage?.success || getMessage?.delete) ? "success" : getMessage?.error && "error"}
             variant={(getMessage?.success || getMessage?.delete || getMessage?.updated) ? "success" : getMessage?.error && "danger"}
             msg={getMessage?.success || getMessage?.delete || getMessage?.error || getMessage?.updated}
             dismissAlert={() => showAlert(false)} /> 
      <Col xl={12} lg={12} md={12} sm={12}>
        <Row>
          <Tab.Container id="controlled-tab-example" defaultActiveKey={active} onSelect={(e)=> setActive(e)}>
            <Col xl={2}>
              <Nav as="ul" className="flex-column nav-pills mb-1">
                {tabData.map((data, i) => (
                  <Nav.Item as="li" key={i}>
                    <Card>
                      <Nav.Link eventKey={data.name} active={data.name === active} disabled={data.disable}>
                        <Col className='text-center'>
                          {data.icon}
                          <p>{data.name}</p>
                        </Col>
                      </Nav.Link>
                    </Card>
                  </Nav.Item>
                ))}
              </Nav>
            </Col>
            <Col xl={10}>
              <Tab.Content className="ml-2">
                {tabData.map((data, i) => (
                  <Tab.Pane eventKey={data.name} key={i} active={data.name === active} disabled={data.disable}>
                      {data.content} 
                  </Tab.Pane>
                ))}
              </Tab.Content>{" "}
            </Col>
          </Tab.Container>
        </Row>
      </Col>
		</>
	)
}

const mapStateToProps = (state) => {
  return {
    getMessage: getMeiaAddedMessage(state)
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getCompanySubscription: () => dispatch(getCompanySubscriptionAction())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PositionCreate))