export const GET_COMPANY_REPORT_COMPLETED = "[get company report action] get company report completed"
export const GET_COMPANY_REPORT_FAILED = "[get company report action] get company report failed"
export const GET_COMPANY_REPORT_REQUEST = "[get company report request] get company report request"

export const GET_COMPANY_REPORT_MEMBERS_COMPLETED =
  "[get company report members action] get company report members completed"
export const GET_COMPANY_REPORT_MEMBERS_FAILED = "[get company report members action] get company report members failed"
export const GET_COMPANY_REPORT_MEMBERS_REQUEST =
  "[get company report members request] get company report members request"

export const GET_COMPANY_REPORT_POSITIONS_COMPLETED =
  "[get company report positions action] get company report positions completed"
export const GET_COMPANY_REPORT_POSITIONS_FAILED =
  "[get company report positions action] get company report positions failed"
export const GET_COMPANY_REPORT_POSITIONS_REQUEST =
  "[get company report positions request] get company report positions request"

export const GET_COMPANY_REPORT_POSITION_COMPLETED =
  "[get company report position action] get company report position completed"
export const GET_COMPANY_REPORT_POSITION_FAILED =
  "[get company report position action] get company report position failed"
export const GET_COMPANY_REPORT_POSITION_REQUEST =
  "[get company report position request] get company report position request"
