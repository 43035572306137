import React from "react"
import ReactApexChart from "react-apexcharts"

import clock from "../../../../images/svg/clock.svg"

const MonthlyUsageGauge = (props) => {
    console.log("D", props?.data)
    const state = {


        series: [props?.data?.length > 0 ? Math.round((props?.data[0]?.avg_res / props?.data[0]?.q_time_allowed * 100)) : 0],
        // series: [props?.data?.length > 0 ? props?.data[0]?.avg_res : 10],
        options: {
            chart: {
                // height: 250,
                type: 'radialBar',
            },
            legend: {
                position: "top",
                show: true,
            },
            plotOptions: {
                radialBar: {
                    hollow: {
                        // margin: 100,
                        size: '90%',
                    },
                    track: {
                        margin: -25,
                        strokeWidth: '100%',

                    },
                    dataLabels: {
                        name: {
                            show: false,
                            color: '#fff'
                        },
                        value: {
                            margin: 5,
                            show: true,
                            color: '#333',
                            offsetY: 120,
                            fontSize: '18px',
                            formatter: function (val) {
                                return `${props?.data?.[0]?.avg_res}/${props?.data?.[0]?.q_time_allowed}`
                            },
                        }
                    }
                }
            },
            // stroke: {
            //   lineCap: 'round'
            // },
            labels: ['Minutes'],
            colors: ["#039577"]
        },
    };
    return (
        <div id="chart" className="apex-chart">
            <ReactApexChart options={state.options} series={state.series} type="radialBar" height={270}/>
        </div>
    )
}

//
// class MonthlyUsageGauge extends React.Component {
//     constructor(props) {
//         super(props)
//         const {evaluated_data} = props
//         console.log("Interview Data:", props?.data)
//
//         // this.state = {
//         //   series: [props?.data?.length > 0 ? props?.data[0]?.avg_res : 0],
//         //   options: {
//         //     chart: {
//         //       type: "radialBar",
//         //       offsetY: -20,
//         //       sparkline: {
//         //         enabled: true
//         //       }
//         //     },
//         //     plotOptions: {
//         //       radialBar: {
//         //         track: {
//         //           background: "#e7e7e7",
//         //           strokeWidth: "97%",
//         //           margin: 5 // margin is in pixels
//         //         },
//         //         dataLabels: {
//         //           name: {
//         //             offsetY: 120
//         //           },
//         //           value: {
//         //             offsetY: -5,
//         //             fontSize: "22px",
//         //             formatter: function(val) {
//         //               return parseInt(val) + " / 100"
//         //             },
//         //             show: true
//         //           }
//         //         }
//         //       }
//         //     },
//         //     labels: ["Average Interview Time"],
//         //     colors: ["#00ba52", "#f79e02", "#ECECEC"]
//         //   }
//         // }
//
//         this.state = {
//
//             series: [props?.data?.length > 0 ? Math.round((props?.data[0]?.avg_res * 100)) / 100 : 0],
//             // series: [props?.data?.length > 0 ? props?.data[0]?.avg_res : 10],
//             options: {
//                 chart: {
//                     // height: 250,
//                     type: 'radialBar',
//                 },
//                 legend: {
//                     position: "top",
//                     show: true,
//                 },
//                 plotOptions: {
//                     radialBar: {
//                         hollow: {
//                             margin: 15,
//                             size: '70%',
//                             image: clock,
//                             imageWidth: 64,
//                             imageHeight: 64,
//                             imageClipped: false
//                         },
//                         dataLabels: {
//                             name: {
//                                 show: false,
//                                 color: '#fff'
//                             },
//                             value: {
//                                 show: true,
//                                 color: '#333',
//                                 offsetY: 100,
//                                 fontSize: '18px',
//                                 formatter: function (val) {
//                                     return parseInt(val) + " mins"
//                                 },
//                             }
//                         }
//                     }
//                 },
//                 // stroke: {
//                 //   lineCap: 'round'
//                 // },
//                 labels: ['Minutes'],
//                 colors: ["#039577"]
//             },
//         };
//     }
//
//     // }
//
//     render() {
//         return (
//             <div id="chart" className="apex-chart">
//                 <ReactApexChart options={this.state.options} series={this.state.series} type="radialBar" height={350}/>
//             </div>
//         )
//     }
// }

export default MonthlyUsageGauge
