import axiosInstance from "../services/AxiosInstance"
import axios from "axios"

const CancelToken = axios.CancelToken
const source = CancelToken.source()
const controller = new AbortController()

export function registerCompanyService(data) {
    //axios call
    const postData = {
        ...data,
    }
    return axiosInstance.post(`/company/`, postData)
}

export function getCompanyService() {
    return axiosInstance.get(`/company/`)
}

export function updateCompanyService(data) {
    const postData = {
        ...data,
    }
    return axiosInstance.put(`/company/`, postData)
}

export function getCompanyCountriesService() {
    return axiosInstance.get(`/company/countries`)
}

export function getCompanyIndustriesService() {
    return axiosInstance.get(`/company/industries`)
}

export function addCompanyMemberService(data) {
    /*
    *
    data: {
      user_data: {
          "first_name": "",
          "last_name": "",
          "phone": "",
          "email": "",
          "role": ""
                  }
           }
    *
    */
    const postData = {
        ...data,
    }
    return axiosInstance.post(`/company/member`, postData)
}

export function editCompanyMemberStatusService(data) {
    /*
    *
    data: {
          "company_member_id": ,
          "mem_id": ,
          "status": , // true - false
          }

    *
    */
    const postData = {
        ...data,
    }
    return axiosInstance.put(`/company/member/status`, postData)
}

export function editCompanyMemberRoleService(data) {
    /*
    *
    data: {
      "company_member_id": 2,
      "role_name": "recruiter", // => "recruiter"/"manager"
      "type" : 0  // => true/false
    *
    */
    const postData = {
        ...data,
    }
    return axiosInstance.put(`/company/member/role`, postData)
}

export function deleteCompanyMemberService(data) {
    /*
    *
    data: {
      "company_member_id": 2
      * }
    *
    */
    const postData = {
        ...data,
    }
    return axiosInstance.delete(`/company/member`, {data: postData})
}

export function addCompanyMediaService(data) {
    /*
    *
    "media_data":{
          "url": "Media url",
          "thumbnail": "Media thumbnail",
          "name": "Media name",
          "type": "video"
      }
    */
    const postData = {
        ...data,
    }
    return axiosInstance.post(`/media/`, postData, {cancelToken: source.token, signal: controller.signal})
}

export function getCompanyMediaService(data) {
    /*
     *
     * data = {
     *
     *  "filter_options": {
     *  "search_string": "video",
     *  "sort_array": [["id", "DESC"], ["createdAt", "ASC"]]
     * }
     *
     * }
     *
     */
    const postData = {
        ...data,
    }
    return axiosInstance.post(`/media/all`, postData)
}

export function deleteCompanyMediaService(data) {
    /*
     *
     * data = {
     *
     *   "media_id": 1
     *
     * }
     *
     */
    const postData = {
        ...data,
    }
    return axiosInstance.delete(
        `/media/`,

        {data: postData}
    )
}

/** Position */

export function getCompanyPositionService(data) {
    /*
    *
     {
      "position_id": 2
     }
    */
    // const postData = {
    //   ...data
    // }
    return axiosInstance.get(`/company/position/${data.position_id}`)
}

export function getCompanyPositionsService(data) {
    /*
    * {
      "filter_options": {
          "search_string": "ec",
          "sort_array": [["id", "DESC"], ["createdAt", "ASC"]]
      },
      "pagination": {
          "page": 1,
          "size": 3
      }
  }
    *
    * */
    const postData = {
        ...data,
    }
    return axiosInstance.post(`/company/positions`, postData)
}

export function addCompanyPositionService(data) {
    /*
    *
    "position_data":{
          "title": "Test Position 1",
          "status": "open",
          "intro_message": "Hellow",
          "outro_message": "Hellow 2",
          "outro_video": "Hellow.mp4",
          "intro_video": "Hellow,mp4",
          "description": "This is first test Position",
          "location": "Test location",
          "closing_date": "2021-12-12",
          "expire_date": "2021-12-12"
      }
    */
    const postData = {
        ...data,
    }
    return axiosInstance.post(`/company/position`, postData)
}

export function companyPositionDuplicationService(data) {
    /*
    *
    "data":{
          position_id: 1,
              }
    */
    const postData = {
        ...data,
    }
    return axiosInstance.post(`/company/position/duplicate`, postData)
}

export function deleteCompanyPositionService(data) {
    /*
    * {
        "position_id": 1
    * }
    * */
    const postData = {
        ...data,
    }
    return axiosInstance.delete("/company/position", {
        data: postData,
    })
}

export function updateCompanyPositionService(data) {
    /*
    * {
         "position_id": 1,
        "position_data":{
          "title": "Test Position 2",
          "status": "open",
          "description": "This is first test Position",
          "location": "Test location",
          "closing_date": "2021-12-12",
          "expire_date": "2021-12-12"
      }
    }
    * */
    const postData = {
        ...data,
    }
    return axiosInstance.put("/company/position", postData)
}

export function addCompanyPositionReminderService(data) {
    /*
    *
     {
      "position_id": 2
     }
    */
    const postData = {
        ...data,
    }
    return axiosInstance.post(`/company/position/reminder`, postData)
}

export function removeCompanyPositionReminderService(data) {
    /*
    *
     {
      "position_id": 2
     }
    */
    const postData = {
        ...data,
    }
    return axiosInstance.delete(`/company/position/reminder`, {
        data: postData,
    })
}

/** Company Positions Team Members*/

export function addCompanyPositionTeamMemberService(data) {
    /*
    *
    {
      "position_id": 2,
      "company_member_id": 6
  }
    */
    const postData = {
        ...data,
    }
    return axiosInstance.post(`/company/position/member`, postData)
}

export function removeCompanyPositionTeamMemberService(data) {
    /*
    *
    {
      "position_id": 2,
      "company_member_id": 6
  }
    */
    const postData = {
        ...data,
    }
    return axiosInstance.delete(`/company/position/member`, {
        data: postData,
    })
}

/** Position Questions */
export function getCompanyPositionQuestionsService(data) {
    /*
    *
     {
      "position_id": 2
     }
    */

    return axiosInstance.get(`/question/position/${data.position_id}`)
}

export function addCompanyPositionQuestionService(data) {
    /*
    *
     {
      "position_id": 2,
      "question_data": {
          "title": "Test Question 1",
          "description": "Test Question 1 description",
          "type": "short-answer",
          "time_allowed": 30,
          "attempts_allowed": 2
      }
     }
    */
    const postData = {
        ...data,
    }
    return axiosInstance.post(`/question`, postData)
}

export function updateCompanyPositionQuestionService(data) {
    /*
    *
     {
      "position_id": 2,
      "question_data": {
          "id": 1,
          "title": "Test 2 Question 1",
          "description": "Test 2 Question 1 description",
          "type": "short-answer",
          "time_allowed": 20,
          "attempts_allowed": 2
      }
     }
    */
    const postData = {
        ...data,
    }
    return axiosInstance.put(`/question`, postData)
}

export function updateCompanyPositionQuestionSeqService(data) {
    /*
    *
     {
      "position_id": 2,
       "question_data": {
          "id": 1,
          "seq": 2
      }
     }
    */
    const postData = {
        ...data,
    }
    return axiosInstance.put(`/question/sequence`, postData)
}

export function deleteCompanyPositionQuestionService(data) {
    /*
    *
     {
       "position_id": 2,
       "question_id": 1
     }
    */
    const postData = {
        ...data,
    }
    return axiosInstance.delete(`/question`, {
        data: postData,
    })
}

// Candidates
export function getCompanyCandidatesService(data) {
    // TODO:: Filter fields
    /**
     *
     * {
    "filter_options": {
        "name": "",
        "position": "",
        "status": "",
        "tag": "",
        "response": "",
        "rating": "",
        "team_member": "",
        "complete_date": "",
        "added_date": "",
        "sort_array": [["id", "DESC"], ["createdAt", "ASC"]]
    },
    "pagination": {
        "page": 1,
        "size": 3
    }
}
     *
     * */
    const postData = {
        ...data,
    }
    return axiosInstance.post("/interview/company", postData)
}

export function getCompanyCandidateFilterDataService(data) {
    // TODO:: Filter data
    return axiosInstance.post("/interview/filter", data)
}

export function getCompanyCandidatesEvaluationsService(data) {
    /**
     *
     * {
            "filter_options": {
                "name": "",
                "position": "",
                "tag": "",
                "team_member": "",
                "complete_date": "",
                "sort_array": [["id", "DESC"], ["createdAt", "ASC"]]
            },
            "pagination": {
                "page": 1,
                "size": 3
            },
            "type": "type" => "todo", "evaluated", "qualified", "favorite"
        }
     *
     * */
    const postData = {
        ...data,
    }
    return axiosInstance.post("/interview/evaluations", postData)
}

export function getCompanyCandidateEvaluationDetailService(data) {
    /**
     *
     * {
     * "interview_id": 4
     * }
     *
     * */
    // const postData = {
    //     ...data
    // }
    return axiosInstance.get(`/interview/evaluate/${data.interview_id}`)
}

export function markCompanyCandidateEvaluationService(data) {
    /**
     *
     * {
    "interview_id": 4,
    "interview_data": {
        "my_response": "yes",
        favorite: 1,
        ratings: 1,
    }
}
     *
     * */
    const postData = {
        ...data,
    }
    // console.log("IN SERVICE")
    return axiosInstance.post(`/interview/evaluate-mark/`, postData)
}

export function deleteCompanyCandidateService(data) {
    /**
     *
     * {
     *      "interview_id": 1
     * }
     *
     * */

    const postData = {
        ...data,
    }
    return axiosInstance.delete("/interview/", {
        data: postData,
    })
}

export function resetCompanyCandidateService(data) {
    /**
     *
     * {
     *      "interview_id": 1
     * }
     *
     * */

    const postData = {
        ...data,
    }
    return axiosInstance.post("/interview/reset", postData)
}

export function sendCompanyCandidateReminderService(data) {
    /**
     *
     * {
     *      "interview_id": 1
     * }
     *
     * */

    const postData = {
        ...data,
    }
    return axiosInstance.post("/interview/send/reminder", postData)
}

export function addCompanyCandidateTeamMemberService(data) {
    /**
     *
     * {
        "interview_id": 2,
        "company_member_id": 6
        }
     *
     * */

    const postData = {
        ...data,
    }
    return axiosInstance.post("/interview/member", postData)
}

export function removeCompanyCandidateTeamMemberService(data) {
    /**
     *
     * {
        "interview_id": 2,
        "company_member_id": 6
        }
     *
     * */

    const postData = {
        ...data,
    }
    return axiosInstance.delete("/interview/member", {
        data: postData,
    })
}

export function companyInviteCandidateService(data) {
    /**
     *
     {
    "user_data": {
        "first_name": "Ahsan",
        "last_name": "Iqbal",
        "phone": "01232967893",
        "email": "abc312@abc.com"
    },
    "position_url": "78891d93-cfff-472a-8daa-a0a484711348"
}
     *
     * */

    const postData = {
        ...data,
    }
    return axiosInstance.post("/company/position/invite", postData)
}

// Subscription
export function getCompanySubscriptionService(data) {
    return axiosInstance.get("/company/subscription")
}

/** Tags */
export function getCompanyTagsService(data) {
    /**
     * {
    "tag_data":{
    "name": "Test Tag 1",
    "color": "Blue"
    }
}
     * */

    const postData = {
        ...data,
    }
    return axiosInstance.get("/tag/all")
}

export function addCompanyTagService(data) {
    /**
     * {
    "tag_data":{
    "name": "Test Tag 1",
    "color": "Blue"
    }
}
     * */

    const postData = {
        ...data,
    }
    return axiosInstance.post("/tag/", postData)
}

export function deleteCompanyTagService(data) {
    /**
     * {
     "tag_id": 2
}
     * */

    const postData = {
        ...data,
    }
    return axiosInstance.delete("/tag/", {data: postData})
}

export function addCompanyInterviewTagService(data) {
    /**
     *
     * {
    "tag_id": 1,
    "interview_id": 4
}
     * */

    const postData = {
        ...data,
    }
    return axiosInstance.post("/tag/interview", postData)
}

export function removeCompanyInterviewTagService(data) {
    /**
     *
     * {
    "tag_id": 1,
    "interview_id": 4
}
     * */

    const postData = {
        ...data,
    }
    return axiosInstance.delete("/tag/interview", {data: postData})
}

export function addCompanyCandidateNoteService(data) {
    /**
     *
     * {
    "interview_id": 4,
    "note": ""

}
     * */

    const postData = {
        ...data,
    }
    return axiosInstance.post("/interview/note", postData)
}

export function editCompanyCandidateNoteService(data) {
    /**
     *
     * {
    "interview_id": 4,
    "note": "",
    "note_id": 2

}
     * */

    const postData = {
        ...data,
    }
    return axiosInstance.put("/interview/note", postData)
}

export function markInterviewHireService(data) {
    /**
     *
     * {
    "interview_id": 4,
    "hired": ""

}
     * */

    const postData = {
        ...data,
    }
    return axiosInstance.post("/interview/hire", postData)
}

export function deleteCompanyCandidateNoteService(data) {
    /**
     *
     * {
    "interview_id": 4,
    "note_id": 2

}
     * */

    const postData = {
        ...data,
    }
    return axiosInstance.delete("/interview/note", {data: postData})
}

export function addCandidatePdfResumeService(data) {
    /**
     *
     * {
    "interviewId": 4,
    "pdfResumeLink": 2
}
     * */

    const postData = {
        ...data,
    }
    return axiosInstance.post("/interview/pdf-resume", postData)
}

export function getCompanyDashboardService(data) {
    return axiosInstance.get("/company/dashboard")
}

export function getCompanyPaymentsService(data) {
    return axiosInstance.post("/company/payments", data)
}

export function getCompanySubscriptionPlansService() {
    return axiosInstance.get("/company/subscription/plans")
}

export function postCompanySubscriptionCheckoutService(data) {
    return axiosInstance.post("/stripe/", data)
}

export function postCompanySubscriptionCheckoutConfirmationService(data) {
    return axiosInstance.post("/stripe/confirm", data)
}

export function getCompanyPositionsList(data) {
    return axiosInstance.post("/company/dashboard/positions", data)
}

export function getCompanyDashboardInterviewGraph(data = {}) {
    return axiosInstance.post("/company/dashboard/interview", data)
}

export function getCompanyDashboardEvaluationGraph(data = {}) {
    return axiosInstance.post("/company/dashboard/evaluated", data)
}

export function shareReviewInterviewService(data = {}) {
    return axiosInstance.post("/interview/share", data)
}

export function callCancel() {
    return function (dispatch) {
        source.cancel("Operation canceled by the user.")
        controller.abort()
    }
}
