import React, { useEffect, useState } from "react"
import {useLocation, withRouter} from "react-router-dom"
import { connect } from "react-redux"
import { loadStripe } from "@stripe/stripe-js"
import { Elements, useStripe } from "@stripe/react-stripe-js"

import CheckoutForm from "./CheckoutForm"
import { postCompanySubscriptionCheckoutService } from "../../../services/CompanyService"


const PUBLIC_KEY = "pk_test_yXSo3SGQaRroI5KSLY0JWRX300Y6G1PTEI"

const stripePromise = loadStripe(PUBLIC_KEY)


const Checkout = (props) => {
  const [clientSecret, setClientSecret] = useState("")
  const sub_id = String(props?.location?.pathname).split("/checkout/")[1]
  const search = useLocation().search;
  const type = new URLSearchParams(search).get('type');
  useEffect(() => {

    postCompanySubscriptionCheckoutService({ subscription_id: sub_id, type }).then((res) => {
      console.log(res)
      setClientSecret(res.data.clientSecret)
    })

  }, [])

  const appearance = {
    theme: "stripe"
  }
  const options = {
    clientSecret,
    appearance
  }

  return (
    <div className="App checkout">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    </div>
  )
}

export default withRouter(connect(null, null)(Checkout))
