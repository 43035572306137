import React from "react"

import ReactApexChart from "react-apexcharts"

const CandidatesBarGraph = ({data}) => {
    const yearMonths = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ]
    const series =
        data?.length > 0
            ? [
                {
                    name: "Invited",
                    data: [data[0]?.interview_invited],
                },
                {
                    name: "Completed",
                    data: [data[0]?.interview_completed],
                },
                {
                    name: "Qualified",
                    data: [data[0]?.interview_hired],
                },
                {
                    name: "Hired",
                    data: [data[0]?.interview_qualified],
                },
            ]
            : []
    const state = {
        series: series,
        options: {
            chart: {
                type: "bar",
                height: 230,
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: "40px",
                    dataLabels: {
                        position: "top",
                    },
                },
            },
            colors: [
                "#00ba52",
                "#009477",
                "#006b7e",
                "#004164",
                "#001c35"
            ],
            legend: {
                show: true,
                position: "top",
                horizontalAlign: "left",
            },
            dataLabels: {
                enabled: false,
                offsetX: -6,
                style: {
                    fontSize: "12px",
                    // colors: ["#fff"],
                },
            },
            stroke: {
                show: true,
                colors: ["transparent"],
                width: 5,
            },
            yaxis: {
                title: {
                    text: "",
                },
                lines: {
                    show: false,
                },
            },
            xaxis: {
                show: false,
                categories: [data?.length > 0 ? yearMonths[data[0]?.interview_date - 1] : "This Month"],
            },
        },
    }
    return (
        <>
            {/* <div className="d-flex justify-content-end w-100">
        <select name="position" className="form-control" type="select" onChange={(e) => setActiveTab(e.target.value)}>
          <option value="1">Junior UI/UX</option>
          <option value="2">Laravel Developer</option>
          <option value="3">SysOps</option>
        </select>
      </div> */}
            <div id="chart" className="apex-chart">
                <ReactApexChart options={state.options} series={state.series} type="bar" height={350}/>
            </div>
        </>
    )
}
export default CandidatesBarGraph
