import { formatError } from "../../services/FormatError"
import {
  GET_COMPANY_REPORT_FAILED,
  GET_COMPANY_REPORT_COMPLETED,
  GET_COMPANY_REPORT_REQUEST,
  GET_COMPANY_REPORT_MEMBERS_COMPLETED,
  GET_COMPANY_REPORT_MEMBERS_FAILED,
  GET_COMPANY_REPORT_MEMBERS_REQUEST,
  GET_COMPANY_REPORT_POSITIONS_COMPLETED,
  GET_COMPANY_REPORT_POSITIONS_FAILED,
  GET_COMPANY_REPORT_POSITIONS_REQUEST,
  GET_COMPANY_REPORT_POSITION_COMPLETED,
  GET_COMPANY_REPORT_POSITION_FAILED,
  GET_COMPANY_REPORT_POSITION_REQUEST,
} from "./constants/Report"
import {
  getCompanyReportService,
  getCompanyReportMembersService,
  getCompanyReportPositionsService,
  getCompanyReportPositionService,
} from "../../services/ReportService"

export function getCompanyReportAction(cb = null) {
  return (dispatch) => {
    dispatch(getCompanyReportRequestAction())
    getCompanyReportService()
      .then((response) => {
        const data = response.data.data
        dispatch(getCompanyReportCompletedAction(data))
        if (cb) cb()
      })
      .catch((error) => {
        let errorMessage
        if (error.response) {
          errorMessage = formatError(error.response.data)
        } else {
          errorMessage = error.message
        }
        dispatch(getCompanyReportFailedAction(errorMessage))
        if (cb) cb()
      })
  }
}

export function getCompanyReportMembersAction(cb = null) {
  return (dispatch) => {
    dispatch(getCompanyReportMembersRequestAction())
    getCompanyReportMembersService()
      .then((response) => {
        const data = response.data.data
        dispatch(getCompanyReportMembersCompletedAction(data))
        if (cb) cb()
      })
      .catch((error) => {
        let errorMessage
        if (error.response) {
          errorMessage = formatError(error.response.data)
        } else {
          errorMessage = error.message
        }
        dispatch(getCompanyReportMembersFailedAction(errorMessage))
        if (cb) cb()
      })
  }
}

export function getCompanyReportPositionsAction(data, cb = null) {
  return (dispatch) => {
    dispatch(getCompanyReportPositionsRequestAction())
    getCompanyReportPositionsService(data)
      .then((response) => {
        const data = response.data.Positions
        dispatch(getCompanyReportPositionsCompletedAction(data))
        !data && dispatch(getCompanyReportPositionAction({ position_id: data?.data[0]?.id }))
        if (cb) cb()
      })
      .catch((error) => {
        let errorMessage
        if (error.response) {
          errorMessage = formatError(error.response.data)
        } else {
          errorMessage = error.message
        }
        dispatch(getCompanyReportPositionsFailedAction(errorMessage))
        if (cb) cb()
      })
  }
}

export function getCompanyReportPositionAction(data, cb = null) {
  return (dispatch) => {
    dispatch(getCompanyReportPositionRequestAction())
    getCompanyReportPositionService(data)
      .then((response) => {
        const data = response.data.data
        dispatch(getCompanyReportPositionCompletedAction(data))
        if (cb) cb()
      })
      .catch((error) => {
        let errorMessage
        if (error.response) {
          errorMessage = formatError(error.response.data)
        } else {
          errorMessage = error.message
        }
        dispatch(getCompanyReportPositionFailedAction(errorMessage))
        if (cb) cb()
      })
  }
}

export function getCompanyReportRequestAction() {
  return {
    type: GET_COMPANY_REPORT_REQUEST,
  }
}
export function getCompanyReportCompletedAction(payload) {
  return {
    type: GET_COMPANY_REPORT_COMPLETED,
    payload,
  }
}

export function getCompanyReportFailedAction(payload) {
  return {
    type: GET_COMPANY_REPORT_FAILED,
    payload,
  }
}

export function getCompanyReportMembersRequestAction() {
  return {
    type: GET_COMPANY_REPORT_MEMBERS_REQUEST,
  }
}
export function getCompanyReportMembersCompletedAction(payload) {
  return {
    type: GET_COMPANY_REPORT_MEMBERS_COMPLETED,
    payload,
  }
}

export function getCompanyReportMembersFailedAction(payload) {
  return {
    type: GET_COMPANY_REPORT_MEMBERS_FAILED,
    payload,
  }
}

export function getCompanyReportPositionsRequestAction() {
  return {
    type: GET_COMPANY_REPORT_POSITIONS_REQUEST,
  }
}
export function getCompanyReportPositionsCompletedAction(payload) {
  return {
    type: GET_COMPANY_REPORT_POSITIONS_COMPLETED,
    payload,
  }
}

export function getCompanyReportPositionsFailedAction(payload) {
  return {
    type: GET_COMPANY_REPORT_POSITIONS_FAILED,
    payload,
  }
}

export function getCompanyReportPositionRequestAction() {
  return {
    type: GET_COMPANY_REPORT_POSITION_REQUEST,
  }
}
export function getCompanyReportPositionCompletedAction(payload) {
  return {
    type: GET_COMPANY_REPORT_POSITION_COMPLETED,
    payload,
  }
}

export function getCompanyReportPositionFailedAction(payload) {
  return {
    type: GET_COMPANY_REPORT_POSITION_FAILED,
    payload,
  }
}
