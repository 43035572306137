const renderResponse = (resp) => {
    if (resp === 'yes') return 'Yes'
    if (resp === 'no') return 'No'
    if (resp === 'maybe') return 'Maybe'
    return resp
}

const renderPlanName = (plan) => {
    if (plan === 'proplus') return 'Pro Plus'
    if (plan === 'trial') return 'Trial'
    if (plan === 'pro') return 'Pro'
    if (plan === 'premium') return 'Premium'
    return plan
}

const renderDevice = (device) => {
    if (device) return device.charAt(0).toUpperCase() + device.slice(1);
    else return 'Not found'
}

const renderStatus = (status) => {
    if (status === 'completed') return 'Completed'
    if (status === 'reviewed') return 'Evaluated'
    if (status === 'startedInterview' || status === 'started_interview') return 'Started Interview'
    if (status === 'invited') return 'Invited'
    if (status === 'clickedInvite' || status === 'clicked_invite') return 'Clicked Invite'
    if (status === 'hired') return 'Hired'
    return status
}

module.exports = {
    renderDevice,
    renderPlanName,
    renderResponse,
    renderStatus
}