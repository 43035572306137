import React from "react"
import { Card, CardBody, CardFooter, Col, Row } from "reactstrap"

const DeletePrompt = ({
  title,
  message,
  toggle,
  func,
  btnText = "Delete",
  btnClass = "btn-danger",
}) => {
  return (
    <div className="p-3">
      <Row>
        <Col lg={12} className="text-center mt-2">
          <h4>{title}</h4>
          <h6 className="mt-3">{message}</h6>
        </Col>
      </Row>
      <Row className="justify-content-center mb-2">
        <Col className="text-center" lg={6}>
          <button
            type="button"
            onClick={() => toggle()}
            className="btn btn-outline-secondary mr-2"
          >
            Cancel
            </button>
          <button
            type="button"
            onClick={() => func()}
            className={`btn ${btnClass}`}
          >
            {btnText}
          </button>
        </Col>
      </Row>
    </div>
  )
}

export default DeletePrompt
