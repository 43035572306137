import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, Col, Row } from 'reactstrap'
import avatar from "../../../../images/default.svg"
import moment from 'moment'



const UserPositionDetails = ({ position, company }) => {

  const getQuestionsTime = (questions) => {
    let time = 0
    if(questions && questions.length > 0) {
      questions.forEach(ques => {
        time += ques?.time_allowed || 0
      })
    }
    return time
  }
  return (
    <Card style={{height:'auto'}}>
      <CardBody>
        <Row className="align-items-center justify-content-between">
          <Col lg={12}>
            <Row className="align-items-center">
                <div className={`circle ml-3`}>
                  <img src={company?.logo || avatar} alt="" className="img-thumbnail rounded-circle" />
                </div>
              <Col md="6">
                <div className="float-left">
                  <h5>{position?.title}</h5>
                  <h5 className="text-subtitle d-block"><small>Created By <b>{position?.CreatedBy?.first_name+' '+position?.CreatedBy?.last_name}</b></small></h5>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-4 d-none">
          <Col>
            <p className="text-subtitle">
              {position?.description}
            </p>
          </Col>
        </Row>
        <hr />
        <Row className="mt-1">
          <Col md={12}>
            <Row className="align-items-start">
              <Col className="align-items-start">
                <div className="d-inline-block">
                  <h6>
                    <i className="fa fa-map-marker mr-2"></i>Location</h6>
                  <span className="d-block text-subtitle ml-3">{position?.location}</span>
                </div>
              </Col>
              <Col className="align-items-start">
                <div className="d-inline-block">
                  <h6>
                    <i className='fa fa-calendar text-warning mr-2'></i>Closing Date</h6>
                  <span className="d-block text-subtitle ml-3">{position?.closing_date ? moment(position?.closing_date).format('MMM DD, YYYY'): ''}</span>
                </div>
              </Col>
            {/* </Row>
          </Col>
          <Col md={7}>
            <Row className="align-items-start"> */}
              <Col className="align-items-start">
                <div className="d-inline-block">
                  <h6>
                    <i className='fa fa-comment text-secondary mr-2' ></i>Total Questions</h6>
                  <span className="d-block text-subtitle ml-3">{position?.Questions ? position?.Questions.length : 0}</span>
                </div>
              </Col>
              <Col className="align-items-start">
                <div className="d-inline-block">
                  <h6>
                    <i className='fa fa-clock-o text-primary mr-2'></i>Total Time</h6>
                  <span className="d-block text-subtitle ml-3">{getQuestionsTime(position?.Questions)} Minutes</span>
                </div>
              </Col>
              <Col className="align-items-start">
                <div className="d-inline-block">
                  <h6>
                    <i className='fa fa-user text-info mr-2'></i>Created By</h6>
                  <span className="d-block text-subtitle ml-3">{position?.CreatedBy?.first_name+' '+position?.CreatedBy?.last_name}</span>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default UserPositionDetails