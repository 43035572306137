import axiosInstance from "../services/AxiosInstance"
import axios from "axios"
const CancelToken = axios.CancelToken
const source = CancelToken.source()
const controller = new AbortController()

export function generatePutSigningUrlService(data) {
  /*
   * {
   *   media_key: "key"
   * }
   * */
  const postData = {
    media_key: data.media_key,
  }
  return axiosInstance.post(`/media/put-signing-url`, postData)
}

export function generateGetSigningUrlService(data) {
  //axios call
  /*
   * {
   *   media_key: "key"
   * }
   * */
  const postData = {
    media_key: data.media_key,
  }
  return axiosInstance.post(`/media/get-signing-url`, postData)
}

export function uploadMediaToAWS(data) {
  //axios call
  /*
   * data = {
   *   url: "key",
   *   file: file // Image
   * }
   * */
  const { url, file } = data
  return axios.put(`${url}`, file, { cancelToken: source.token, signal: controller.signal })
}

export function callCancelMedia() {
  return function (dispatch) {
    source.cancel("Operation canceled by the user.")
    controller.abort()
    console.log("I'm here")
  }
}
