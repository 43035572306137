import React from "react"
import {Link} from "react-router-dom"
import {Row, Col, Card, Tab, Nav} from "react-bootstrap"
import "./style.css"

const EvaluationTabs = ({loading, tab, setTab}) => {
    return (
        <Tab.Container defaultActiveKey={tab.toLowerCase()} onSelect={(e) => setTab(e)}>
            <Nav as="ul" className="nav-pills mb-4">
                <Nav.Item as="li" className="bg-c-blue">
                    <Nav.Link eventKey={"todo"} className="bg-c-blue">
                        To Do
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                    <Nav.Link eventKey={"evaluated"} className="bg-o-peel">
                        Evaluated
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                    <Nav.Link eventKey={"qualified"} className="bg-b-bell">
                        Qualified
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                    <Nav.Link eventKey={"favorite"} className="bg-c-green">
                        Favorites
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                    <Nav.Link eventKey={"hired"} className="bg-c-green2">
                        Hired
                    </Nav.Link>
                </Nav.Item>
            </Nav>
        </Tab.Container>
    )
}

export default EvaluationTabs
