import React, { Fragment, useEffect, useState } from "react"
import { Card, Modal, Badge, Col, Form, Row } from "reactstrap"
import Dropzone from "react-dropzone"

const UploadBox = (props) => {
  return (
    <Col lg="12">
      <span className="mb-2 d-block">Maximum Video Size Limit: 34MB; Max Image Size Limit: 2MB</span>
      <Form>
        <Dropzone
          onDrop={(acceptedFiles) => props.handleAcceptedFiles(acceptedFiles)}
          accept={"image/png, image/gif, image/jpg, image/jpeg, video/mp4, video/mov"}
        >
          {({ getRootProps, getInputProps }) => (
            <div className="dropzone" style={{ cursor: "pointer" }}>
              <div className="dz-message needsclick" {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="dz-message needsclick">
                  <div className="mb-3 mt-4">
                    <i className="display-4  fa fa-cloud-upload" style={{ color: "#00BA52" }} />
                  </div>
                  <h4>Drop files here or click to upload.</h4>
                </div>
              </div>
            </div>
          )}
        </Dropzone>
        <div className="dropzone-previews mt-3" id="file-previews"></div>
      </Form>
    </Col>
  )
}

export default UploadBox
