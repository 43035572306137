import PropTypes from "prop-types"
import React, {useEffect, useState} from "react"
import {Container, Row, Col, Modal, Spinner, ModalBody} from "reactstrap"

import Alert from "./../Common/Alert"
import ProfileData from "./ProfileData"
import VideoResume from "./VideoResume"
import TodoList from "./TodoList"

import PageTitle from "../../layouts/PageTitle"

// import './evaluations.scss'
import Questions from "./Questions"
import EvaluationStats from "./EvaluationStats"
import ScoreCard from "./ScoreCard"
import EvaluationComments from "./EvaluationComments"
import ProfileDataModal from "./ProfileDataModal"
import EmailInterviewModal from "./EmailInterviewModal"
import {connect} from "react-redux"
import {
    getCompanyCandidateFilterDataSelector,
    getCompanyCandidates,
    getCompanySubscription,
    getCurrentEvaluationDetailSelector,
    getMeiaAddedMessage,
} from "../../../store/selectors/CompanySelector"
import {
    addCandidatePdfResumeAction,
    addCompanyCandidateNoteAction,
    deleteCompanyCandidateNoteAction,
    getCompanyCandidateEvaluationDetailAction,
    markCompanyCandidateEvaluationAction, markInterviewHireAction,
} from "../../../store/actions/CompanyActions";
import {withRouter} from "react-router-dom";
import avatar1 from "../../../images/default.svg";
import SweetAlert from "react-bootstrap-sweetalert";
import {uploadMediaToAWSAction} from "../../../store/actions/MediaActions";

const EvaluationDetails = (props) => {
    const {
        getCompanyEvaluationDetail,
        getCurrentEvaluation,
        deleteCompanyCandidateNote,
        getMessage,
        markInterviewHire,
        uploadMediaToAWS,
        addCandidatePdfResume
    } = props;
    const [profileModal, setProfileModal] = useState(false);
    const [noResume, setNoResume] = useState(false);
    const [emailInterview, setEmailInterview] = useState(false);
    const [evaluationDetails, setEvaluationDetails] = useState(undefined);
    const [alert, showAlert] = useState(false);
    const [editMode, setEditMode] = useState(undefined);
    const [loading, setloading] = useState(false);
    // console.log(getMessage);
    useEffect(() => {
        setEvaluationDetails(undefined)
        const id = String(props?.location?.pathname).split("/evaluation/")[1]
        if (id) {
            setloading(true)
            getCompanyEvaluationDetail({interview_id: id}, props, () => setloading(false))
        } else {
            props.history.push("/evaluation")
        }
    }, [props?.location?.pathname])

    useEffect(() => {
        // setEvaluationDetails(undefined)
        const id = String(props?.location?.pathname).split("/evaluation/")[1]
        if (id && getMessage?.showLoading) {
            getCompanyEvaluationDetail({interview_id: id}, props, () => setloading(false))
        }
    }, [getMessage?.showLoading])

    useEffect(() => {
        if (getCurrentEvaluation) {
            setEvaluationDetails(getCurrentEvaluation)
        } else {
            setEvaluationDetails(undefined)
        }
        // if(!loading && !getCurrentEvaluation) {
        //   props.history.push('/evaluation')
        // }
    }, [getCurrentEvaluation])

    return (
        <React.Fragment>
            {/*<PageTitle activeMenu="Detail" motherMenu="Evaluation" />*/}
            {getMessage?.updated !== "Mark Company Candidate Evaluation Completed" && (
                <Alert
                    alert={alert}
                    type={getMessage?.updated || getMessage?.delete ? "success" : getMessage?.error && "error"}
                    variant={getMessage?.updated || getMessage?.delete ? "success" : getMessage?.error && "danger"}
                    msg={getMessage?.updated || getMessage?.delete || getMessage?.error}
                    dismissAlert={() => showAlert(false)}
                />
            )}
            {loading ? (
                <Row className="align-items-center justify-content-center">
                    <Col lg={1}>
                        <Spinner size="sm" className="m-auto text-center" color="success"/>
                    </Col>
                </Row>
            ) : evaluationDetails ? (
                <Row>
                    <Col xl="3">
                        <ProfileData evaluation={evaluationDetails} setProfileModal={setProfileModal}
                                     setNoResume={setNoResume} uploadMediaToAWS={uploadMediaToAWS}
                                     addCandidatePdfResume={addCandidatePdfResume} showAlert={showAlert}/>
                        <VideoResume evaluation={evaluationDetails} setProfileModal={setProfileModal}
                                     loading={loading}/>
                        <TodoList evaluation={evaluationDetails} loading={loading}/>
                    </Col>
                    <Col xl="9">
                        <Row>
                            <Col xl="8">
                                <Questions setProfileModal={setProfileModal} loading={loading}
                                           evaluation={evaluationDetails}/>
                            </Col>
                            <Col xl="4">
                                <ScoreCard
                                    evaluation={evaluationDetails}
                                    setEmailInterview={setEmailInterview}
                                    markCompanyCandidateEvaluation={props.markCompanyCandidateEvaluation}
                                    addCompanyCandidateNote={props.addCompanyCandidateNote}
                                    markInterviewHire={markInterviewHire}
                                    showAlert={() => showAlert(true)}
                                />
                            </Col>
                        </Row>
                        <div>
                            <EvaluationStats evaluation={evaluationDetails}/>
                            <EvaluationComments
                                evaluation={evaluationDetails}
                                deleteNote={deleteCompanyCandidateNote}
                                showAlert={() => showAlert(true)}
                            />
                        </div>
                    </Col>
                </Row>
            ) : (
                ""
            )}
            <Modal
                centered={true}
                isOpen={profileModal}
                toggle={() => {
                    setProfileModal(!profileModal)
                }}
            >
                <ProfileDataModal
                    position={evaluationDetails?.Position}
                    candidate={evaluationDetails?.Candidate}
                    toggle={() => {
                        setProfileModal(!profileModal)
                    }}
                />
            </Modal>
            <SweetAlert
                show={noResume}
                warning
                showCancel
                // confirmBtnText="Yes, delete it!"
                // confirmBtnBsStyle="danger"
                title="Whoops!"
                // onConfirm={}
                onCancel={() => {
                    setNoResume(false)
                }}
                focusCancelBtn
                showConfirm={false}

                onConfirm={() => {
                    setNoResume(false)
                }}
            >
                Candidate hasn't uploaded PDF resume
            </SweetAlert>
            {/*<Modal*/}
            {/*    centered={true}*/}
            {/*    isOpen={noResume}*/}
            {/*    toggle={() => {*/}
            {/*        setNoResume(!noResume)*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <ModalBody>*/}
            {/*        /!*<Row className="justify-content-end">*!/*/}
            {/*        /!*    <Col lg={3}>*!/*/}
            {/*        /!*        <button className="float-right btn btn-link-secondary" onClick={() => toggle()}>close*!/*/}
            {/*        /!*        </button>*!/*/}
            {/*        /!*    </Col>*!/*/}
            {/*        /!*</Row>*!/*/}
            {/*        <Row className="align-items-center mb-4">*/}
            {/*            <Col>*/}
            {/*                <h5>Sorry candidate hasn't uploaded PDF resume</h5>*/}
            {/*            </Col>*/}
            {/*        </Row>*/}

            {/*        <Row className="justify-content-center">*/}
            {/*            <Col lg={6}>*/}
            {/*                <button className="btn btn-primary btn-block" onClick={() => {*/}
            {/*                    setNoResume(!noResume)*/}
            {/*                }}>Return</button>*/}
            {/*            </Col>*/}
            {/*        </Row>*/}
            {/*    </ModalBody>*/}
            {/*</Modal>*/}
            <Modal
                centered={true}
                isOpen={emailInterview}
                toggle={() => {
                    setEmailInterview(!emailInterview)
                }}
            >
                <EmailInterviewModal
                    evaluation={evaluationDetails}
                    toggle={() => {
                        setEmailInterview(!emailInterview)
                    }}
                />
            </Modal>
        </React.Fragment>
    )
}
const mapStateToProps = (state) => {
    return {
        getCurrentEvaluation: getCurrentEvaluationDetailSelector(state),
        getMessage: getMeiaAddedMessage(state),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCompanyEvaluationDetail: (data, props, cb) =>
            dispatch(
                getCompanyCandidateEvaluationDetailAction(data, props.history, cb)
            ),
        markCompanyCandidateEvaluation: (data, props, cb) =>
            dispatch(markCompanyCandidateEvaluationAction(data, null, cb)),
        addCompanyCandidateNote: (data, props, cb) =>
            dispatch(addCompanyCandidateNoteAction(data, null, cb)),
        deleteCompanyCandidateNote: (data, props, cb) =>
            dispatch(deleteCompanyCandidateNoteAction(data, null, cb)),
        markInterviewHire: (data, history) => dispatch(markInterviewHireAction(data, history)),
        uploadMediaToAWS: (data, history, cb) => dispatch(uploadMediaToAWSAction(data, history, cb)),
        addCandidatePdfResume: (data, history, cb) => dispatch(addCandidatePdfResumeAction(data, history, cb)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EvaluationDetails))
