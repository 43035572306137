import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import avatar from "../../../../images/default.svg";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  getCompanyCurrentPosition,
  getMeiaAddedMessage,
} from "../../../../store/selectors/CompanySelector";
import {
  addCompanyPositionTeamMemberAction,
  getCompanyPositionAction,
  removeCompanyPositionTeamMemberAction,
} from "../../../../store/actions/CompanyActions";

const RenderMembers = ({ mem, position }) => {
  return (
    <li key={mem?.id} className="active">
      <Link to="#" onClick={() => {}}>
        <Row className="align-items-center mb-2">
          <Col md={8}>
            <div className="d-flex ">
              <div >
                <img
                  src={mem?.AddedMemberUser?.image || avatar}
                  className="rounded-circle mr-2"
                  alt=""
                  style={{ height: "2.5rem" }}
                />
              </div>
              <div >
                <h5 className="d-inline-block">
                  {mem?.AddedMemberUser?.first_name +
                    " " +
                    mem?.AddedMemberUser?.last_name}
                  <br></br>
                  <span className="text-muted">
                    <small>
                      {" "}
                      {mem?.AddedMemberUser?.Roles.map(
                        (role) => role.name + " "
                      )}{" "}
                    </small>
                  </span>
                </h5>
              </div>
            </div >
          </Col>
          {
            //if user recruter &&
            <Col md={4} className="pl-0">
              {mem?.position_member ? (
                <button
                  onClick={() =>
                    position.removeCompanyPositionTeamMember(
                      {
                        position_id: position?.position?.id,
                        company_member_id: mem?.id,
                      },
                      position,
                      () => {
                        position.showAlert();
                      }
                    )
                  }
                  className="btn btn-danger  btn-sm"
                >
                  Remove
                </button>
              ) : (
                <button
                  onClick={() =>
                    position.addCompanyPositionTeamMember(
                      {
                        position_id: position?.position?.id,
                        company_member_id: mem?.id,
                      },
                      position,
                      () => {
                        position.showAlert();
                      }
                    )
                  }
                  className="btn btn-primary btn-sm"
                >
                  Add
                </button>
              )}
            </Col>
          }
        </Row>
      </Link>
    </li>
  );
};
const AddTeamMember = (props) => {
  const [teamSearch, setTeamSearch] = useState("");

  return (
    <Card style={{ height: "auto" }}>
      <CardBody>
        <div>
          <div>
            <h5>Team Members</h5>
            <input
              type="text"
              value={teamSearch}
              onChange={(e) => setTeamSearch(e.target.value)}
              className="form-control"
              placeholder="Search Member"
            />
          </div>
        </div>
        <hr />
        <div>
          <div>
            <ul className="list-unstyled chat-list">
              <div
                className="custom-scroll"
                style={{ height: "250px", width: "", overflowY: "scroll" }}
              >
                {props?.position?.Company?.CompanyMembers.map(
                  (mem, key) =>
                    mem?.AddedMemberUser?.first_name
                      ?.toLowerCase()
                      ?.includes(teamSearch.toLowerCase()) && (
                      <RenderMembers key={key} mem={mem} position={props} />
                    )
                )}
              </div>
            </ul>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    getCurrentPosition: getCompanyCurrentPosition(state),
    getMessage: getMeiaAddedMessage(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyPosition: (data, props, cb) =>
      dispatch(getCompanyPositionAction(data, props.history, cb)),
    addCompanyPositionTeamMember: (data, props, cb) =>
      dispatch(addCompanyPositionTeamMemberAction(data, props.history, cb)),
    removeCompanyPositionTeamMember: (data, props, cb) =>
      dispatch(removeCompanyPositionTeamMemberAction(data, props.history, cb)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddTeamMember)
);
