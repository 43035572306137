import React, {useState, useEffect, useRef} from "react"
import {Link} from "react-router-dom"
import {Row, Col, Table, Spinner} from "reactstrap"
import PageTitle from "../../layouts/PageTitle"
import PositionList from "./PositionList"
import Alert from "./../Common/Alert"
import PaginationComponent from "./../Common/PaginationComponent"
import {connect} from "react-redux"
import {withRouter} from "react-router-dom"
import {
    deleteCompanyPositionAction,
    getCompanyPositionQuestionsAction,
    getCompanyPositionsAction,
    updateCompanyPositionAction,
    getCompanySubscriptionAction,
} from "../../../store/actions/CompanyActions"
import {
    getCompanyPositions,
    getMeiaAddedMessage,
    getCompanySubscription,
} from "../../../store/selectors/CompanySelector"

import "./positions.css"

const Positions = (props) => {
    const {getCompanySubscription, getMessage, getSubscription, getPositions} = props
    const [loader, setloader] = useState(false)
    const [alert, showAlert] = useState(false)
    const [filterTitle, setFilterTitle] = useState("")
    const isMounted = useRef(false)

    useEffect(() => {
        submitFilter(1, true)
        getCompanySubscription()
        setFilterTitle("")
    }, [])

    useEffect(() => {
        if (getMessage?.updated || getMessage?.delete || getMessage?.duplicate) {
            submitFilter()
        }
    }, [getMessage?.updated, getMessage?.delete, getMessage?.duplicate])

    useEffect(() => {
        if (filterTitle) {
            submitFilter()
            isMounted.current = true
        } else {
            if (isMounted.current) {
                submitFilter()
                isMounted.current = false
            }
        }
    }, [filterTitle])

    const submitFilter = (page = 1, bol = false) => {
        const data = {
            pagination: {
                page: page,
                size: 10,
            },
        }
        if (filterTitle) {
            data.filter_options = {
                search_string: filterTitle,
                sort_array: [["createdAt", "DESC"]],
            }
        }
        if (bol) setloader(bol)
        props.getCompanyPositions(data, props, () => {
            bol && setloader(false)
        })
    }

    return (
        <>
            {/*<PageTitle activeMenu="Positions" motherMenu="Dashboard" />*/}
            <Alert
                alert={alert}
                type={getMessage?.success || getMessage?.delete || getMessage?.duplicate ? "success" : getMessage?.error && "error"}
                variant={getMessage?.success || getMessage?.delete || getMessage?.duplicate ? "success" : getMessage?.error && "danger"}
                msg={getMessage?.success || getMessage?.duplicate || getMessage?.delete || getMessage?.error}
                dismissAlert={() => showAlert(false)}
            />
            <div className="">
                <Row className="mb-3" style={{alignItems: 'center'}}>
                    <Col lg={4}>
                        <div className="d-inline-block d-inline-block float-left">
                            <Link to={{pathname: "./position/add", form: "new"}}
                                  className={`btn btn-primary ${getSubscription?.limit?.positions?.allowed === getSubscription?.limit?.positions?.used ? "disabled" : ""}`}>
                                Create a position
                            </Link>
                        </div>
                    </Col>
                    {/* <Col lg></Col> */}
                    <Col lg={8} md={7}>
                        <Row style={{alignItems: 'center'}}>
                            <Col lg={7} md={7}>
                                {/* <div > */}
                                <Row className="float-right" style={{alignItems: 'center', justifyContent: 'center'}}>
                                    <div className="mr-2">Positions
                                        Limit: {getSubscription?.limit?.positions?.allowed === getSubscription?.limit?.positions?.used ?
                                            <span
                                                style={{color: "red"}}>Limit Reached</span> : getSubscription?.limit?.positions?.allowed}</div>
                                    <div>
                                            <span
                                                onClick={() => props.history.push("/subscriptions")}
                                                className="btn btn-primary text-white"
                                                style={{cursor: "pointer", paddingInline: '22px'}}
                                            >
                                            {" "}
                                                Increase Limit{" "}
                                            </span>
                                    </div>
                                </Row>
                                {/* </div> */}
                            </Col>
                            <Col xl={5} lg={5} md={5}>
                                <div className="input-group float-right">
                                    <input
                                        type="text"
                                        value={filterTitle}
                                        onChange={(e) => setFilterTitle(e.target.value)}
                                        className="form-control"
                                        placeholder="Search (min 4 characters)"
                                        style={{height: '36px'}}
                                    />
                                    <div className="input-group-append">
                                        <button className="btn btn-secondary" disabled={true} type="button">
                                            <i className="fa fa-search"></i>
                                        </button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <div>
                <div className="card">
                    <div className="">
                        <Table className="table-hover text-dark">
                            <thead>
                            <tr>
                                <th style={{width: "70px"}}></th>
                                <th>
                                    <div data-delay="{ show: 1000, hide: 3000}" data-toggle="tooltip"
                                         style={{cursor: "pointer"}}
                                         data-placement="top" title="Position Title">Title
                                    </div>
                                </th>
                                <th>
                                    <div data-delay="{ show: 1000, hide: 3000}" data-toggle="tooltip"
                                         style={{cursor: "pointer"}}
                                         data-placement="top" title="Position job location">Location
                                    </div>
                                </th>
                                <th>
                                    <div data-delay="{ show: 1000, hide: 3000}" data-toggle="tooltip"
                                         style={{cursor: "pointer"}}
                                         data-placement="top" title="Position closing date">Closing Date
                                    </div>

                                </th>
                                <th>
                                    <div data-delay="{ show: 1000, hide: 3000}" data-toggle="tooltip"
                                         style={{cursor: "pointer"}}
                                         data-placement="top" title="Position created by user name">Created By
                                    </div>

                                </th>
                                <th>
                                    <div data-delay="{ show: 1000, hide: 3000}" data-toggle="tooltip"
                                         style={{cursor: "pointer"}}
                                         data-placement="top" title="Position interviews">Interviews
                                    </div>

                                </th>
                                <th>
                                    <div data-delay="{ show: 1000, hide: 3000}" data-toggle="tooltip"
                                         data-placement="top" style={{cursor: "pointer"}}
                                         title="Position applicants">Evaluations
                                    </div>

                                </th>
                                <th>
                                    <div data-delay="{ show: 1000, hide: 3000}" data-toggle="tooltip"
                                         style={{cursor: "pointer"}}
                                         data-placement="top" title="Position active status">Status
                                    </div>
                                </th>
                                <th>
                                    Action
                                </th>
                            </tr>
                            </thead>
                            <tbody className="positions">
                            {loader ? (
                                <tr>
                                    <td style={{textAlignLast: "center"}} colSpan={9}>
                                        <Spinner size="sm" className="m-auto text-center" color="success"/>
                                    </td>
                                </tr>
                            ) : getPositions?.totalItems > 0 ? (
                                <PositionList showAlert={() => showAlert(true)}/>
                            ) : (
                                <tr>
                                    <td style={{textAlignLast: "center"}} colSpan={9}>
                                        No Data To Display
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
            <Row className="justify-content-center">
                {/* <PaginationComponent pages={1113} page={0} setPage={(page) => submitFilter(page)} count={getPositions?.totalItems} /> */}
                <PaginationComponent
                    pages={getPositions?.totalPages}
                    page={getPositions?.currentPage}
                    setPage={(page) => submitFilter(page)}
                    count={getPositions?.totalItems}
                />
            </Row>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        getMessage: getMeiaAddedMessage(state),
        getPositions: getCompanyPositions(state),
        getSubscription: getCompanySubscription(state),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCompanyPositions: (data, props, cb) => dispatch(getCompanyPositionsAction(data, props.history, cb)),
        updateCompanyPosition: (data, props, cb) => dispatch(updateCompanyPositionAction(data, props.history, cb)),
        deleteCompanyPosition: (data, props, cb) => dispatch(deleteCompanyPositionAction(data, props.history, cb)),
        getCompanyPositionQuestions: (data, props, cb) =>
            dispatch(getCompanyPositionQuestionsAction(data, props.history, cb)),
        getCompanySubscription: () => dispatch(getCompanySubscriptionAction()),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Positions))
