import React from "react"

import ReactApexChart from "react-apexcharts"

const PositionCandidateGraph = (props) => {
    const yearMonths = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ]
    const state = {
        series: [
            {
                name: "Invited",
                data: [props?.data?.length > 0 ? props?.data[1]?.interview_invited : 0],
            },
            {
                name: "Completed",
                data: [props?.data?.length > 0 ? props?.data[1]?.interview_completed : 0],
            },
            {
                name: "Qualified",
                data: [props?.data?.length > 0 ? props?.data[1]?.interview_qualified : 0],
            },
            {
                name: "Hired",
                data: [props?.data?.length > 0 ? props?.data[1]?.interview_hired : 0],
            },
        ],
        options: {
            chart: {
                type: "bar",
                height: 230,
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: "40px",
                    dataLabels: {
                        position: "top",
                    },
                },
            },
            colors: [
                "#00ba52",
                "#009477",
                "#006b7e",
                "#004164",
                "#001c35"
            ],
            legend: {
                show: true,
                position: "top",
                horizontalAlign: "left",
            },
            dataLabels: {
                enabled: false,
                offsetX: -6,
                style: {
                    fontSize: "12px",
                    // colors: ["#fff"],
                },
            },
            stroke: {
                show: true,
                colors: ["transparent"],
                width: 5,
            },
            yaxis: {
                title: {
                    text: "",
                },
                lines: {
                    show: false,
                },
                labels: {
                    formatter: function (val) {
                        return val === Infinity ? 1 : val.toFixed(0)
                    }
                }
            },
            xaxis: {
                show: false,
                categories: [""], //"Today", "This Week",
            },
        },
    }
    return (
        <div id="chart" className="apex-chart">
            <ReactApexChart
                options={state.options}
                series={state?.series}
                type="bar"
                height={250}
                // height={350}
            />
        </div>
    )
}
export default PositionCandidateGraph
