import React from "react"
import {Card, CardBody, Col, Row} from "reactstrap"

const ShareInterview = ({setInviteModal, position}) => {
    return (
        <Card style={{height: "auto"}}>
            <CardBody className="share-card">
                <Row className="align-items-center">
                    {/* <Col md={1}>
              <i className='fa fa-share mainColor share-icon mt-3' style={{fontSize:'30px'}}></i>
            </Col> */}
                    <Col md={12} className="align-items-center">
                        <div>
                            <h4>
                                <i className="fa fa-share share-icon fa-lg mr-2" style={{color: "#00BA52"}}></i>
                                Share Interview Link
                            </h4>
                            <span className="d-inline-block text-muted">
                Anyone with this link will be able to take an interview for this position.
              </span>
                        </div>
                        <Row className="mt-3">
                            <Col lg={6}>
                                <input
                                    className="form-control"
                                    disabled={true}
                                    width="120%"
                                    value={`https://app.digitalhire.com/interview/${position?.id}`}
                                />
                            </Col>
                            <Col lg={3}>
                                <button
                                    onClick={() => {
                                        navigator.clipboard.writeText(`https://app.digitalhire.com/interview/${position?.id}`)
                                    }}
                                    className="btn btn-outline-primary btn-block"
                                >
                                    Copy Link
                                </button>
                            </Col>
                            <Col lg={3} className="px-1">
                                <button onClick={() => setInviteModal(true)} className="btn btn-primary btn-block px-1">
                                    Invite Candidate
                                </button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default ShareInterview
