// import { useOnClickOutside } from "components/Common/clickOutside"
import React, {useRef, useState, useEffect} from "react"
import {withRouter, Link} from "react-router-dom"
import {connect} from "react-redux"
import {
    Col,
    Row,
    UncontrolledDropdown
} from "reactstrap"
import Alert from "./../Common/Alert"
import {
    addCompanyTagAction,
    addCompanyInterviewTagAction,
    addCompanyCandidateTeamMemberAction,
    deleteCompanyTagAction,
    removeCompanyCandidateTagAction
} from "../../../store/actions/CompanyActions"
import {
    getDataForTagsModal,
    getDataForMembersModal,
    getMeiaAddedMessage
} from "../../../store/selectors/CompanySelector"

const TeamMember = (props) => {
    const {
        interview,
        onHide,
        getMemberData,
        addTeamMemberAction,
        getMessage
    } = props;
    let errorsObj = {tag: ""};
    const [errors, setErrors] = useState(errorsObj);
    const [tag, setTag] = useState("");
    const [color, setColor] = useState("#556ee6");
    const [dropdown, setDropdown] = useState(false);
    const [colorMenu, setColorMenu] = useState(false);
    const [alert, showAlert] = useState(false);
    const [checked, setChecked] = useState([]);

    const ref = useRef()
    const colorMenuRef = useRef()

    useOnClickOutside(ref, () => setDropdown(false))
    useOnClickOutside(colorMenuRef, () => setColorMenu(false))


    const handleRecruiters = members => {
        const interviewRecruiters = interview?.interviewRecruiters
        const intRecsIDs = interviewRecruiters?.map(rec => (rec = rec.id)) || []

        const filteredRecruiters = members?.map(x => {
            if (intRecsIDs.includes(x.id)) {
                x.added = true
            } else {
                x.added = false
            }
            return x
        })

        return filteredRecruiters
    }

    const handleRecruiterToggle = (recruiter, interviewID, check) => {
        const newData = [...checked];
        const index = checked.findIndex(item => {
            return item?.interview_id === interviewID && item?.company_member_id === recruiter?.id;
        });
        if (index === -1 && newData.length === 0) {
            newData.push({
                "interview_id": interviewID,
                "company_member_id": recruiter?.id,
                status: check !== -1 ? 'remove' : 'added'
            });
            setChecked(newData);
        } else if (index === -1) {
            newData.push({
                "interview_id": interviewID,
                "company_member_id": recruiter?.id,
                status: check !== -1 ? 'remove' : 'added'
            });
            setChecked(newData);
        } else {
            const addData = newData.map(obj => {
                if (obj.interview_id === interviewID && obj?.company_member_id === recruiter?.id) {
                    return {...obj, status: obj.status === 'added' ? 'remove' : 'added'};
                }
                return obj;
            });
            setChecked(addData);
        }
        addTeamMemberAction({"interview_id": interviewID, "company_member_id": recruiter?.id}, props, (bol) => {
            showAlert(true);
            if (!bol) {
                const removeData = newData.map(obj => {
                    if (obj.interview_id === interviewID && obj?.company_member_id === recruiter?.id) {
                        return {...obj, status: 'remove'};
                    }
                    return obj;
                });
                setChecked(removeData);
            }
            // bol && onHide()
        })
    }

    const renderMembers = members => {

        let result = []
        if (members && members.length > 0) {
            members.map((member, index) => {
                const check = interview?.CompanyMembers?.findIndex(x => x?.Interview_Company_Member?.company_member_id === member?.id)

                function isChecked(item) {
                    if (item?.interview_id === interview?.id && item?.company_member_id === member?.id) {
                        return item;
                    }
                    return false;
                }

                const findChecked = () => checked.find(isChecked)?.status;
                result.push(
                    <li
                        onClick={() => handleRecruiterToggle(member, interview?.id, check)}
                        style={{cursor: "pointer"}}
                        key={member?.id}
                        className="list-group-item"
                    >
                        <Row>
                            <Col lg="10">
                                <div
                                    className="d-inline-block mr-2">{member?.AddedMemberUser?.first_name} {member?.AddedMemberUser?.last_name}</div>
                                <span
                                    className="text-muted">({member?.AddedMemberUser?.Roles ? member?.AddedMemberUser?.Roles.map(role => role.name + " ") : ""})</span>
                            </Col>
                            <Col lg="2">
                                <i style={{fontSize: '17px'}}
                                   className={`bx float-right ${
                                       findChecked() ? (findChecked() === 'added' ? 'bx-check-circle text-primary' : 'x-circle') : check !== -1 ? 'bx-check-circle text-primary' : 'x-circle'
                                   }`}
                                ></i>
                            </Col>
                        </Row>
                    </li>
                )
            })
        }
        return result
    }


    return (
        <div className="new-tag-card p-3">
            <Alert alert={alert}
                   type={(getMessage?.success || getMessage?.delete) ? "success" : getMessage?.error && "error"}
                   variant={(getMessage?.success || getMessage?.delete) ? "success" : getMessage?.error && "danger"}
                   msg={getMessage?.success || getMessage?.delete || getMessage?.error}
                   dismissAlert={() => showAlert(false)}/>
            <Row>
                <Col>
                    <Row style={{justifyContent: 'space-between', marginInline: '0px'}}>
                        <h5>Assign Team Member</h5>
                        <i
                            className={'fa fa-window-close'}
                            style={{fontSize: '17px', cursor: "pointer"}}
                            onClick={() => onHide()}
                        />
                    </Row>
                    <div className="new-tag-members custom-scroll">
                        <ul className="list-group">
                            {renderMembers(handleRecruiters(getMemberData))}
                        </ul>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        getTagsData: getDataForTagsModal(state),
        getMessage: getMeiaAddedMessage(state),
        getMemberData: getDataForMembersModal(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addTag: (data, props, cb) => dispatch(addCompanyTagAction(data, props.history, cb)),
        addInterviewTag: (data, props, cb) => dispatch(addCompanyInterviewTagAction(data, props.history, cb)),
        addTeamMemberAction: (data, props, cb) => dispatch(addCompanyCandidateTeamMemberAction(data, props.history, cb)),
        deleteCompanyTag: (data, props, cb) => dispatch(deleteCompanyTagAction(data, props.history, cb))
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TeamMember))


function useOnClickOutside(ref, handler) {
    useEffect(
        () => {
            const listener = event => {
                if (!ref.current || ref.current.contains(event.target)) {
                    return
                }

                handler(event)
            }

            document.addEventListener("mousedown", listener)
            document.addEventListener("touchstart", listener)

            return () => {
                document.removeEventListener("mousedown", listener)
                document.removeEventListener("touchstart", listener)
            }
        },
        [ref, handler]
    )
}