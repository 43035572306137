import React, {useEffect, useRef, useState} from "react";
import {Card, CardBody, CardSubtitle, CardTitle, Col, Badge, Form, Label} from "reactstrap";
import {Row, Modal, Spinner, Alert} from "react-bootstrap";
import {Link, withRouter} from "react-router-dom";
import MediaLibrary from "./PositionMedia";
import {
    getCompanyCurrentPosition,
    getMeiaAddedMessage,
    getCompanyCurrentPositionQuestions
} from "../../../../store/selectors/CompanySelector";
import {
    addCompanyPositionQuestionAction,
    deleteCompanyPositionQuestionAction,
    getCompanyPositionQuestionsAction,
    updateCompanyPositionQuestionAction,
    updateCompanyPositionQuestionSeqAction,
    getCompanyMediaAction,
} from "../../../../store/actions/CompanyActions";
import {connect} from "react-redux";
import SortableComponent from "./SortableColumn";
import {arrayMove} from "react-sortable-hoc";
import DeletePrompt from "../../Common/DeletePrompt";
import VideoThumbnail from "react-video-thumbnail";

const AddPositionQuestions = (props) => {
    const {getCurrentPosition, modeOn, getMessage, getCurrentPositionQuestions, activeTab, nextTab, showAlert} = props;
    let errorsObj = {ques: "", allowTime: "", attempts: "", type: ""};
    const [errors, setErrors] = useState(errorsObj);
    const [ques, setQues] = useState("");
    const [allowTime, setAllowTime] = useState("");
    const [attempts, setattempts] = useState("");
    const [quesDetail, setQuesDetail] = useState("");
    const [questions, setQuestions] = useState([]);
    const [quesUpdate, setQuesUpdate] = useState(false);
    const [mediaModal, setMediaModal] = useState(false);
    const [file, setFile] = useState(undefined);
    const [editMode, setEditMode] = useState(undefined);
    const [type, setType] = useState("");
    const [deleteModal, setDeleteModal] = useState({show: false, func: undefined});
    const [loader, setLoader] = useState(false);
    const [mediaLoader, setMediaLoader] = useState(false);
    const formRef = useRef(null);

    useEffect(() => {
        if (((getMessage?.success && getMessage?.success !== 'Company Media Added Successfully') || getMessage?.delete) && activeTab) {
            setEditMode(undefined)
            // formRef.current.reset()
            setFile(undefined)
            setType('');
            setQues('');
            setAllowTime('');
            setattempts('');
            setQuesDetail('');
            const data = {
                position_id: getCurrentPosition?.id
            }
            props.getCompanyPositionQuestions(data, props,)
        }
    }, [getMessage?.success, getMessage?.delete])

    useEffect(() => {
        !mediaLoader && props.loadCompanyMedia({}, props)
    }, [mediaLoader])

    useEffect(() => {
        setBoard(getCurrentPositionQuestions, 'list')
    }, [getCurrentPositionQuestions])

    const handleSetFile = file => {
        if (editMode) {
            setEditMode({
                ...editMode,
                video: file
            })
        } // else {
        setFile(file)
        // }
    }

    const setBoard = (board, type) => {
        if (board && board.length > 0) {
            if (type === 'list') {
                board.sort(function (a, b) {
                    return a.seq - b.seq;
                });
                board = board?.map((ques, i) => {
                    if (ques) {
                        ques.seqIndex = i + 1
                        return ques
                    }
                })
                setQuestions(board);
                return
            }

            board = board?.map((ques, i) => {
                if (ques) {
                    ques.seqIndex = i + 1
                    return ques
                }
            })
            loop1:
                for (let i = 0; i < getCurrentPositionQuestions.length; i++) {
                    loop2:
                        for (let j = i; j < board.length; j++) {
                            if (getCurrentPositionQuestions[i].id === board[j].id) {
                                break loop2;
                            } else {
                                // console.log(j, board[j]);
                                //  Question edit sequence
                                const data = {
                                    position_id: getCurrentPosition?.id,
                                    question_data: {
                                        id: board[j].id,
                                        seq: board[j].seqIndex
                                    }
                                }
                                props.updateCompanyPositionQuestionSeq(data, props)
                                break loop1;
                            }
                        }
                }

            setQuestions(board);
        } else {
            setQuestions([])
        }
    }

    const onSubmit = (e) => {
        e.preventDefault()

        let error = false
        const errorObj = {...errorsObj}
        if (ques === "") {
            errorObj.ques = "Question is Required"
            error = true
        }
        if (type === "") {
            errorObj.type = "Question Type is Required"
            error = true
        }
        if (allowTime === "") {
            errorObj.allowTime = "Maximum Time Allowed is Required"
            error = true
        }
        if (type === "video" && attempts === "") {
            errorObj.attempts = "Allow Attempts are Required"
            error = true
        }
        setErrors(errorObj)
        if (error) {
            return
        }
        const data = {
            position_id: getCurrentPosition?.id,
            question_data: {
                title: ques,
                description: quesDetail,
                video: file === undefined ? "removed" : file?.id,
                type: type,
                time_allowed: allowTime,
                attempts_allowed: type === 'video' ? attempts && attempts : 1
            }
        }
        if (editMode?.id) {
            setLoader(true);
            data.question_data.id = editMode.id;
            props.updateCompanyPositionQuestion(data, props, () => {
                props.showAlert();
                setLoader(false);
            })

        } else {
            setLoader(true);
            props.addCompanyPositionQuestion(data, props, () => {
                props.showAlert();
                setLoader(false);
            })
        }
    }

    const renderTimeInput = type => {
        const essay = [5, 10, 15, 20, 25]
        const regular = [1, 2, 3, 4, 5]
        const values = type === "essay" ? essay : regular

        const times = []
        times.push(<option key={100} value="">Please Select time</option>)
        values?.map((val, i) => {
            times.push(<option key={i} value={val}>
                {val} {val === 1 ? "Minute" : "Minutes"}

            </option>)
        })

        return times
    }

    const cancelEditMode = () => {
        // console.log(question)
        setFile(undefined)
        setQues("")
        setAllowTime("")
        setattempts("")
        setQuesDetail("")
        setType("")
        setEditMode(undefined)
    }

    const editQuestion = id => {
        const question = getCurrentPositionQuestions.find(ques => ques.id === id)
        if (question) {
            setEditMode(question)
            setQues(question?.title)
            setAllowTime(question?.time_allowed)
            setattempts(question?.attempts_allowed)
            setQuesDetail(question?.description)
            setType(question?.type)
            setFile(question?.video)
        }
    }

    const deleteQuestion = id => {
        // let board = getCurrentPositionQuestions.filter(ques => {
        //   return ques.id !== id
        // })
        // board = arrayMove(board, 0, 0)
        // setBoard(board, undefined, undefined, "delete")

        const data = {
            "position_id": getCurrentPosition?.id,
            "question_id": id
        }

        setLoader(true);
        props.deleteCompanyPositionQuestion(data, props, () => {
            props.showAlert();
            setLoader(false);
        })
        setDeleteModal({show: false, func: undefined})
        if (editMode) {
            if (editMode.id === id) setEditMode(undefined)
        }
    }

    return (
        <>
            <Card>
                <Form onSubmit={(e) => onSubmit(e)} ref={formRef}>
                    <CardBody>
                        <CardTitle>Add Questions</CardTitle>
                        <CardSubtitle className="mb-3">
                            Fill out all the required fields below!
                        </CardSubtitle>
                        <hr/>
                        <Row>
                            <Col lg={12}>
                                <div className="form-group" style={{height: "80px"}}>
                                    <Label> Question{" "} <span className="text-muted"> <small>Required</small> </span>
                                    </Label>
                                    <input
                                        name="quesTitle"
                                        className="form-control"
                                        placeholder="Type your question here"
                                        type="text"
                                        value={ques}
                                        onChange={(e) => setQues(e.target.value)}
                                    />
                                    {errors.ques &&
                                        <div className="animated fadeInUp text-danger fs-12"
                                             style={{marginTop: 5}}>{errors.ques}</div>}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <div className="form-group" style={{height: "80px"}}>
                                    <Label>Question Type{" "}<span
                                        className="text-muted"> <small>Required</small> </span></Label>
                                    <select
                                        name="type"
                                        className="form-control"
                                        value={type}
                                        onChange={e => setType(e?.target?.value)}
                                    >
                                        <option value="">Please choose type</option>
                                        <option value="video">Video</option>
                                        <option value="shortText">Short Text</option>
                                        <option value="essay">Essay</option>
                                    </select>
                                    {errors.type &&
                                        <div className="animated fadeInUp text-danger fs-12"
                                             style={{marginTop: 5}}>{errors.type}</div>}
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="form-group" style={{height: "80px"}}>
                                    <Label>Maximum Time Allowed{" "}<span
                                        className="text-muted"> <small>Required</small> </span></Label>
                                    <select
                                        name="timeAllowed"
                                        className="form-control"
                                        value={allowTime}
                                        onChange={(e) => setAllowTime(e.target.value)}
                                    >
                                        {renderTimeInput(type)}
                                    </select>
                                    {errors.allowTime && <div className="animated fadeInUp text-danger fs-12"
                                                              style={{marginTop: 5}}>{errors.allowTime}</div>}
                                </div>
                            </Col>
                            <Col lg={4}>
                                {type === "video" && (
                                    <div className="form-group select2-container" style={{height: "80px"}}>
                                        <Label>Attempts Allowed{" "}<span
                                            className="text-muted"> <small>Required</small></span></Label>
                                        <select
                                            name="attemptsAllowed"
                                            className="form-control"
                                            type="select"
                                            value={attempts}
                                            onChange={(e) => setattempts(e.target.value)}
                                            required
                                        >
                                            <option value={""}>Choose Attempts Allowed</option>
                                            <option value={1}>1 Attempt</option>
                                            <option value={2}>2 Attempts</option>
                                            <option value={3}>3 Attempts</option>
                                        </select>
                                        {errors.attempts && <div className="animated fadeInUp text-danger fs-12"
                                                                 style={{marginTop: 5}}>{errors.attempts}</div>}
                                    </div>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <Label> Question Details{" "} <span
                                    className="text-muted"> <small>Optional</small> </span></Label>
                                <div className="form-group">
                  <textarea
                      name="quesDescription"
                      className="form-control"
                      placeholder="Add further details or instructions for your question"
                      value={quesDetail}
                      onChange={(e) => setQuesDetail(e.target.value)}
                  />
                                </div>
                            </Col>
                        </Row>
                        <Row className="align-items-end">
                            <Col lg={6}>{
                                file || editMode?.video ? <Row className="align-items-center">
                                    <Col lg={6}>
                                        <div style={{
                                            maxHeight: "130px",
                                            maxWidth: "220px",
                                            overflow: "hidden",
                                            borderRadius: "15px"
                                        }}>
                                            {
                                                editMode ? editMode?.video ?
                                                        editMode?.video?.type === "video" ?
                                                            <VideoThumbnail
                                                                videoUrl={editMode?.video?.url}
                                                                thumbnailHandler={(thumb) => console.log(thumb)}
                                                                height={130}
                                                                width={220}
                                                            />
                                                            :
                                                            <img src={editMode?.video?.url} className="img-fluid"
                                                                 width="100%"
                                                                 style={{maxHeight: "130px"}}/> : ""
                                                    :

                                                    file ? file?.type === "video" ?
                                                        <VideoThumbnail
                                                            videoUrl={file?.url}
                                                            thumbnailHandler={(thumb) => console.log(thumb)}
                                                            height={125}
                                                            width={215}
                                                        />
                                                        :
                                                        <img src={file?.url} className="img-fluid" width="100%"
                                                             style={{maxHeight: "130px"}}/> : ""
                                            }
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div>{file?.name || editMode?.video?.name}</div>
                                        <div>
                                            <button
                                                type="button"
                                                className="btn btn-link text-primary"
                                                onClick={() =>
                                                    setMediaModal({
                                                        id: "question",
                                                        show: true
                                                    })
                                                }
                                            >
                                                Replace
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-link text-danger"
                                                onClick={() =>
                                                    setDeleteModal({
                                                        show: true,
                                                        func: () => {
                                                            handleSetFile(undefined)
                                                            setDeleteModal(undefined)
                                                        }
                                                    })
                                                }
                                            >
                                                Remove
                                            </button>
                                        </div>
                                    </Col>
                                </Row> : <>
                                    <h5>Add a Video or Image</h5>
                                    <span
                                        className="text-subtitle d-block">Show a video or an image with the question</span>
                                    <div className="dz-message needsclick filesUpload mt-2"
                                         onClick={() => setMediaModal({id: "question", show: true})}
                                         style={{border: "dotted"}}>
                                        <div><i className="display-4 fa fa-cloud-upload"/></div>
                                        <h5>Open Media Libary</h5>
                                    </div>
                                </>
                            }
                            </Col>
                            <Col lg={6}>
                                <button type="submit" className="btn btn-block btn-primary float-right col-3"
                                        onClick={onSubmit}
                                        disabled={loader}>
                                    {loader ?
                                        <Spinner size="sm" variant="mutted" animation="border"/>
                                        :
                                        editMode ? "Save Changes" : "Add Question"
                                    }
                                </button>
                                {editMode && (
                                    <button type="button" className="btn red float-right"
                                            onClick={() => cancelEditMode()}> Cancel </button>
                                )}
                            </Col>
                        </Row>
                        {editMode && (
                            <Alert className="mt-2" variant="warning">
                                You are in Question Edit Mode. Click 'Update Question' or Cancel
                                to exit Edit Mode.
                            </Alert>
                        )}
                        <hr/>
                        {questions ? (
                            questions.length > 0 ? (
                                <SortableComponent
                                    deleteQuestion={id =>
                                        setDeleteModal({
                                            show: true,
                                            func: () => deleteQuestion(id),
                                        })
                                    }
                                    editQuestion={editQuestion}
                                    editMode={editMode}
                                    questions={questions}
                                    setBoard={setBoard}
                                />
                            ) : (
                                <Alert variant="warning">No Questions here</Alert>
                            )
                        ) : (
                            ""
                        )}
                    </CardBody>
                </Form>
            </Card>
            <Modal
                centered={true}
                size="md"
                show={deleteModal?.show}
                onHide={() => {
                    setDeleteModal({func: undefined, show: false})
                }}
            >
                <DeletePrompt
                    title={"Are you sure ?"}
                    func={() => deleteModal?.func()}
                    toggle={() => {
                        setDeleteModal({func: undefined, show: false})
                    }}
                />
            </Modal>
            <Modal centered={true} size="lg" show={mediaModal.show} onHide={() => {
                setMediaModal({id: undefined, show: false})
            }}>
                <MediaLibrary setFile={handleSetFile} media={mediaModal} toggle={() => {
                    setMediaModal({id: undefined, show: false})
                }} setLoader={(val) => setMediaLoader(val)} loader={mediaLoader}/>
            </Modal>
            <Row className="my-4 align-items-center">
                <Col sm="6">
                </Col>
                <Col></Col>
                <Col sm="2">
                    <div className="text-sm-right">
                        {quesUpdate ? (
                            <button
                                type="button"
                                onClick={() => {
                                    // dispatch(updatePosQuestions(position?._id, questions))
                                    setTimeout(() => {
                                        setQuesUpdate(false)
                                    }, 1500)
                                }}
                                className="float-right mb-2 btn btn-danger"
                                // disabled={loading}
                                style={{width: "160px"}}
                            >
                                Update Questions
                            </button>
                        ) : ""}
                        {questions.length > 0 && <button onClick={() => {
                            nextTab('Review')
                        }}
                                                         className="btn btn-primary btn-block">Next</button>
                        }
                    </div>
                </Col>
            </Row>
        </>
    )
}


const mapStateToProps = (state) => {
    return {
        getCurrentPosition: getCompanyCurrentPosition(state),
        getMessage: getMeiaAddedMessage(state),
        getCurrentPositionQuestions: getCompanyCurrentPositionQuestions(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addCompanyPositionQuestion: (data, props, cb) => dispatch(addCompanyPositionQuestionAction(data, props.history, cb)),
        updateCompanyPositionQuestion: (data, props, cb) => dispatch(updateCompanyPositionQuestionAction(data, props.history, cb)),
        updateCompanyPositionQuestionSeq: (data, props, cb) => dispatch(updateCompanyPositionQuestionSeqAction(data, props.history, cb)),
        deleteCompanyPositionQuestion: (data, props, cb) => dispatch(deleteCompanyPositionQuestionAction(data, props.history, cb)),
        getCompanyPositionQuestions: (data, props, cb) => dispatch(getCompanyPositionQuestionsAction(data, props.history, cb)),
        loadCompanyMedia: (data, props, cb) => dispatch(getCompanyMediaAction(data, props.history, cb))
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddPositionQuestions))
