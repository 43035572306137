import React, { useState, useEffect, Fragment } from "react"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import { Modal } from "react-bootstrap"

import ImageCropModal from "../Common/ImageCropModal"
import avatar from "../../../images/default.svg"
import { registerCompanyAction } from "../../../store/actions/CompanyActions"
import { updateUserProfileAction, getUserProfileAction } from "../../../store/actions/AuthActions"
import { uploadMediaToAWSAction } from "../../../store/actions/MediaActions"
import { withRouter } from "react-router-dom"
import { connect, useSelector } from "react-redux"
import { getUserProfile, getUserProfileLoading } from "../../../store/selectors/AuthSelectors"
import Loader from "../Loader"
import {
  getCompany,
  getCompanyCountries,
  getCompanyIndustries,
  getCompanyMembers,
  getCompanyMessage,
} from "../../../store/selectors/CompanySelector"

const InfoSection = (props) => {
  let errorsObj = { fname: "", lname: "", nEmail: "", nPhone: "" }
  const [errors, setErrors] = useState(errorsObj)
  const [fname, setFName] = useState("")
  const [lname, setLName] = useState("")
  const [nEmail, setEmail] = useState("")
  const [nPhone, setPhone] = useState("")
  const [img, setImg] = useState(undefined)
  const [uploadFile, setUploadFile] = useState(undefined)
  const [preImg, setPreImg] = useState(undefined)
  const [cropModal, setCropModal] = useState(false)
  const hiddenFileInput = React.useRef(null)
  const [isLoading, setIsLoading] = useState(props?.userProfileLoading)

  const { first_name, last_name, email, image, phone } = props.getUserProfile

  useEffect(() => {
    if (props.profile) {
      setFName(first_name)
      setLName(last_name)
      setEmail(email)
      setPhone(phone)
    }
    console.log(props?.userProfileLoading, "props?.userProfileLoading")
  }, [props.profile])

  const handleChange = (event) => {
    if (event.target.files[0]) {
      const fileUploaded = event.target.files[0]

      setImg(URL.createObjectURL(fileUploaded))
      setTimeout(() => {
        setCropModal(true)
      }, 300)
    }
  }

  const handleCropSubmit = (file) => {
    const media_key = "user/" + file.lastModified + "." + file.type.split("/")[1]

    setImg(media_key)
    setUploadFile(file)
    setPreImg(URL.createObjectURL(file))

    setCropModal(false)
  }

  function onSubmit(e) {
    e.preventDefault()
    let error = false
    const errorObj = { ...errorsObj }
    if (fname === "") {
      errorObj.fname = "First Name is Required"
      error = true
    }
    if (lname === "") {
      errorObj.lname = "Last Name is Required"
      error = true
    }
    if (nEmail === "") {
      errorObj.nEmail = "Email is Required"
      error = true
    }
    if (nPhone === "") {
      errorObj.nPhone = "Phone Number is Required"
      error = true
    }
    setErrors(errorObj)
    if (error) {
      return
    }
    const data = {
      user_data: {
        first_name: fname,
        last_name: lname,
        // email: nEmail,
        phone: nPhone,
        image: img,
      },
    }

    props.uploadMediaToAWS({ media_key: img, file: uploadFile }, props, () => {
      props.updateUserProfile(data, props.history, () => {
        props.showAlert()
        setImg(undefined)
        setIsLoading(false)
      })
    })
  }

  return (
    <Fragment>
      <form onSubmit={onSubmit} className=" col-12 ">
        <div className="row align-items-center">
          <div className={"col-2"} style={{ maxWidth: "fit-content" }}>
            <img src={preImg || image || avatar} className="rounded-circle avatar-mb" width={"130"} />
          </div>
          <div className={"col-7"}>
            <h4>Upload your Profile Picture</h4>
            <small className="d-block">Maximum Size Allowed 2MB | JPEG, JPG or PNG</small>
            <div className="custom-file my-2">
              <input
                type="file"
                className="custom-file-input"
                accept="image/*"
                ref={hiddenFileInput}
                onChange={(e) => handleChange(e)}
              />
              <label className="btn btn-primary">{"Browse"}</label>
            </div>
          </div>
          <div className="col-3">
            <div className="col-12 mb-2">
                <div className="text-muted">
                    <span>Last Updated</span>
                    <span
                        className="float-right">{props.getUserProfile?.updatedAt ? new Date(props.getUserProfile?.updatedAt).toLocaleDateString() : "N/A"}</span>
                </div>
            </div>
            <div className="col-12 mb-2">
                <div className="text-muted">
                    <span>Account Created</span>
                    <span
                        className="float-right">{props.getUserProfile?.createdAt ? new Date(props.getUserProfile?.createdAt).toLocaleDateString() : "N/A"}</span>
                </div>
            </div>
            <div className="col-12 mb-2">
                <div className="text-muted">
                    <span>Active Plan</span>
                    <span
                        className="float-right">{props.getUserProfile?.active_plan ? props.getUserProfile?.active_plan : "N/A"}</span>
                </div>
            </div>
            {props.getUserProfile?.subscription_deactivated_at ?
              <div className="col-12 mb-1">
                  <div className="text-muted">
                      <span>Deactivated on</span>
                      <span
                          className="float-right">{props.getUserProfile?.subscription_deactivated_at ? new Date(props.getUserProfile?.subscription_deactivated_at).toLocaleDateString() : "N/A"}</span>
                  </div>
              </div> : ""}
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-lg-6 mb-2">
            <div className={`form-group`}>
              <label className="text-label">
                First Name{" "}
                <span className="text-muted">
                  <small>Required</small>
                </span>
              </label>
              <input
                type="text"
                className="form-control"
                id="val-fname1"
                placeholder="Enter a first name.."
                onChange={(e) => setFName(e.target.value)}
                value={fname}
              />
              {errors.fname && <div className="animated fadeInUp text-danger fs-12">{errors.fname}</div>}
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className={`form-group`}>
              <label className="text-label">
                Last Name{" "}
                <span className="text-muted">
                  <small>Required</small>
                </span>
              </label>
              <input
                type="text"
                className="form-control"
                id="val-lname1"
                placeholder="Enter a last name.."
                onChange={(e) => setLName(e.target.value)}
                value={lname}
              />
              {errors.lname && <div className="animated fadeInUp text-danger fs-12">{errors.lname}</div>}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 mb-2">
            <div className={`form-group`}>
              <label className="text-label">
                Email{" "}
                <span className="text-muted">
                  <small>Required</small>
                </span>
              </label>
              <input
                type="text"
                className="form-control"
                id="val-email1"
                placeholder="Enter a Email Address"
                // onChange={(e) => setEmail(e.target.value)}
                value={nEmail}
                disabled
              />
              {errors.nEmail && <div className="animated fadeInUp text-danger fs-12">{errors.nEmail}</div>}
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className={`form-group`}>
              <label className="text-label">
                Phone Number{" "}
                <span className="text-muted">
                  <small>Required</small>
                </span>
              </label>
              <PhoneInput
                country={"us"}
                value={nPhone}
                onChange={(phone) => setPhone(phone)}
                placeholder="541-754-3010"
                inputStyle={{ width: "99%", height: "-webkit-fill-available", border: "hidden" }}
                containerClass={"form-control"}
                containerStyle={{ padding: 0 }}
                buttonStyle={{ borderRadius: " 10px 0 0 10px", border: "0" }}
              />
              {errors.nPhone && <div className="animated fadeInUp text-danger fs-12">{errors.nPhone}</div>}
            </div>
          </div>
        </div>
        <button
          type="submit"
          className="btn mr-2 btn-primary"
          // disabled={isSubmitting}
          onClick={() => setIsLoading(true)}
        >
          {isLoading ? <Loader spinnerClass="text-white" /> : "Save changes"}
        </button>
      </form>
      <Modal
        centered={true}
        size="lg"
        show={cropModal}
        onHide={() => {
          setCropModal(!cropModal)
          setImg(undefined)
        }}
      >
        <ImageCropModal
          handleCropSubmit={handleCropSubmit}
          img={img}
          toggle={() => {
            setCropModal(!cropModal)
            setImg(undefined)
          }}
        />
      </Modal>
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    getUserProfile: getUserProfile(state),
    userProfileLoading: getUserProfileLoading(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    uploadMediaToAWS: (data, props, cb) => dispatch(uploadMediaToAWSAction(data, props.history, cb)),
    updateUserProfile: (data, props, cb) => dispatch(updateUserProfileAction(data, props.history, cb)),
    getUserProfileAction: () => dispatch(getUserProfileAction()),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InfoSection))
