import React, {Fragment, useEffect, useState} from "react"
import {Table} from "react-bootstrap"
import {Spinner} from "reactstrap"
import PaymentList from "../Payment/PaymentList"
import {Link} from "react-router-dom"
import {
    getCompanyCandidateFilterDataSelector,
    getCompanyCandidates,
    getCompanyPaymentsSelector,
    getCompanySubscription,
    getMeiaAddedMessage,
} from "../../../store/selectors/CompanySelector"
import {
    getCompanyCandidateFilterDataAction,
    getCompanyCandidatesAction,
    getCompanyPaymentsAction,
    getCompanySubscriptionAction,
    getCompanyTagsAction,
} from "../../../store/actions/CompanyActions"
import {withRouter} from "react-router-dom"
import {connect} from "react-redux"
import "./style.css"
import {postCompanySubscriptionCheckoutConfirmationService} from "../../../services/CompanyService";

const Payment = (props) => {
    const [loader, setloader] = useState(false)
    const {getPayments, loadCompanyPayments} = props
    const [sort, setSort] = useState(['createdAt', 'DESC'])
    const [trackSort, setTrackSort] = useState(0)

    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);
    const redirect_status = parameters.get('redirect_status');
    const payment_intent = parameters.get('payment_intent');
    const payment_intent_client_secret = parameters.get('payment_intent_client_secret');
    console.log(redirect_status, payment_intent, payment_intent_client_secret);


    useEffect(() => {
        // setloader(true)
        loadCompanyPayments({
            sort_array: [sort],
        })
        if (redirect_status && payment_intent && payment_intent_client_secret) {
            postCompanySubscriptionCheckoutConfirmationService({
                payment_intent: payment_intent,
                secret_key: payment_intent_client_secret
            }).then(r => {
                console.log("CONFIRMATION: ", r)
            }).catch(e => {
                console.log("Error: ", e)
            })
        }
        // getCompanyTags({},props);
    }, [sort])

    return (
        <Fragment>
            <h1>Coming Soon</h1>
            {/*<div className="mb-4">*/}
            {/*    <Link to={{pathname: "/subscriptions"}} className="btn btn-primary">*/}
            {/*        Subscription*/}
            {/*    </Link>*/}
            {/*</div>*/}
            {/*<div className="card">*/}
            {/*    <div class="card-header">*/}
            {/*        <div class="card-title h5">Payments</div>*/}
            {/*    </div>*/}
            {/*    <div className="card-body">*/}
            {/*        <Table responsive className="text-dark table-striped table-hover">*/}
            {/*            <thead>*/}
            {/*            <tr>*/}
            {/*                <th*/}
            {/*                    style={{overflow: "hidden", cursor: "pointer"}}*/}
            {/*                    onClick={() => {*/}
            {/*                        setSort((prev) => {*/}
            {/*                            return prev[0] === "name" && prev[1] === "ASC" ? ["name", "DESC"] : ["name", "ASC"]*/}
            {/*                        })*/}
            {/*                        setTrackSort(1)*/}
            {/*                    }}*/}
            {/*                >*/}
            {/*                    Name*/}
            {/*                    <span className="ml-1">*/}
            {/*        {trackSort === 1 ? (*/}
            {/*            sort[1] === "DESC" ? (*/}
            {/*                <i className="fa fa-arrow-down"/>*/}
            {/*            ) : (*/}
            {/*                <i className="fa fa-arrow-up"/>*/}
            {/*            )*/}
            {/*        ) : (*/}
            {/*            <i className="bx bxs-sort-alt"/>*/}
            {/*        )}*/}
            {/*      </span>*/}
            {/*                </th>*/}
            {/*                <th*/}
            {/*                    style={{overflow: "hidden", cursor: "pointer"}}*/}
            {/*                    onClick={() => {*/}
            {/*                        setSort((prev) => {*/}
            {/*                            return prev[0] === "amount_paid" && prev[1] === "ASC"*/}
            {/*                                ? ["amount_paid", "DESC"]*/}
            {/*                                : ["amount_paid", "ASC"]*/}
            {/*                        })*/}
            {/*                        setTrackSort(2)*/}
            {/*                    }}*/}
            {/*                >*/}
            {/*                    Amount Paid*/}
            {/*                    <span className="ml-1">*/}
            {/*        {trackSort === 2 ? (*/}
            {/*            sort[1] === "DESC" ? (*/}
            {/*                <i className="fa fa-arrow-down"/>*/}
            {/*            ) : (*/}
            {/*                <i className="fa fa-arrow-up"/>*/}
            {/*            )*/}
            {/*        ) : (*/}
            {/*            <i className="bx bxs-sort-alt"/>*/}
            {/*        )}*/}
            {/*      </span>*/}
            {/*                </th>*/}
            {/*                <th*/}
            {/*                    style={{overflow: "hidden", cursor: "pointer"}}*/}
            {/*                    onClick={() => {*/}
            {/*                        setSort((prev) => {*/}
            {/*                            return prev[0] === "date_paid" && prev[1] === "ASC" ? ["date_paid", "DESC"] : ["date_paid", "ASC"]*/}
            {/*                        })*/}
            {/*                        setTrackSort(3)*/}
            {/*                    }}*/}
            {/*                >*/}
            {/*                    Date Paid*/}
            {/*                    <span className="ml-1">*/}
            {/*        {trackSort === 3 ? (*/}
            {/*            sort[1] === "DESC" ? (*/}
            {/*                <i className="fa fa-arrow-down"/>*/}
            {/*            ) : (*/}
            {/*                <i className="fa fa-arrow-up"/>*/}
            {/*            )*/}
            {/*        ) : (*/}
            {/*            <i className="bx bxs-sort-alt"/>*/}
            {/*        )}*/}
            {/*      </span>*/}
            {/*                </th>*/}
            {/*                <th style={{overflow: "hidden", cursor: "pointer"}}>*/}
            {/*                    Status*/}
            {/*                    <i className="bx bxs-sort-alt"></i>*/}
            {/*                </th>*/}
            {/*            </tr>*/}
            {/*            </thead>*/}
            {/*            {loader ? (*/}
            {/*                <tbody>*/}
            {/*                <tr>*/}
            {/*                    <td style={{textAlignLast: "center"}} colSpan={9}>*/}
            {/*                        <Spinner size="sm" className="m-auto text-center" color="success"/>*/}
            {/*                    </td>*/}
            {/*                </tr>*/}
            {/*                </tbody>*/}
            {/*            ) : getPayments && getPayments.length > 0 ? (*/}
            {/*                <PaymentList payData={getPayments}/>*/}
            {/*            ) : (*/}
            {/*                // <div></div>*/}
            {/*                <tbody>*/}
            {/*                <tr>*/}
            {/*                    <td style={{textAlignLast: "center"}} colSpan={9}>*/}
            {/*                        No Data To Display*/}
            {/*                    </td>*/}
            {/*                </tr>*/}
            {/*                </tbody>*/}
            {/*            )}*/}
            {/*        </Table>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </Fragment>
    )
}
const mapStateToProps = (state) => {
    return {
        getPayments: getCompanyPaymentsSelector(state),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadCompanyPayments: (data) => dispatch(getCompanyPaymentsAction(data)),
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Payment))
