import React, { Fragment, useState, useEffect } from "react";
import { Card, CardBody, CardTitle, Col, Modal, Row } from "reactstrap";
import { withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { uploadMediaToAWSAction } from "../../../store/actions/MediaActions";
import { updateCompanyAction } from "../../../store/actions/CompanyActions";
import avatar from "../../../images/default.svg";
import ImageCropModal from "../Common/ImageCropModal";

const CompanyLogo = (props) => {
  const [img, setImg] = useState(undefined);
  const [cropModal, setCropModal] = useState(false);
  const hiddenFileInput = React.useRef(null);

  // console.log(props.logo)

  const handleChange = (event) => {
    if (event.target.files[0]) {
      const fileUploaded = event.target.files[0];

      setImg(URL.createObjectURL(fileUploaded));
      setTimeout(() => {
        setCropModal(true);
      }, 300);
    }
  };

  const handleCropSubmit = (file) => {
    // upload image from here this is the fimal image after croping
    const media_key =
      "company/" + file.lastModified + "." + file.type.split("/")[1];
    setImg(media_key);
    const data = {
      company_data: {
        logo: media_key,
      },
      company_profile_data: {},
    };
    props.uploadMediaToAWS({ media_key, file }, props, () => {
      props.updateCompany(data, props, () => {
        props.showAlert();
      });
    });

    setCropModal(false);
    setImg(undefined);
  };
  return (
    <div className="col-12">
      <div className="card">
        <div className="card-header">
          <h5 className="card-title">Company Logo</h5>
        </div>
        <div className="card-body">
          <div className="row align-items-center">
            <div className={"col-3 mb-2 align-items-center"}>
              <img
                src={props.logo || avatar}
                className="rounded-circle avatar-mb bg-light p-1"
                width={"100"}
                alt=""
              />
            </div>
            <div className={"col-9"}>
              <span className="d-block text-muted">
                Maximum Size Allowed 2MB | JPEG, JPG or PNG
              </span>
              <div className="custom-file my-2">
                <input
                  type="file"
                  className="custom-file-input"
                  accept="image/*"
                  ref={hiddenFileInput}
                  onChange={(e) => handleChange(e)}
                />
                <label className="custom-file-label-cancel btn btn-primary">
                  Choose file
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        centered={true}
        size="lg"
        isOpen={cropModal}
        toggle={() => {
          setCropModal(!cropModal);
        }}
      >
        <ImageCropModal
          handleCropSubmit={handleCropSubmit}
          img={img}
          toggle={() => {
            setCropModal(!cropModal);
          }}
        />
      </Modal>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    uploadMediaToAWS: (data, props, cb) =>
      dispatch(uploadMediaToAWSAction(data, props.history, cb)),
    updateCompany: (data, props, cb) =>
      dispatch(updateCompanyAction(data, props.history, cb)),
  };
};
export default withRouter(connect(null, mapDispatchToProps)(CompanyLogo));
