import React, {Component, useEffect, Fragment} from "react"
import {render} from "react-dom"
import {Row, Col} from "react-bootstrap"
import {SortableContainer, SortableElement} from "react-sortable-hoc"
import {arrayMoveImmutable as arrayMove} from "array-move"
import QuestionBox from "./QuestionBox"

const SortableItem = SortableElement(({value, edit}) => <QuestionBox key={value?.id} edit={edit} data={value}/>)

const SortableList = SortableContainer(({items, editMode, onDelete, edit, onArrowClick}) => {
    items = items.sort((a, b) => a.seqIndex - b.seqIndex)
    return (
        <ul>
            {items && items.length > 0
                ? items.map((value, index) => (
                    <Fragment key={`item-${value?.id}`}>
                        {value?.id ? (
                            <Row style={{alignItems: "baseline"}}>
                                <div style={{display: "flex", flexDirection: "column", flexWrap: "wrap"}}
                                     className="ml-1 mr-1">
                                    <i
                                        style={{cursor: "pointer", fontSize: "20px"}}
                                        onClick={() => {
                                            if (index > 0) {
                                                onArrowClick({oldIndex: index, newIndex: index - 1})
                                            }
                                        }}
                                        className="mdi mdi-18px mdi-arrow-up"
                                    />
                                    <i
                                        style={{cursor: "pointer", fontSize: "20px"}}
                                        onClick={() => {
                                            if (index < items.length - 1) {
                                                onArrowClick({oldIndex: index, newIndex: index + 1})
                                            }
                                        }}
                                        className=" mdi mdi-18px mdi-arrow-down"
                                    />
                                </div>
                                <SortableItem key={`item-${index}`} editMode={editMode} index={index} value={value}/>
                                <span className="sortable-item-actions" key={index}>
                    <i
                        style={{cursor: "pointer", fontSize: "20px"}}
                        onClick={() => {
                            console.log("Question ID: ", value.id)
                            edit(value.id)
                            window.scrollTo({
                                top: 0,
                                behavior: "smooth",
                            })
                        }}
                        className=" fa fa-edit mr-1 text-secondary "
                    ></i>
                    <i
                        style={{cursor: "pointer", fontSize: "20px"}}
                        onClick={() => onDelete(value.id)}
                        className="mdi mdi-18px text-danger mdi-backspace"
                    ></i>
                  </span>
                            </Row>
                        ) : (
                            ""
                        )}
                    </Fragment>
                ))
                : ""}
        </ul>
    )
})

const SortableComponent = ({questions, deleteQuestion, editMode, editQuestion, setBoard}) => {
    const onSortEnd = ({oldIndex, newIndex}) => {
        const updated = arrayMove(questions, oldIndex, newIndex)
        setBoard(updated)
    }

    const onArrowClick = ({oldIndex, newIndex}) => {
        const updated = arrayMove(questions, oldIndex, newIndex)
        console.log({questions})
        console.log({updated})
        setBoard(updated)
    }

    return (
        <SortableList
            items={questions}
            onDelete={deleteQuestion}
            editMode={editMode}
            edit={editQuestion}
            onArrowClick={onArrowClick}
            onSortEnd={onSortEnd}
        />
    )
}

export default SortableComponent
