import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, Col, Media, ModalBody, Row } from 'reactstrap'
import avatar1 from "./../../../images/default.svg"

const ProfileDataModal = ({ toggle, candidate, position }) => {
  return (
    <ModalBody>
      <Row className="justify-content-end">
        <Col lg={3}>
          <button className="float-right btn btn-link-secondary" onClick={() => toggle()}>close</button>
        </Col>
      </Row>
      <Row className="align-items-center mb-4">
        <Col sm="4">
          <div className="avatar-xxl mt-4">
            <img
              src={candidate?.image || avatar1}
              alt=""
              className="img-thumbnail rounded-circle"
            />
          </div>
        </Col>
        <Col sm="8">
          <div className="mt-4">
            <h5>{candidate?.first_name} {candidate?.last_name}</h5>
            <span className="text-subtitle mb-2">Position - {position?.title}</span>
            <span className="d-block mb-1"> <i className="dripicons-mail d-inline-block mr-1"></i> {candidate?.email}</span>
            <span className="d-block mb-1"> <i className="dripicons-phone d-inline-block mr-1"></i> {candidate?.phone}</span>
            { candidate?.CandidateProfile?.pdf_resume ? <a href={candidate?.CandidateProfile?.pdf_resume} target="_blank" className="btn btn-outline-primary mt-2">
              <i className="bx bx-file mr-1"></i> PDF Resume
            </a> : "" }
          </div>
        </Col>
      </Row>
      {
        candidate?.CandidateProfile?.video_resume ? <><hr />
        <Row className="mb-2">
          <Col lg={12} className="text-center">
            <h5 className="mb-2">Video Resume</h5>
            <span className="mb-2 d-block">Watch 90 Seconds Video Resume</span>
            <video src={candidate?.CandidateProfile?.video_resume} controls width="100%" />
          </Col>
        </Row></> : ""
      }
      <Row className="justify-content-center">
        <Col lg={6}>
          <button className="btn btn-primary btn-block" onClick={() => toggle()}>Return</button>
        </Col>
      </Row>
    </ModalBody>
  )
}

export default ProfileDataModal