import React, {useEffect, useRef, useState} from "react";
import {CardBody, Card, CardSubtitle, CardTitle, Label} from "reactstrap";
import {OverlayTrigger, Tooltip, Modal, Col, Row, Spinner} from "react-bootstrap";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "../positions.css";
import MediaLibrary from "./PositionMedia";
import DeletePrompt from "../../Common/DeletePrompt";
import Alert from "./../../Common/Alert"
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {
    getCompanyPositionAction,
    addCompanyPositionAction,
    getCompanyMediaAction,
    updateCompanyPositionAction
} from "../../../../store/actions/CompanyActions";
import {
    getCompanyMedia,
    getCompanyCurrentPosition,
    getCompanySubscription,
    getMeiaAddedMessage
} from "../../../../store/selectors/CompanySelector";
import moment from "moment";
import VideoThumbnail from "react-video-thumbnail";

const PositionsDetails = (props) => {
    const {getSubscription, modeOn, setPositionData, getMessage} = props
    let errorsObj = {title: "", location: "", cDate: "", eDate: ""}
    const [errors, setErrors] = useState(errorsObj)
    const [title, setTitle] = useState("")
    const [location, setLocation] = useState("")
    const [cDate, setCDate] = useState(null)
    const [eDate, setEDate] = useState(moment().format("yyyy-MM-DD"))
    const [intro, setIntro] = useState("")
    const [outro, setOutro] = useState("")
    const [dis, setDis] = useState("")
    const [introVideo, setIntroVideo] = useState(undefined)
    const [outroVideo, setOutroVideo] = useState(undefined)
    const [mediaModal, setMediaModal] = useState({id: undefined, show: false})
    const [deleteModal, setDeleteModal] = useState({show: false, func: undefined})
    const [loader, setLoader] = useState(false)
    const [mediaLoader, setMediaLoader] = useState(false)

    const renderTooltip = (props, text) => {
        return (
            <Tooltip style={{width: "300px"}} {...props}>
                {text}
            </Tooltip>
        )
    }

    useEffect(() => {
        if (props.modeOn === "edit") {
            const pos_id = String(props?.location?.pathname).split("/position/edit/")[1]
            const data = {
                position_id: pos_id
            }
            console.log(data)
            props.getCompanyPosition(data, props)
        }
    }, [])

    useEffect(() => {
        !mediaLoader && props.loadCompanyMedia({}, props)
    }, [mediaLoader])

    const onsubmit = (e, v) => {

        let error = false
        const errorObj = {...errorsObj}
        if (title === "") {
            errorObj.title = "Position Title is Required"
            error = true
        } else {
            errorObj.title = ""
        }
        if (location === "") {
            errorObj.location = "Location is Required"
            error = true
        } else {
            errorObj.location = ""
        }
        // if (cDate === "") {
        //   errorObj.cDate = "Closing Date is Required"
        //   error = true
        // }
        // if (eDate === "") {
        //   errorObj.eDate = "Closing Date is Required"
        //   error = true
        // } else { rrorObj.eDate = "" }
        setErrors(errorObj)
        if (error) {
            return
        }
        const data = {
            position_data: {
                title: title,
                // status: "draft",
                intro_message: intro,
                outro_message: outro,
                outro_video: outroVideo?.id ? outroVideo?.id : "removed",
                intro_video: introVideo?.id ? introVideo?.id : "removed",
                description: dis,
                location: location,
                closing_date: cDate,
                expire_date: eDate
            }
        }


        if (props?.modeOn === "edit") {
            setLoader(true)
            data.position_id = props?.getCurrentPosition?.id
            console.log(data)
            props.updateCompanyPosition(data, props, () => {
                props.showAlert()
                setLoader(false)
            })
        }
        if (props?.modeOn === "add") {
            data.position_data.status = "draft";
            setLoader(true)
            props.addCompanyPosition(data, props, () => {
                props.showAlert()
                setLoader(false)
            })
        }
    }
    useEffect(() => {
        const positions = props?.getCurrentPosition
        if (props?.modeOn === "edit") {
            setTitle(positions?.title)
            setLocation(positions?.location)
            setCDate(positions?.closing_date)
            setEDate(positions?.expire_date)
            setIntro(positions?.intro_message)
            setOutro(positions?.outro_message)
            setDis(positions?.description)
            setIntroVideo(positions?.intro_video)
            setOutroVideo(positions?.outro_video)
        }
    }, [props.getCurrentPosition])

    useEffect(() => {
        const positions = props?.getCurrentPosition
        props?.modeOn === "add" && !loader && !getMessage?.error && positions && props.nextTab()
    }, [loader])

    const handleIntro = file => {
        // TODO:  for Edit
        // if (position) changeIntro(file)
        // else
        setIntroVideo(file)
    }

    const handleOutro = file => {
        // TODO:  for Edit
        // if (position) changeOutro(file)
        // else
        setOutroVideo(file)
    }

    return (
        <>
            <Card>
                <CardBody>
                    <CardTitle>{props?.modeOn} Position Details</CardTitle>
                    <CardSubtitle className="mb-3">
                        Fill out all the required fields below!
                    </CardSubtitle>
                    <hr/>
                    <Row>
                        <Col lg={6}>
                            <div className="form-group" style={{height: "100px"}}>
                                <Label> Position Title{" "} <span
                                    className="text-muted"> <small>Required</small> </span> </Label>
                                <input name="title" className="form-control" placeholder="Enter Title" type="text"
                                       value={title}
                                       onChange={(e) => setTitle(e.target.value)} required/>
                                {errors.title &&
                                    <div className="animated fadeInUp text-danger fs-12"
                                         style={{marginTop: 5}}>{errors.title}</div>}
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="form-group" style={{height: "100px"}}>
                                <Label> Location{" "} <span className="text-muted"> <small>Required</small> </span>
                                </Label>
                                <input name="location" className="form-control" placeholder="Enter Location" type="text"
                                       value={location} onChange={(e) => setLocation(e.target.value)}/>
                                {errors.location &&
                                    <div className="animated fadeInUp text-danger fs-12"
                                         style={{marginTop: 5}}>{errors.location}</div>}
                            </div>
                        </Col>
                        <Col lg={3} className="d-none">
                            <div className="form-group" style={{height: "100px"}}>
                                <Label> Expiry Date{" "} <span className="text-muted"> <small>Optional</small></span>
                                </Label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker autoOk clearable format="yyyy-MM-dd" disablePast
                                                className="form-control" value={eDate}
                                                onChange={setEDate} error={(errors?.eDate)} helperText={errors.eDate}/>
                                </MuiPickersUtilsProvider>
                            </div>
                        </Col>

                        <Col lg={3}>
                            <div className="form-group" style={{height: "100px"}}>
                                <Label> Closing Date{" "} <span className="text-muted">
                  {/* <small>Required</small> */}
                </span> </Label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker autoOk clearable format="yyyy-MM-dd" disablePast
                                                className="form-control" value={cDate}
                                                onChange={(value) => setCDate(value)} error={(errors?.cDate)}
                                                helperText={errors.cDate}/>
                                </MuiPickersUtilsProvider>
                            </div>
                        </Col>


                        <Col lg={6}>
                            <div className="form-group">
                                <Label> Intro Message{" "} <span className="text-muted">
                  <OverlayTrigger
                      placement="bottom"
                      overlay={props => renderTooltip(props, `Text displayed to candidates BEFORE they start the interview`)}>
                    <i className="bx bxs-info-circle mr-1 text-primary align-middle"></i>
                  </OverlayTrigger>
                  <small>Optional</small>
                  </span>
                                </Label>
                                <textarea className="form-control" placeholder="Opening Message" value={intro}
                                          onChange={(e) => setIntro(e.target.value)}/>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="form-group">
                                <Label> Outro Message{" "} <span className="text-muted">
                  <OverlayTrigger
                      placement="bottom"
                      overlay={props => renderTooltip(props, `Text displayed to candidates AFTER they complete the interview`)}>
                    <i className="bx bxs-info-circle mr-1 text-primary align-middle"></i>
                  </OverlayTrigger>
                  <small>Optional</small>
                  </span>
                                </Label>
                                <textarea className="form-control" placeholder="Closing Message" value={outro}
                                          onChange={(e) => setOutro(e.target.value)}/>
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="form-group">
                                <Label> Description{" "} <span className="text-muted">
                  <small>Optional</small>
                  </span>
                                </Label>
                                <textarea className="form-control" placeholder="Job description" value={dis}
                                          onChange={(e) => setDis(e.target.value)}/>
                            </div>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col lg={6}>
                            <Row className="ml-1">
                                <h5>Intro Video</h5>
                                <small className="ml-2 mt-1 text-muted">Optional</small>
                            </Row>
                            <span className="text-subtitle d-block mb-2">
                Add a video to the interview introduction screen
              </span>
                            {introVideo ? (
                                    <Row className="align-items-center">
                                        <Col lg={4}>
                                            <div style={{
                                                maxHeight: "130px",
                                                maxWidth: "220px",
                                                overflow: "hidden",
                                                borderRadius: "15px"
                                            }}>
                                                {introVideo?.type === "video" ? (

                                                    introVideo?.thumbnail ?
                                                        <img src={introVideo?.thumbnail}
                                                             className="img-fluid"
                                                             width="100%"
                                                        /> : <VideoThumbnail
                                                            videoUrl={introVideo?.url}
                                                            thumbnailHandler={(thumb) => console.log(thumb)}
                                                            height={130}
                                                            width={220}
                                                        />


                                                ) : (
                                                    <img src={introVideo?.url}
                                                         className="img-fluid"
                                                         width="100%"
                                                    />
                                                )}
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div>{introVideo?.name}</div>
                                            <div>
                                                <button type="button" className="btn btn-link text-primary"
                                                        onClick={() =>
                                                            setMediaModal({
                                                                id: "intro",
                                                                show: true
                                                            })
                                                        }
                                                >
                                                    Replace
                                                </button>
                                                <button
                                                    type="button" className="btn btn-link text-danger"
                                                    onClick={() => setDeleteModal({
                                                        func: () => {
                                                            handleIntro("removed")
                                                            setDeleteModal(undefined)
                                                        },
                                                        show: true
                                                    })
                                                    }
                                                >
                                                    Remove
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                ) :
                                <div className="dz-message needsclick filesUpload mt-2" style={{border: "dotted"}}
                                     onClick={() => setMediaModal({id: "intro", show: true})}>
                                    <div>
                                        <i className="display-4 fa fa-cloud-upload"/>
                                    </div>
                                    <h5>Open Media Libary</h5>
                                </div>
                            }
                        </Col>
                        <Col lg={6}>
                            <Row className="ml-1">
                                <h5>Outro Video</h5>
                                <small className="ml-2 mt-1 text-muted">Optional</small>
                            </Row>
                            <span className="text-subtitle d-block mb-2">
                Add a video to the interview conclusion screen
              </span>
                            {outroVideo ? (
                                    <Row className="align-items-center">
                                        <Col lg={4}>
                                            <div style={{
                                                maxHeight: "130px",
                                                maxWidth: "220px",
                                                overflow: "hidden",
                                                borderRadius: "15px"
                                            }}>
                                                {outroVideo?.type === "video" ? (
                                                    <img
                                                        // src={outro?.thumbnail || position?.outro?.video?.thumbnail}
                                                        className="img-fluid" width="100%"
                                                    />
                                                ) : (
                                                    <img src={outroVideo?.url} className="img-fluid" width="100%"/>
                                                )}
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div>{outroVideo?.name}</div>
                                            <div>
                                                <button type="button" className="btn btn-link text-primary"
                                                        onClick={() => setMediaModal({id: "outro", show: true})}
                                                >
                                                    Replace
                                                </button>
                                                <button type="button" className="btn btn-link text-danger"
                                                        onClick={() => {
                                                            setDeleteModal({
                                                                func: () => {
                                                                    handleOutro("removed")
                                                                    setDeleteModal(undefined)
                                                                },
                                                                show: true
                                                            })
                                                        }}
                                                >
                                                    Remove
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                ) :
                                <div className="dz-message needsclick filesUpload mt-2" style={{border: "dotted"}}
                                     onClick={() => setMediaModal({id: "outro", show: true})}>
                                    <div>
                                        <i className="display-4 fa fa-cloud-upload"/>
                                    </div>
                                    <h5>Open Media Library</h5>
                                </div>
                            }
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <Modal
                centered={true}
                size="lg"
                media={mediaModal}
                show={mediaModal.show}
                onHide={() => {
                    setMediaModal({id: undefined, show: false})
                }
                }>
                <MediaLibrary
                    media={mediaModal}
                    setFile={mediaModal.id === "intro" ? handleIntro : handleOutro}
                    listmedia={props?.listmedia}
                    setLoader={(bol) => setMediaLoader(bol)}
                    loader={mediaLoader}
                    toggle={() => {
                        setMediaModal({id: undefined, show: false})
                    }}
                />
            </Modal>
            <Modal
                centered={true}
                // size="lg"
                show={deleteModal?.show}
                onHide={() => {
                    setDeleteModal({func: undefined, show: false})
                }}
            >
                <DeletePrompt
                    title={"Are you sure you want to remove media file?"}
                    btnText={"Remove"}
                    func={() => deleteModal?.func()}
                    toggle={() => {
                        setDeleteModal({func: undefined, show: false})
                    }}
                />
            </Modal>
            <Row className="my-4">
                <Col sm="6"></Col>
                <Col></Col>
                <Col sm="4">
                    <div className="text-sm-right">
                        <button
                            className={`btn btn-${getSubscription?.limit?.positions?.allowed === getSubscription?.limit?.positions?.used && modeOn === "add" ? "danger" : "primary"} btn-block`}
                            type="button"
                            onClick={onsubmit}
                            disabled={(getSubscription?.limit?.positions?.allowed === getSubscription?.limit?.positions?.used && modeOn === "add") || loader}
                        >
                            {loader ?
                                <Spinner variant="mutted" animation="border"/>
                                :
                                props?.modeOn === "add" ? (getSubscription?.limit?.positions?.allowed === getSubscription?.limit?.positions ? "Limit Reached" : "Add Position") : "Update Postion"
                            }
                        </button>
                    </div>
                </Col>
            </Row>
        </>
    )
}


const mapStateToProps = (state) => {
    return {
        listmedia: getCompanyMedia(state),
        getCurrentPosition: getCompanyCurrentPosition(state),
        getSubscription: getCompanySubscription(state),
        getMessage: getMeiaAddedMessage(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCompanyPosition: (data, props, cb) => dispatch(getCompanyPositionAction(data, props.history, cb)),
        addCompanyPosition: (data, props, cb) => dispatch(addCompanyPositionAction(data, props.history, cb)),
        updateCompanyPosition: (data, props, cb) => dispatch(updateCompanyPositionAction(data, props.history, cb)),
        loadCompanyMedia: (data, props, cb) => dispatch(getCompanyMediaAction(data, props.history, cb))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PositionsDetails))

