import React, {useState, useEffect} from "react"
import {connect, useDispatch, useSelector} from "react-redux"
import {Link, useHistory} from "react-router-dom"
import {loadingToggleAction, loginAction} from "../../store/actions/AuthActions"
import Loader from "../components/Loader"
//
import './login.css'
import LogoDark from "../../images/logo-dark.svg"
import login from "../../images/login-left-new.svg"
import loginbg from "../../images/bg-login.jpg"
//import { ReactComponent as LinkedInIcon } from "../../images/svg/linked-in.svg"
import GoogleButton from "../components/Common/GoogleButton"

function Login(props) {
    // console.log(props.showLoading);
    const [email, setEmail] = useState("")
    let errorsObj = {email: "", password: ""}
    const [errors, setErrors] = useState(errorsObj)
    const [password, setPassword] = useState("")
    const [alert, setAlert] = useState("")
    const dispatch = useDispatch()
    const history = useHistory()
    const AuthDataLoading = useSelector((state) => state.auth.AuthDataLoading)

    function showMessage(msg) {
        if (msg) {
            setAlert(true)
            setTimeout(() => setAlert(false), 30000)
        }
    }

    function onLogin(e) {
        e.preventDefault()
        let error = false
        const errorObj = {...errorsObj}
        if (email === "") {
            errorObj.email = "Email is Required"
            error = true
        }
        if (password === "") {
            errorObj.password = "Password is Required"
            error = true
        }
        setErrors(errorObj)
        if (error) {
            return
        }
        dispatch(loadingToggleAction(true))
        dispatch(loginAction(email, password, history, (msg) => showMessage(msg)))
    }

    return (
        <div className="login-main-page">
            <div className="login-wrapper">
                <div className="login-aside-left sidenav bg-img">
                    <Link to="/dashboard" className="login-logo">
                        <img src={LogoDark} alt="" className=""/>
                    </Link>
                    <div className="login-description">
                        <h2 className="text-black  mb-3">Welcome</h2>
                        <p className="fs-12 text-dark">
                            DigitalHire lets you automate your hiring process so you can spend more time focusing on
                            qualified
                            candidates.
                        </p>
                        <ul className="social-icons mt-3">
                            <li>
                                <a href="https://www.facebook.com/DigitalHireInc/" target="_blank">
                                    <i className="fa fa-facebook"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com/digitalhireinc" target="_blank">
                                    <i className="fa fa-twitter"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/company/digital-hire-inc/" target="_blank">
                                    <i className="fa fa-linkedin"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/digitalhireinc/" target="_blank">
                                    <i className="fa fa-instagram"></i>
                                </a>
                            </li>
                        </ul>
                        <div className="mt-3">
                            <a href="https://digitalhire.com/privacy-policy/" className="text-black mr-4"
                               target="_blank">
                                Privacy Policy
                            </a>
                            <a href="https://digitalhire.com/terms-of-service/" className="text-black mr-4"
                               target="_blank">
                                Terms & Conditions
                            </a>
                            <Link to={"#"} className="text-black">
                                © {new Date().getFullYear()} DigitalHire
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="login-aside-right">
                    <div className="row m-0 justify-content-center h-100 align-items-center">
                        <div className="col-xl-7 col-xxl-7">
                            <div className="authincation-content">
                                <div className="row no-gutters">
                                    <div className="col-xl-12">
                                        <div className="auth-form-1">
                                            <div className="mb-4">
                                                <h3 className="text-white mb-1">Employer's Dashboard</h3>
                                                <p className="">Sign in by entering information below</p>
                                            </div>
                                            {props.errorMessage && alert && (
                                                <div
                                                    className="animated fadeInUp text-danger fs-15 text-center">{props.errorMessage}</div>
                                            )}
                                            {/* {props.successMessage && (
                                      <div className="animated fadeInUp fs-15 text-center">{props.successMessage}</div>
                                    )} */}
                                            <form onSubmit={onLogin}>
                                                <div className="form-group mb-3">
                                                    <label className="mb-2 ">
                                                        <strong>Email</strong>
                                                    </label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        value={email}
                                                        placeholder="Enter Email"
                                                        onChange={(e) => setEmail(e.target.value)}
                                                    />
                                                    {errors.email &&
                                                        <div className="text-danger fs-12">{errors.email}</div>}
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label className="mb-2 ">
                                                        <strong>Password</strong>
                                                    </label>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        value={password}
                                                        placeholder="Enter Password"
                                                        onChange={(e) => setPassword(e.target.value)}
                                                    />
                                                    {errors.password &&
                                                        <div className="text-danger fs-12">{errors.password}</div>}
                                                </div>
                                                <p
                                                    onClick={() => {
                                                        history.push("/forgot-password")
                                                    }}
                                                    style={{
                                                        color: "#b62b2b",
                                                        marginTop: "-12px",
                                                        marginBottom: "-11px",
                                                        cursor: "pointer"
                                                    }}>forgot password?</p>
                                                <div className="form-row d-flex justify-content-between mt-4 mb-2">
                                                    <div className="form-group">
                                                        <div className="custom-control custom-checkbox ml-1 ">
                                                            <input type="checkbox" className="custom-control-input mr-2"
                                                                   id="basic_checkbox_1"/>
                                                            <label
                                                                className="custom-control-label"
                                                                htmlFor="basic_checkbox_1"
                                                                style={{paddingTop: "0.25rem"}}
                                                            >
                                                                Remember my preference
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-center">
                                                    <button disabled={props.showLoading} type="submit"
                                                            className="btn btn-primary btn-block">
                                                        {AuthDataLoading ?
                                                            <Loader spinnerClass="text-white"/> : "Sign In"}
                                                    </button>
                                                </div>
                                            </form>
                                            <div className="mt-4">
                                                <GoogleButton/>
                                                {/* <div className="d-flex align-items-center mb-2">
                          <button className="btn btn-block btn-info font-bold">
                            <LinkedInIcon width="20" height="20" />
                            &nbsp; Continue with LinkedIn
                          </button>
                        </div> */}
                                            </div>
                                            <div className="new-account mt-2">
                                                <p className="">
                                                    Don't have an account?{" "}
                                                    <Link className="text-primary"
                                                          to={{pathname: "./register", account: "new"}}>
                                                        Sign up
                                                    </Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    }
}
export default connect(mapStateToProps)(Login)
