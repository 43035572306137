export default {
    ACCOUNT_SETUP: {
        name: 'Account Setup',
        id: 1
    },
    VERIFICATION: {
        name: 'Verification',
        id: 2
    },
    PROFILE: {
        name: 'Profile',
        id: 3
    },
    COMPLETE: {
        name: 'Complete',
        id: 4
    }
};