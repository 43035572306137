import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {connect, useSelector} from "react-redux";
import {Modal} from "react-bootstrap";
import {Spinner} from "reactstrap";
import Select from 'react-select';

import avatar from "../../../images/default.svg";

import Alert from "./../Common/Alert";
import {verifyAction} from "../../../store/actions/AuthActions";
import {uploadMediaToAWSAction} from "../../../store/actions/MediaActions";
import {
    registerCompanyAction,
    getCompanyIndustriesAction,
    getCompanyCountriesAction
} from "../../../store/actions/CompanyActions";
import ImageCropModal from "../Common/ImageCropModal";
import {renderIndustries, renderCountries} from "../Common/commonFunctions";
import {getCompanyCountries, getCompanyIndustries} from "../../../store/selectors/CompanySelector";

const StepThree = (props) => {
    let errorsObj = {
        img: "",
        indus: "",
        cname: "",
        cEmail: "",
        addOne: "",
        city: "",
        cState: "",
        country: "",
        postal: ""
    }
    const [errors, setErrors] = useState(errorsObj)
    const [indus, setIndus] = useState("")
    const [cname, setCname] = useState("")
    const [cEmail, setCemail] = useState("")
    const [site, setSite] = useState("")
    const [addOne, setAddOne] = useState("")
    const [addTwo, setAddTwo] = useState("")
    const [city, setCity] = useState("")
    const [cState, setCState] = useState("")
    const [country, setCountry] = useState("")
    const [postal, setPostal] = useState("")
    const [img, setImg] = useState(undefined)
    const [uploadFile, setUploadFile] = useState(undefined)
    const [preImg, setPreImg] = useState(undefined)
    const [cropModal, setCropModal] = useState(false)
    const [alert, showAlert] = useState(false)
    const [loader, setLoader] = useState(false)
    const state = useSelector((state) => state)

    console.log(alert)

    const hiddenFileInput = React.useRef(null)

    useEffect(() => {
        props.getCountries()
        props.getIndustries()
    }, [])

    const {countries, industries} = useSelector(state1 => ({
        countries: getCompanyCountries(state1),
        industries: getCompanyIndustries(state1)

    }))

    const handleChange = event => {
        if (event.target.files[0]) {
            const fileUploaded = event.target.files[0]
            setImg(URL.createObjectURL(fileUploaded))
            setTimeout(() => {
                setCropModal(true)
            }, 300)
        }
    }

    const handleCropSubmit = file => {
        // upload image from here this is the fimal image after croping
        const media_key = "company/" + file.lastModified + "." + file.type.split("/")[1]
        setImg(media_key)
        setUploadFile(file)
        setPreImg(URL.createObjectURL(file))
        setCropModal(false)
    }

    // console.log(img)

    function onSubmit(e) {
        e.preventDefault()
        let error = false
        const errorObj = {...errorsObj}
        if (img === "" || img === undefined) {
            errorObj.img = "Image is Required"
            error = true
        }
        if (indus === "") {
            errorObj.indus = "Industry is Required"
            error = true
        }
        if (cname === "") {
            errorObj.cname = "Company Name is Required"
            error = true
        }
        if (cEmail === "") {
            errorObj.cEmail = "Company Email is Required"
            error = true
        }
        if (addOne === "") {
            errorObj.addOne = "Address is Required"
            error = true
        }
        if (city === "") {
            errorObj.city = "City is Required"
            error = true
        }
        if (cState === "") {
            errorObj.cState = "State is Required"
            error = true
        }
        if (country === "") {
            errorObj.country = "Country is Required"
            error = true
        }
        if (postal === "") {
            errorObj.postal = "Postal Code is Required"
            error = true
        }
        setErrors(errorObj)
        if (error) {
            return
        }

        const data = {
            name: cname,
            industry: indus.value,
            email: cEmail,
            logo: img,
            website: site,
            address: addOne,
            address2: addTwo,
            city: city,
            state: cState,
            country: country,
            postal_code: postal
        }
        console.log("DATA: FOR COMPANY PROFILE")
        console.log(img, uploadFile)
        setLoader(true)
        props.uploadMediaToAWS({
                media_key: img,
                file: uploadFile
            }, props, () => props.registerCompany(data, props, () => {
                setLoader(false)
                showAlert(true);
            })
        )

    }

    return (
        <section>
            <form onSubmit={onSubmit} style={{marginTop: 10}}>
                {state?.company?.errorMessage && alert &&
                    <Alert alert={alert} type={"error"} variant={"danger"} msg={state?.company?.errorMessage}
                           dismissAlert={() => showAlert(false)}/>}
                <div className="row align-items-center">
                    <div className={"col-lg-2 col-sm-4 mb-2 align-items-center"}>
                        <img src={preImg || avatar} width="120"
                             className="img-fluid rounded-circle" alt=""/>
                    </div>
                    <div className={"col-8"}>
                        <div className="">
                            <h3>Upload your logo</h3>
                            <span className="d-block">Maximum Size Allowed 2MB | JPEG, JPG or PNG</span>
                            <div className="custom-file my-2" style={{cursor: 'pointer'}}>
                                <input type="file" className="custom-file-input" accept="image/*" ref={hiddenFileInput}
                                       onChange={(e) => handleChange(e)}/>
                                <label className="custom-file-label-cancel btn btn-primary ">Choose file</label>
                            </div>
                            {errors.img && <div className="animated fadeInUp text-danger fs-12">{errors.img}</div>}
                        </div>
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-lg-6 mb-2">
                        <div className={"form-group"}>
                            <label className="text-label">
                                Industry{" "}
                                <span className="text-muted">
                              <small>Required</small>
                           </span>
                            </label>
                            <Select
                                placeholder={'Select industry'}
                                value={indus}
                                onChange={setIndus}
                                options={renderIndustries(industries)}
                            />
                            {/* <select defaultValue={indus} className="form-control" onChange={(e) => setIndus(e.target.value)}>
                {industries.length > 0 ? renderIndustries(industries) :
                  <option value="" disabled>No data Found</option>}
              </select> */}
                            {errors.indus && <div className="animated fadeInUp text-danger fs-12">{errors.indus}</div>}
                        </div>
                    </div>
                    <div className="col-lg-6 mb-2">
                        <div className={"form-group"}>
                            <label className="text-label">
                                Company Name{" "}
                                <span className="text-muted">
                           <small>Required</small>
                        </span>
                            </label>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter a company name.."
                                    onChange={(e) => setCname(e.target.value)}
                                    value={cname}
                                />
                                {errors.cname &&
                                    <div className="animated fadeInUp text-danger fs-12">{errors.cname}</div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 mb-2">
                        <div className={"form-group"}>
                            <label className="text-label">
                                Company Email{" "}
                                <span className="text-muted">
                              <small>Required</small>
                           </span>
                            </label>
                            <input
                                type="email"
                                className="form-control"
                                placeholder="email@company.com"
                                onChange={(e) => setCemail(e.target.value)}
                                value={cEmail}
                            />
                            {errors.cEmail &&
                                <div className="animated fadeInUp text-danger fs-12">{errors.cEmail}</div>}
                        </div>
                    </div>
                    <div className="col-lg-6 mb-2">
                        <div className={"form-group"}>
                            <label className="text-label">Website</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="www.yourCompany.com"
                                onChange={(e) => setSite(e.target.value)}
                                value={site}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 mb-2">
                        <div className={"form-group"}>
                            <label className="text-label">
                                Address{" "}
                                <span className="text-muted">
                              <small>Required</small>
                           </span>
                            </label>
                            <div className="input-group">
                                <input
                                    type={"text"}
                                    className="form-control"
                                    id="val-address1"
                                    name="address"
                                    onChange={(e) => setAddOne(e.target.value)}
                                    value={addOne}
                                    placeholder="Address line 1"
                                />
                            </div>
                            {errors.addOne &&
                                <div className="animated fadeInUp text-danger fs-12">{errors.addOne}</div>}
                        </div>
                    </div>
                    <div className="col-lg-6 mb-2">
                        <div className={"form-group"}>
                            <label className="text-label">
                                Address Line 2{" "}
                                <span className="text-muted">
                              <small>Optional</small>
                           </span>
                            </label>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Address line 2"
                                    onChange={(e) => setAddTwo(e.target.value)}
                                    value={addTwo}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 mb-2">
                        <div className={"form-group"}>
                            <label className="text-label">
                                City{" "}
                                <span className="text-muted">
                              <small>Required</small>
                           </span>
                            </label>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter city name"
                                    onChange={(e) => setCity(e.target.value)}
                                    value={city}
                                />
                                {errors.city &&
                                    <div className="animated fadeInUp text-danger fs-12">{errors.city}</div>}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-2">
                        <div className={"form-group"}>
                            <label className="text-label">
                                State{" "}
                                <span className="text-muted">
                              <small>Required</small>
                           </span>
                            </label>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="State"
                                    onChange={(e) => setCState(e.target.value)}
                                    value={cState}
                                />
                                {errors.cState &&
                                    <div className="animated fadeInUp text-danger fs-12">{errors.cState}</div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 mb-2">
                        <div className={"form-group"}>
                            <label className="text-label">
                                Country{" "}
                                <span className="text-muted">
                              <small>Required</small>
                           </span>
                            </label>
                            <div className="form-group">

                                <select defaultValue={country} className="form-control"
                                        onChange={(e) => setCountry(e.target.value)}>
                                    {countries.length > 0 ? renderCountries(countries) :
                                        <option value="" disabled>No data Found</option>}
                                </select>
                                {errors.country &&
                                    <div className="animated fadeInUp text-danger fs-12">{errors.country}</div>}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-2">
                        <div className={"form-group"}>
                            <label className="text-label">
                                Postal Code{" "}
                                <span className="text-muted">
                              <small>Required</small>
                           </span>
                            </label>
                            <div className="form-group">
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Postal code"
                                    onChange={(e) => setPostal(e.target.value)}
                                    value={postal}
                                />
                                {errors.postal &&
                                    <div className="animated fadeInUp text-danger fs-12">{errors.postal}</div>}
                            </div>
                        </div>
                    </div>
                </div>

                <button
                    type="submit"
                    className="btn mr-2 btn-primary"
                    disabled={loader}
                >
                    {loader ? (
                        <Spinner
                            size="sm"
                            className="m-auto text-center"
                            color="light"
                        />
                    ) : ("Submit")
                    }
                </button>
            </form>

            <Modal centered={true} size="lg" show={cropModal} onHide={() => {
                setCropModal(!cropModal)
            }}>
                <ImageCropModal handleCropSubmit={handleCropSubmit} img={img} toggle={() => {
                    setCropModal(!cropModal)
                }}/>
            </Modal>
        </section>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        registerCompany: (data, props, cb) => dispatch(registerCompanyAction(data, props.history, cb)),
        uploadMediaToAWS: (data, props, cb) => dispatch(uploadMediaToAWSAction(data, props.history, cb)),
        getCountries: () => dispatch(getCompanyCountriesAction()),
        getIndustries: () => dispatch(getCompanyIndustriesAction())
    }
}
export default withRouter(connect(null, mapDispatchToProps)(StepThree))
