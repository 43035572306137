import ProfileImage from "../Common/ProfileImage"
import React from "react"
import {Link} from "react-router-dom"
import {Col, Row} from "reactstrap"

import companyProfile from "../../../images/svg/default.svg"

const SharePosition = ({company, toggle, position}) => {
    console.log("POSITION DATA:", company)
    return (
        <div>
            <div className="modal-body">
                <Row className="align-items-center justify-content-center">
                    <Col lg={3}>
                        <ProfileImage image={company?.logo || companyProfile}/>
                    </Col>
                    <Col lg={9}>
                        <div className="text-subtitle">
                            <h5>{company?.name}</h5>
                            <div>Share Interview Link</div>
                        </div>
                    </Col>
                </Row>
                <hr/>
                <Row className="align-items-center justify-content-center">
                    <Col lg={3}>
                        <i className="fa fa-share share-icon fa-5x mr-2" style={{color: "#00BA52"}}></i>
                    </Col>
                    <Col lg={9}>
                        <div className="text-subtitle">
                            <h5>Share Interview Link</h5>
                            <div>Anyone with this link will be able to take an interview for this position.</div>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col lg={12}>
                        {/* TODO:   create link to display  */}
                        <input className="form-control" disabled={true} width="100%"
                               value={`https://app.digitalhire.com/interview/${position?.id}`}/>
                    </Col>
                </Row>
                <hr/>
                <Row className="justify-content-center">
                    <Col lg={6}>
                        <button type="button" className="btn btn-block btn-outline-secondary"
                                onClick={() => toggle()}>Return
                        </button>
                    </Col>
                    <Col lg={6}>
                        <button
                            /* TODO:   create link to copy   style={{paddingInline:'50px'}}  */
                            onClick={() => {
                                navigator.clipboard.writeText(`https://app.digitalhire.com/interview/${position?.id}`)
                            }}
                            className="btn btn-block btn-primary">Copy to Clipboard
                        </button>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default SharePosition