export const getCompanyReportSelector = (state) => {
  if (state?.report?.reportData) return state?.report?.reportData
  return false
}
export const getCompanyReportLoadingSelector = (state) => {
  if (state?.report?.reportLoading) return state?.report?.reportLoading
  return false
}
export const getCompanyReportMembersSelector = (state) => {
  if (state?.report?.reportMembersData) return state?.report?.reportMembersData
  return false
}
export const getCompanyReportMembersLoadingSelector = (state) => {
  if (state?.report?.reportMembersLoading) return state?.report?.reportMembersLoading
  return false
}
export const getCompanyReportPositionsSelector = (state) => {
  if (state?.report?.reportPositionsData) return state?.report?.reportPositionsData
  return false
}
export const getCompanyReportPositionsLoadingSelector = (state) => {
  if (state?.report?.reportPositionsLoading) return state?.report?.reportPositionsLoading
  return false
}
export const getCompanyReportPositionSelector = (state) => {
  if (state?.report?.reportPositionData) return state?.report?.reportPositionData
  return false
}
export const getCompanyReportPositionLoadingSelector = (state) => {
  if (state?.report?.reportPositionLoading) return state?.report?.reportPositionLoading
  return false
}
