import React, {useState, useEffect, useRef, Fragment} from "react";
import {Col, Row} from "reactstrap";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import pic1 from "./../../../images/default.svg";
import ActiveUsersChart from "./ActiveUsersChart";
import PositionCandidateGraph from "./../Common/graphs/PositionCandidateGraph";
import EvaluationDonutPGraph from "../Common/graphs/EvaluationDonutPGraph";
import ActiveBarGraph from "../Common/graphs/ActiveBarGraph";
import CandidatesBarGraph from "./CandidatesBarGraph";
import Slider from "react-slick";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";
import Loader from "../Loader";
import {
    getCompanyReportAction,
    getCompanyReportMembersAction,
    getCompanyReportPositionAction,
    getCompanyReportPositionsAction,
} from "../../../store/actions/ReportActions";
import {
    getCompanyReportSelector,
    getCompanyReportLoadingSelector,
    getCompanyReportMembersSelector,
    getCompanyReportMembersLoadingSelector,
    getCompanyReportPositionsSelector,
    getCompanyReportPositionsLoadingSelector,
    getCompanyReportPositionSelector,
    getCompanyReportPositionLoadingSelector,
} from "../../../store/selectors/ReportSelector";
import CountUp from "react-countup";

const AvgInterviewTimeGauge = loadable(() =>
    pMinDelay(import("../Common/graphs/AvgInterviewTimeGauge"), 1000)
);

const Index = (props) => {
    const [detail, setDetail] = useState(0);
    const [filterTitle, setFilterTitle] = useState("");
    const isMounted = useRef(false);
    console.log(props.reportPositionData, " reportPositionData");
    const settings = {
        dots: false,
        swipeToSlide: true,
        infinite: true,
        arrows: false,
        speed: 900,
        autoplay: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow:
                        props?.reportMembersData?.Company?.CompanyMembers?.length <= 5
                            ? props?.reportMembersData?.Company?.CompanyMembers?.length
                            : 5,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow:
                        props?.reportMembersData?.Company?.CompanyMembers?.length <= 4
                            ? props?.reportMembersData?.Company?.CompanyMembers?.length
                            : 4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 450,
                settings: {
                    slidesToShow:
                        props?.reportMembersData?.Company?.CompanyMembers?.length <= 3
                            ? props?.reportMembersData?.Company?.CompanyMembers?.length
                            : 3,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    useEffect(() => {
        props.loadCompanyReportAction();
        props.loadCompanyReportMembersAction();
        props.loadCompanyReportPositionsAction();
    }, []);

    useEffect(() => {
        if (filterTitle) {
            submitFilter();
            isMounted.current = true;
        } else {
            if (isMounted.current) {
                submitFilter();
                isMounted.current = false;
            }
        }
    }, [filterTitle]);

    const submitFilter = (page = 1, bol = false) => {
        const data = {
            // pagination: {
            //   page: page,
            //   size: 5,
            // },
        };
        if (filterTitle) {
            data.filter_options = {
                search_string: filterTitle,
                sort_array: [["createdAt", "DESC"]],
            };
        }
        console.log("Position List Data called", data);
        props.loadCompanyReportPositionsAction(data);
    };

    useEffect(() => {

        if (props?.reportPositionsData?.data?.length > 0) {
            props.loadCompanyReportPositionAction({
                position_id: props?.reportPositionsData?.data?.[0]?.id,
            });
        }

    }, [props?.reportPositionsData?.data && detail === 0])

    return (
        <>
            <div className="card candidate-filters border border-primary">
                <div className="card-header">
                    <h5 className="card-title">Summary - Active User Hours (GMT)</h5>
                </div>
                <div className=" card-body ">
                    <ActiveUsersChart/>
                </div>
            </div>
            <Row>
                <Col xl={6}>
                    <div className="card candidate-filters border border-primary">
                        <div className="card-header">
                            <h5 className="card-title">
                                Candidates
                            </h5>
                        </div>
                        <div className=" card-body ">
                            <div className="d-flex justify-content-center">
                                {props?.reportLoading && <Loader spinnerClass="text-green"/>}
                            </div>
                            <CandidatesBarGraph
                                data={props?.reportData?.candidate_detail_graph_data}
                            />
                        </div>
                    </div>
                </Col>
                <Col xl={6}>
                    <div className="card candidate-filters border border-primary">
                        <div className="card-header">
                            <h5 className="card-title">Traffic</h5>
                        </div>
                        <div className=" card-body">
                            <div className="d-flex justify-content-center">
                                {props?.reportLoading && <Loader spinnerClass="text-green"/>}
                            </div>
                            <ActiveBarGraph traffic_data={props?.reportData?.traffic_data}/>
                        </div>
                    </div>
                </Col>
            </Row>
            <div className="card candidate-filters border border-primary">
                <div className="card-header">
                    <h5 className="card-title">Positions</h5>
                </div>
                <div className=" card-body">
                    <Row>
                        <Col xl={3} className="position-col">
                            <div className="form-group">
                                <input
                                    className="form-control"
                                    value={filterTitle}
                                    onChange={(e) => setFilterTitle(e.target.value)}
                                    type="text"
                                    placeholder="Search Position Name"
                                />
                            </div>
                            {props?.reportPositionsLoading ? (
                                <Loader spinnerClass="text-green"/>
                            ) : (
                                <div
                                    className="custom-scroll custom-scroll-bg"
                                    style={{height: "320px"}}
                                >
                                    {props?.reportPositionsData?.data?.map((item, index) => {
                                        return (
                                            <div
                                                className={`position ${
                                                    detail === index && !filterTitle
                                                        ? `position-active`
                                                        : ``
                                                }`}
                                                onClick={() => {
                                                    setDetail(index);
                                                    props.loadCompanyReportPositionAction({
                                                        position_id: item.id,
                                                    });
                                                }}
                                                key={item?.id}
                                            >
                                                {item?.title}
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </Col>
                        <Col xl={9}>
                            {props?.reportPositionLoading && !props?.reportPositionsData?.position_interview_detail_graph_data ? (
                                <div className="d-flex justify-content-center">
                                    <Loader spinnerClass="text-green"/>
                                </div>
                            ) : (
                                <div>
                                    {/* <h4>Average Interview Time</h4> */}
                                    <Row>
                                        <Col xl={4}>
                                            <div className="card candidate-filters border border-primary">
                                                <div className="card-header">
                                                    <h5 className="card-title">Candidates</h5>
                                                </div>
                                                <div className=" card-body">

                                                    <PositionCandidateGraph
                                                        data={
                                                            props?.reportPositionData
                                                                ?.position_interview_detail_graph_data
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={4}>
                                            <div className="card candidate-filters border border-primary">
                                                <div className="card-header">
                                                    <h5 className="card-title">
                                                        Avg Interview Length
                                                    </h5>
                                                </div>
                                                <div className="card-body">
                                                    {/*{Math.round((props?.reportPositionData?.position_interview_detail_graph_data?.[0]?.avg_res ? props?.reportPositionData?.position_interview_detail_graph_data?.[0]?.avg_res : 0) * 100) / 100} min*/}
                                                    {/*<AvgInterviewTimeGauge data={*/}
                                                    {/*    props?.reportPositionData*/}
                                                    {/*        ?.position_interview_detail_graph_data*/}
                                                    {/*}/>*/}
                                                    <CountUp
                                                        start={0}
                                                        end={props?.reportPositionData?.position_interview_detail_graph_data?.[0]?.avg_res}
                                                        duration={2.75}
                                                        separator=" "
                                                        decimals={2}
                                                        decimal="."
                                                        suffix=" Minutes"
                                                    >
                                                    </CountUp>
                                                    {/*<EvaluationDonutPGraph*/}
                                                    {/*    data={*/}
                                                    {/*        props?.reportPositionData*/}
                                                    {/*            ?.position_interview_detail_graph_data*/}
                                                    {/*    }*/}
                                                    {/*    report={true}*/}
                                                    {/*    type={"avg_time"}*/}
                                                    {/*/>*/}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={4}>
                                            <div className="card candidate-filters border border-primary">
                                                <div className="card-header">
                                                    <h5 className="card-title">
                                                        Evaluations
                                                    </h5>
                                                </div>
                                                <div className="card-body">
                                                    <EvaluationDonutPGraph
                                                        data={
                                                            props?.reportPositionData
                                                                ?.position_evaluation_detail_graph_data
                                                        }
                                                        report={true}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </Col>
                    </Row>
                </div>
            </div>

            {/*<div className="card candidate-filters border border-primary">*/}
            {/*    <div className="card-header">*/}
            {/*        <h5 className="card-title">Team Members</h5>*/}
            {/*    </div>*/}
            {/*    <div className=" card-body">*/}
            {/*        <div className="d-flex justify-content-center">*/}
            {/*            {props?.reportMembersLoading && (*/}
            {/*                <Loader spinnerClass="text-green"/>*/}
            {/*            )}*/}
            {/*        </div>*/}
            {/*        <Row>*/}
            {/*            <Col xl={12}>*/}
            {/*                <div className="testimonial-one px-4 owl-right-nav owl-carousel owl-loaded owl-drag">*/}
            {/*                    <Slider {...settings}>*/}
            {/*                        {props?.reportMembersData?.Company?.CompanyMembers.map(*/}
            {/*                            (item, index) => (*/}
            {/*                                <div className="items">*/}
            {/*                                    <div className="text-center">*/}
            {/*                                        <img*/}
            {/*                                            className="mb-3 rounded member-picture"*/}
            {/*                                            src={item?.AddedMemberUserpic1?.image || pic1}*/}
            {/*                                            alt=""*/}
            {/*                                        />*/}
            {/*                                        <h5 className="mb-0">{`${item?.AddedMemberUser?.first_name} ${item?.AddedMemberUser?.last_name}`}</h5>*/}
            {/*                                        <span*/}
            {/*                                            className="fs-12">{`Positions Assigned ${item?.AddedMemberUser?.Roles?.length}`}</span>*/}
            {/*                                    </div>*/}
            {/*                                </div>*/}
            {/*                            )*/}
            {/*                        )}*/}
            {/*                    </Slider>*/}
            {/*                </div>*/}
            {/*            </Col>*/}
            {/*        </Row>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        reportData: getCompanyReportSelector(state),
        reportLoading: getCompanyReportLoadingSelector(state),
        reportMembersData: getCompanyReportMembersSelector(state),
        reportMembersLoading: getCompanyReportMembersLoadingSelector(state),
        reportPositionsData: getCompanyReportPositionsSelector(state),
        reportPositionsLoading: getCompanyReportPositionsLoadingSelector(state),
        reportPositionData: getCompanyReportPositionSelector(state),
        reportPositionLoading: getCompanyReportPositionLoadingSelector(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadCompanyReportAction: () => dispatch(getCompanyReportAction()),
        loadCompanyReportMembersAction: () => dispatch(getCompanyReportMembersAction()),
        loadCompanyReportPositionsAction: (data) => dispatch(getCompanyReportPositionsAction(data)),
        loadCompanyReportPositionAction: (data) => dispatch(getCompanyReportPositionAction(data)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));
