import React, { useState } from "react";

import { Row, Col, Card, CardBody, Badge, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import Rating from "react-rating-tooltip";
import goldBadge from "./../../../images/gold-badge.svg";
import silverBadge from "./../../../images/silver-badge.svg";
import { renderStatus } from "../Common/RenderText";

const ScoreCard = ( props ) => {
  const { 
    setEmailInterview,
    evaluation,
    markCompanyCandidateEvaluation,
    addCompanyCandidateNote,
    markInterviewHire,
    showAlert,
  } = props;
  const [note, setNote] = useState(undefined);
  const [hire, setHire] = useState(evaluation?.status === "hired");

  const setRating = (rating) => {
    const data = {
      interview_id: evaluation.id,
      interview_data: {
        ratings: rating,
      },
    };
    markCompanyCandidateEvaluation(data, [], ()=> showAlert());
  };

  const setResponse = (response) => {
    const data = {
      interview_id: evaluation.id,
      interview_data: {
        my_response: response, // "no" "maybe"
      },
    };
    markCompanyCandidateEvaluation(data, [], ()=> showAlert());
  };

  const handleNoteChange = (note) => {
    setNote(note);
  };

  const handleCommentSubmit = () => {
    const data = {
      interview_id: evaluation.id,
      note: note,
    };
    addCompanyCandidateNote(data);
    setNote("");
  };

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="card-header">
          <h5 className="card-title">Scorecard</h5>
        </div>
        <CardBody>
          <Row>
            <Col lg={12}>
              <div>
                <ul className="list-unstyled chat-list">
                  <li className="border-bottom pb-2">
                    <Link
                      to="#"
                      onClick={() => setEmailInterview(true)}
                      className="text-small text-primary ai-icon"
                    >
                      {/*<i className="mdi mdi-mail mr-1" />*/}
                      {/*<span className="mdi mdi-mail mr-1"/>*/}
                      <i
                        className="bx bxs-envelope bx-lg"
                        style={{ margin: 2 }}
                      />
                      Email this Interview
                    </Link>
                  </li>
                  <li className="border-bottom pb-2 pt-2">
                    <Row className="align-items-center">
                      <Col lg="6">
                        <span>My Ratings</span>
                      </Col>
                      <Col lg="6">
                        <div className="float-right">
                          <Rating
                            max={5}
                            onChange={(rate) => {
                              setRating(rate);
                            }}
                            defaultRating={evaluation?.ratings}
                            ActiveComponent={
                              <i
                                key={"active_1"}
                                className="mdi mdi-star text-primary"
                              />
                            }
                            InActiveComponent={
                              <i
                                key={"active_01"}
                                className="mdi mdi-star-outline text-muted"
                              />
                            }
                            styleConfig={{
                              starContainer: {
                                fontSize: "24px",
                                backgroundColor: "#ffffff",
                                height: "28px",
                              },
                            }}
                          />{" "}
                        </div>
                      </Col>
                    </Row>
                  </li>
                  <li className="border-bottom pb-2 pt-2">
                    <Row className="align-items-center">
                      <Col lg="6">
                        <span>Favorite</span>
                      </Col>
                      <Col lg="6" className="text-right">
                        {evaluation?.favorite ? (
                          <img
                            src={goldBadge}
                            alt=""
                            className="img-fluid rounded-circle"
                            style={{
                              width: "25px",
                              height: "25px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              console.log("DATA::", evaluation);
                              const data = {
                                interview_id: evaluation.id,
                                interview_data: {
                                  favorite: false,
                                },
                              };
                              markCompanyCandidateEvaluation(data, [], ()=> showAlert());
                            }}
                          />
                        ) : (
                          <img
                            src={silverBadge}
                            alt=""
                            className="img-fluid rounded-circle"
                            style={{
                              width: "25px",
                              height: "25px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              const data = {
                                interview_id: evaluation.id,
                                interview_data: {
                                  favorite: true,
                                },
                              };
                              markCompanyCandidateEvaluation(data, [], ()=> showAlert());
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                  </li>
                  <li className="border-bottom pb-2 pt-2">
                    <Row className="align-items-center">
                      <Col lg="6">
                        <span>Status</span>
                      </Col>
                      <Col lg="6" className="text-right">
                        <Badge color="primary">
                          {evaluation?.is_reviewed ||
                          evaluation?.ratings > 0 ||
                          evaluation?.my_response
                            ? "Evaluated"
                            : renderStatus(evaluation?.status)}
                        </Badge>
                      </Col>
                    </Row>
                  </li>
                  <li className="border-bottom pb-2 pt-2">
                    <Row className="align-items-center">
                      <Col lg="6">
                        <span>Hired</span>
                      </Col>
                      <Col lg="6" className="text-right">
                        <input
                          type="checkbox"
                          defaultChecked={hire}
                          onClick={() => {
                            markInterviewHire(
                              { interview_id: evaluation.id, hired: !hire },
                             undefined, ()=> showAlert()
                            );
                            setHire(!hire);
                          }}
                        />
                      </Col>
                    </Row>
                  </li>
                  <li className="border-bottom pb-2 pt-2">
                    <Row className="align-items-center">
                      <Col lg="6">
                        <span>My Response</span>
                      </Col>
                      <Col lg="6" className="text-right">
                        <select
                          value={evaluation?.my_response}
                          onChange={(e) => setResponse(e?.target?.value)}
                          className="form-control"
                        >
                          <option value="">Please Select</option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                          <option value="maybe">Maybe</option>
                        </select>
                      </Col>
                    </Row>
                  </li>
                  {/* <li className="pb-2 pt-2">
                    <Row className="align-items-center">
                      <Col lg="12">
                        <span className="d-block mb-2">Add a note</span>
                        <textarea onChange={(e) => handleNoteChange(e.target.value)} value={note}
                                  className="form-control d-block"></textarea>
                      </Col>
                    </Row>
                    <Row className="justify-content-end mt-2">
                      <Col lg={6}>
                        <button disabled={
                          !note || note.length === 0 ? true : false
                        } onClick={() => handleCommentSubmit()} className="btn btn-primary float-right">Submit
                        </button>
                      </Col>
                    </Row>
                  </li> */}
                </ul>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ScoreCard;
